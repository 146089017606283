@charset "UTF-8";
html {
  font-family: "Hiragino Kaku Gothic ProN", "ヒラギノ角ゴ ProN W3", "FP-ヒラギノ角ゴ ProN W3", sans-serif;
  font-size: 16px;
  font-weight: 500;
  font-style: normal;
  color: #4a535b;
  line-height: 1;
  -webkit-text-size-adjust: 100%;
  vertical-align: bottom;
  box-sizing: border-box;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale; }
  @media screen and (max-width: 760px) {
    html {
      font-size: 14px; } }

body,
body *,
body *:before,
body *:after {
  margin: 0;
  padding: 0;
  list-style: none;
  font-family: inherit;
  font-size: inherit;
  letter-spacing: inherit;
  box-sizing: inherit;
  vertical-align: inherit;
  font-weight: inherit;
  font-style: inherit;
  color: inherit;
  font-feature-settings: inherit;
  outline: none; }

html,
body {
  height: 100%;
  background: #eff3f6; }

a,
a img,
button {
  outline: none;
  border: none;
  cursor: pointer; }

button {
  background-color: transparent;
  appearance: none;
  margin: 0;
  padding: 0;
  background: none;
  border: none;
  border-radius: 0;
  outline: none; }

input,
textarea {
  padding: 0;
  border: none;
  border-radius: 0;
  outline: none;
  background: none; }

input[type="text"]:focus,
textarea:focus {
  outline: 0; }

sup {
  vertical-align: super;
  font-size: smaller; }

sub {
  vertical-align: baseline;
  font-size: 0.7em; }

img,
video,
svg {
  vertical-align: bottom;
  height: auto;
  width: 100%; }

a,
button {
  text-decoration: none;
  -webkit-tap-highlight-color: transparent; }

a:hover,
button:hover {
  outline: none; }

a:disabled,
button:disabled,
input:disabled {
  pointer-events: none; }

table {
  width: 100%;
  border-collapse: collapse; }

main {
  text-align: justify;
  text-justify: inter-ideograph;
  display: block;
  position: relative;
  overflow: hidden; }

section {
  position: relative; }

small {
  font-size: 12px; }
  @media screen and (max-width: 760px) {
    small {
      font-size: 11px; } }

strong {
  font-weight: bold; }

.red {
  color: #d2001e; }

.bg_w {
  background: #fff; }

.text, .text2 {
  line-height: 2; }

.mds1 {
  padding-bottom: 80px;
  text-align: center;
  font-size: 28px;
  font-family: "HiraKakuProN-W6", "ヒラギノ角ゴ ProN W6", "FP-ヒラギノ角ゴ ProN W6", sans-serif; }
  .mds1 b {
    display: inline;
    position: relative;
    z-index: 1;
    margin-bottom: 20px;
    letter-spacing: 0.1em;
    background: linear-gradient(transparent 70%, #e1e7ec 0%);
    line-height: 1.6; }
  .mds1 i {
    display: block;
    font-family: "DIN 2014", "DINNextLTPro-Medium", sans-serif;
    font-weight: 700;
    color: #004498;
    font-size: 16px;
    letter-spacing: 0.1em;
    padding-top: 10px; }
  .mds1 span {
    display: block;
    font-size: 16px;
    padding-top: 15px; }
  @media screen and (max-width: 760px) {
    .mds1 {
      padding-bottom: 50px;
      font-size: 20px; }
      .mds1 i {
        font-size: 16px; }
      .mds1 span {
        font-size: 14px;
        line-height: 1.5; } }
  .mds1.ex {
    font-size: 30px;
    padding-bottom: 50px;
    color: #004498; }
    .mds1.ex b {
      background: linear-gradient(transparent 70%, #CFDDEA 0%); }
    @media screen and (max-width: 760px) {
      .mds1.ex {
        padding-bottom: 30px;
        font-size: 18px; } }

.mds2 {
  text-align: center;
  font-family: "HiraKakuProN-W6", "ヒラギノ角ゴ ProN W6", "FP-ヒラギノ角ゴ ProN W6", sans-serif;
  font-size: 20px;
  padding-bottom: 20px;
  margin-bottom: 30px;
  position: relative; }
  .mds2::after {
    position: absolute;
    bottom: 0;
    left: calc(50% - 30px);
    content: '';
    width: 60px;
    height: 3px;
    background: linear-gradient(to right, #213f9b, #03baf3); }
  .mds2.ex {
    text-align: left;
    line-height: 1.4; }
    .mds2.ex::after {
      left: 0; }
  .mds2.ex2 {
    color: #fff;
    font-weight: 700;
    letter-spacing: 0.1em;
    font-family: "DIN 2014", "DINNextLTPro-Medium", sans-serif;
    font-size: 24px; }
    .mds2.ex2::after {
      background: #fff; }
  .mds2.ex3 {
    font-weight: 700;
    letter-spacing: 0.1em;
    font-family: "DIN 2014", "DINNextLTPro-Medium", sans-serif;
    font-size: 24px; }
  @media screen and (max-width: 760px) {
    .mds2 {
      font-size: 18px; } }

.btn1 {
  color: #fff;
  font-family: "HiraKakuProN-W6", "ヒラギノ角ゴ ProN W6", "FP-ヒラギノ角ゴ ProN W6", sans-serif;
  font-size: 18px;
  letter-spacing: 0.1em;
  position: relative;
  width: 300px;
  height: 70px;
  border-radius: 35px;
  background: linear-gradient(to right, #213f9b, #03baf3);
  z-index: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: auto; }
  .btn1 i {
    position: relative;
    z-index: 2; }
  .btn1::before {
    position: absolute;
    top: calc(50% - 5px);
    right: -25px;
    content: '';
    width: 50px;
    height: 7px;
    background: url("../img/arrow1.png") no-repeat center center;
    background-size: 50px auto;
    z-index: 2; }
  .btn1::after {
    position: absolute;
    top: 4px;
    left: 4px;
    content: '';
    width: calc(100% - 8px);
    height: calc(100% - 8px);
    background: #fff;
    border-radius: 35px;
    opacity: 0;
    z-index: 1;
    transition: 0.3s; }
  @media print, screen and (min-width: 761px) {
    .btn1:hover {
      opacity: 1;
      color: #004498; }
      .btn1:hover::after {
        opacity: 1; } }
  @media screen and (max-width: 760px) {
    .btn1 {
      width: 280px;
      height: 50px; } }
  .btn1.ex {
    font-size: 16px;
    width: 200px;
    height: 50px;
    box-shadow: 0px 0px 15px rgba(255, 255, 255, 0.7); }
  .btn1.ex2 {
    font-size: 16px;
    width: 200px;
    height: 50px;
    background: #4A535B; }
    .btn1.ex2::before {
      display: none; }
    @media print, screen and (min-width: 761px) {
      .btn1.ex2:hover {
        color: #4A535B; } }
  .btn1.ex3 {
    width: 400px; }
    @media screen and (max-width: 760px) {
      .btn1.ex3 {
        width: 280px;
        letter-spacing: 0; } }

.btn2 {
  color: #fff;
  font-family: "HiraKakuProN-W6", "ヒラギノ角ゴ ProN W6", "FP-ヒラギノ角ゴ ProN W6", sans-serif;
  font-size: 16px;
  letter-spacing: 0.1em;
  position: relative;
  width: 200px;
  height: 50px;
  border-radius: 35px;
  border: 2px solid #fff;
  z-index: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: auto; }
  .btn2 i {
    position: relative;
    z-index: 2; }
  .btn2::before {
    position: absolute;
    top: calc(50% - 5px);
    right: -25px;
    content: '';
    width: 50px;
    height: 7px;
    background: url("../img/arrow2.png") no-repeat center center;
    background-size: 50px auto;
    z-index: 2; }
  @media print, screen and (min-width: 761px) {
    .btn2:hover {
      opacity: 1;
      color: #004498;
      background: #fff; } }
  @media screen and (max-width: 760px) {
    .btn2 {
      height: 40px;
      font-size: 14px; } }

.outlink {
  position: relative;
  display: inline-block;
  color: #004498;
  font-family: "HiraKakuProN-W6", "ヒラギノ角ゴ ProN W6", "FP-ヒラギノ角ゴ ProN W6", sans-serif;
  padding-right: 25px; }
  .outlink::after {
    position: absolute;
    top: calc(50% - 7px);
    right: 0;
    content: '';
    width: 15px;
    height: 15px;
    background: url("../img/outlink.png") no-repeat center center;
    background-size: 15px;
    z-index: 1; }

.gbd1 {
  display: inline-block;
  position: relative;
  color: #004498;
  font-size: 18px;
  font-family: "HiraKakuProN-W6", "ヒラギノ角ゴ ProN W6", "FP-ヒラギノ角ゴ ProN W6", sans-serif;
  letter-spacing: 0.1em;
  padding-bottom: 13px;
  margin-bottom: 20px;
  min-width: 80px;
  text-align: center; }
  .gbd1::after {
    position: absolute;
    bottom: 0;
    left: 0;
    content: '';
    width: 100%;
    height: 3px;
    background: linear-gradient(to right, #213f9b, #03baf3); }
  @media screen and (max-width: 760px) {
    .gbd1 {
      font-size: 16px;
      padding-bottom: 10px; } }

.marulist {
  margin-bottom: 50px;
  line-height: 1.5;
  font-size: 20px; }
  @media screen and (max-width: 760px) {
    .marulist {
      font-size: 16px; } }
  .marulist li {
    margin-bottom: 0.7em;
    padding-left: 1.5em;
    text-indent: -1.5em; }
    .marulist li i {
      margin-right: 0.5em; }

@media print, screen and (min-width: 761px) {
  .effect1 {
    opacity: 0; }
    .effect1.on {
      animation: ef1 1.0s forwards; }
  .effect3 {
    opacity: 0; }
    .effect3.on {
      animation: ef3 0.5s forwards 0.3s; }
  .effect4 .line, .effect4 .set {
    opacity: 0; }
  .effect4.on .line {
    animation: mask 0.5s forwards 0.2s; }
  .effect4.on .set {
    animation: ef1 1.0s forwards 0.7s; } }

@keyframes ef1 {
  0% {
    opacity: 0;
    transform: translate(0px, 50px); }
  100% {
    opacity: 1;
    transform: translate(0px, 0px); } }

@keyframes ef2 {
  0% {
    opacity: 0;
    transform: scale(0.9); }
  100% {
    opacity: 1;
    transform: scale(1); } }

@keyframes ef3 {
  0% {
    opacity: 0; }
  100% {
    opacity: 1; } }

@keyframes mask {
  0% {
    opacity: 0;
    clip-path: inset(0 100% 0 0); }
  100% {
    opacity: 1;
    clip-path: inset(0); } }

@keyframes circle {
  0% {
    opacity: 0;
    transform: scale(0.2); }
  60% {
    opacity: 1;
    transform: scale(1); }
  100% {
    opacity: 0;
    transform: scale(1.1); } }

@keyframes pop {
  0% {
    opacity: 0;
    transform: scale(0.2); }
  80% {
    opacity: 1;
    transform: scale(1); }
  100% {
    opacity: 1;
    transform: scale(1); } }

#loading {
  width: 100vw;
  height: 100vh;
  transition: all 1s;
  background-color: #fff;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 9999; }

.loaded {
  opacity: 0;
  visibility: hidden; }

select {
  display: inline-block;
  width: 100%;
  cursor: pointer;
  padding: 10px 30px 10px 15px;
  outline: 0;
  border: 0;
  border-radius: 0;
  color: #4a535b;
  appearance: none;
  background: url("../img/arrow3.png") no-repeat right 5px center #eff3f6;
  background-size: 20px;
  font-family: sans-serif; }
  select::-ms-expand {
    display: none; }

.check input[type="checkbox"] {
  display: none; }

.check input[type="checkbox"] + label {
  display: none;
  cursor: pointer;
  display: inline-block;
  position: relative;
  padding-left: 30px;
  padding-right: 10px; }

.check input[type="checkbox"] + label::before {
  content: "";
  position: absolute;
  display: block;
  box-sizing: border-box;
  width: 20px;
  height: 20px;
  margin-top: -10px;
  left: 0;
  top: 50%;
  border: 1px solid;
  border-radius: 50%;
  border-color: #4a535b;
  background-color: #FFF; }

.check input[type="checkbox"]:checked + label::after {
  content: "";
  position: absolute;
  display: block;
  box-sizing: border-box;
  width: 18px;
  height: 9px;
  margin-top: -9px;
  top: 50%;
  left: 3px;
  transform: rotate(-45deg);
  border-bottom: 3px solid;
  border-left: 3px solid;
  border-color: #004498; }

.check input[type="checkbox"]:checked + label::before {
  background-color: #eff3f6; }

#button:disabled {
  opacity: 0.2; }

@media screen and (max-width: 760px) {
  .none, .pc {
    display: none; }
  main {
    font-size: 15px;
    position: relative;
    overflow: hidden; }
    main a {
      color: #4a535b; }
  #maintitle {
    position: relative;
    background: url("../img/mainbg_sp.png") no-repeat center top;
    background-size: auto 400px; }
    #maintitle .in {
      padding: 140px 20px 60px; }
      #maintitle .in h1, #maintitle .in .h1 {
        font-size: 16px;
        font-family: "HiraKakuProN-W6", "ヒラギノ角ゴ ProN W6", "FP-ヒラギノ角ゴ ProN W6", sans-serif; }
        #maintitle .in h1 strong, #maintitle .in .h1 strong {
          display: block;
          font-size: 30px;
          font-family: "DIN 2014", "DINNextLTPro-Medium", sans-serif;
          font-weight: 700;
          letter-spacing: 0.05em;
          color: #004498;
          padding-bottom: 10px; }
  #bread {
    font-size: 12px;
    letter-spacing: 0.1em;
    padding: 20px 20px 50px; }
    #bread a {
      display: inline-block;
      white-space: nowrap; }
    #bread li {
      display: inline; }
      #bread li::after {
        content: ' ｜ '; }
      #bread li:last-child::after {
        display: none; }
  .list_a {
    padding-bottom: 30px; }
    .list_a li {
      background: #fff;
      margin-bottom: 20px; }
      .list_a li a {
        padding: 20px;
        display: block;
        position: relative; }
        .list_a li a .new {
          position: absolute;
          top: 0;
          right: 0;
          background: #FF0000;
          color: #fff;
          font-size: 16px;
          font-family: "DIN 2014", "DINNextLTPro-Medium", sans-serif;
          font-weight: 700;
          padding: 8px;
          width: 75px;
          text-align: center;
          letter-spacing: 0.1em; }
        .list_a li a figure {
          overflow: hidden;
          position: relative; }
          .list_a li a figure::after {
            position: absolute;
            bottom: 0;
            right: 0;
            content: '';
            width: 40px;
            height: 40px;
            background: url("../img/arrow5.png") no-repeat center center;
            background-size: 40px auto; }
        .list_a li a .t1 {
          font-size: 18px;
          font-family: "HiraKakuProN-W6", "ヒラギノ角ゴ ProN W6", "FP-ヒラギノ角ゴ ProN W6", sans-serif;
          line-height: 1.5;
          padding: 20px 0; }
        .list_a li a .t2 {
          line-height: 1.5; }
  .list_c {
    padding-bottom: 30px; }
    .list_c li {
      padding-bottom: 30px; }
      .list_c li a {
        position: relative;
        display: block;
        line-height: 1.5; }
        .list_c li a .new {
          position: absolute;
          top: 0;
          right: 0;
          background: #FF0000;
          color: #fff;
          font-size: 12px;
          font-family: "DIN 2014", "DINNextLTPro-Medium", sans-serif;
          font-weight: 700;
          padding: 4px;
          width: 50px;
          text-align: center;
          letter-spacing: 0.1em;
          z-index: 1; }
        .list_c li a figure {
          overflow: hidden;
          position: relative; }
          .list_c li a figure::after {
            position: absolute;
            bottom: 0;
            right: 0;
            content: '';
            width: 40px;
            height: 40px;
            background: url("../img/arrow5.png") no-repeat center center;
            background-size: 40px auto; }
        .list_c li a .t1 {
          font-size: 16px;
          font-family: "HiraKakuProN-W6", "ヒラギノ角ゴ ProN W6", "FP-ヒラギノ角ゴ ProN W6", sans-serif;
          padding: 20px 0 15px; }
          .list_c li a .t1 i {
            display: inline-block;
            padding: 5px 20px;
            background: #eff3f6; }
        .list_c li a .t2 {
          font-size: 18px;
          font-family: "HiraKakuProN-W6", "ヒラギノ角ゴ ProN W6", "FP-ヒラギノ角ゴ ProN W6", sans-serif;
          padding-bottom: 10px; }
        .list_c li a .t3 {
          color: #004498;
          font-family: "HiraKakuProN-W6", "ヒラギノ角ゴ ProN W6", "FP-ヒラギノ角ゴ ProN W6", sans-serif;
          padding-bottom: 10px; }
  #f_download {
    margin-top: 50px;
    background: linear-gradient(to right, #213f9b, #03baf3); }
    #f_download .in {
      padding: 50px 20px;
      color: #fff; }
      #f_download .in h2 {
        padding-bottom: 30px;
        text-align: center; }
        #f_download .in h2 i {
          display: block;
          font-size: 16px;
          padding-bottom: 10px;
          line-height: 1.2; }
        #f_download .in h2 b {
          display: block;
          font-size: 22px;
          font-family: "HiraKakuProN-W6", "ヒラギノ角ゴ ProN W6", "FP-ヒラギノ角ゴ ProN W6", sans-serif; }
      #f_download .in .text {
        padding-bottom: 40px; }
      #f_download .in ul li {
        box-shadow: 5px 5px 15px rgba(0, 0, 0, 0.3);
        padding: 30px 0;
        margin-top: 20px;
        text-align: center; }
        #f_download .in ul li .t1 {
          font-family: "HiraKakuProN-W6", "ヒラギノ角ゴ ProN W6", "FP-ヒラギノ角ゴ ProN W6", sans-serif;
          line-height: 1.5;
          padding-bottom: 20px; }
        #f_download .in ul li figure {
          width: 220px;
          margin: auto;
          padding-bottom: 20px;
          position: relative; }
          #f_download .in ul li figure.ex1 {
            width: 140px; }
          #f_download .in ul li figure a {
            display: block;
            position: absolute;
            top: 0;
            left: 0; }
            #f_download .in ul li figure a:hover {
              opacity: 0; }
      #f_download .in ul .s0 {
        background: linear-gradient(to right, #213f9b, #03baf3); }
      #f_download .in ul .s1 {
        background: url("../img/download/p1.jpg") no-repeat center center;
        background-size: cover; }
      #f_download .in ul .s2 {
        background: url("../img/download/p2.jpg") no-repeat center center;
        background-size: cover; }
      #f_download .in ul .s3 {
        background: url("../img/download/p3.jpg") no-repeat center center;
        background-size: cover; }
      #f_download .in ul .s4 {
        background: url("../img/download/p4.jpg") no-repeat center center;
        background-size: cover; }
      #f_download .in .catalog {
        position: relative;
        background: url("../img/download/ca1.jpg") no-repeat center center;
        background-size: cover;
        padding-bottom: 30px;
        margin-top: 20px;
        text-align: center;
        box-shadow: 5px 5px 15px rgba(0, 0, 0, 0.3); }
        #f_download .in .catalog .t1 {
          font-family: "HiraKakuProN-W6", "ヒラギノ角ゴ ProN W6", "FP-ヒラギノ角ゴ ProN W6", sans-serif;
          line-height: 1.5;
          padding-bottom: 20px; }
        #f_download .in .catalog figure {
          width: 140px;
          margin: auto;
          padding-bottom: 20px;
          box-shadow: 5px 5px 15px rgba(0, 0, 0, 0.3); }
          #f_download .in .catalog figure img {
            margin-top: -40px; }
  .jirei {
    background: #fff;
    margin: 0 20px; }
    .jirei .in {
      padding: 50px 20px; }
      .jirei .in h2 {
        padding-bottom: 30px;
        text-align: center; }
        .jirei .in h2 i {
          color: #004498;
          font-size: 16px;
          display: inline-block;
          position: relative; }
          .jirei .in h2 i::after {
            position: absolute;
            bottom: -3px;
            left: 0;
            content: '';
            width: 100%;
            height: 10px;
            background: #e1e7ec;
            z-index: -1; }
        .jirei .in h2 b {
          display: block;
          font-family: "HiraKakuProN-W6", "ヒラギノ角ゴ ProN W6", "FP-ヒラギノ角ゴ ProN W6", sans-serif;
          font-size: 22px;
          padding-top: 20px; }
  .susumelist2 {
    background: #fff;
    margin: 0 20px; }
    .susumelist2 .in {
      padding: 50px 20px; }
      .susumelist2 .in .list_c {
        padding-bottom: 0; }
        .susumelist2 .in .list_c .t1 {
          padding-bottom: 5px; }
        .susumelist2 .in .list_c .t2 {
          font-size: 14px;
          font-weight: normal; }
  .susume .in {
    padding: 50px 20px; }
    .susume .in h2 {
      padding-bottom: 30px; }
      .susume .in h2 i {
        color: #004498;
        font-size: 16px;
        display: inline-block;
        position: relative; }
        .susume .in h2 i::after {
          position: absolute;
          bottom: -3px;
          left: 0;
          content: '';
          width: 100%;
          height: 10px;
          background: #e1e7ec;
          z-index: -1; }
      .susume .in h2 b {
        display: block;
        font-family: "HiraKakuProN-W6", "ヒラギノ角ゴ ProN W6", "FP-ヒラギノ角ゴ ProN W6", sans-serif;
        font-size: 22px;
        padding-top: 20px; }
  #esform {
    background: linear-gradient(to right, #213f9b, #03baf3); }
    #esform .in {
      padding: 50px 20px; }
      #esform .in h2 {
        text-align: center;
        margin-bottom: 20px;
        color: #fff; }
        #esform .in h2 i {
          display: inline-block;
          text-align: left;
          background: url("../img/top/cal.png") no-repeat left center;
          background-size: 40px auto;
          padding: 10px 0 10px 55px;
          font-size: 13px; }
          #esform .in h2 i strong {
            display: block;
            font-size: 22px;
            font-family: "HiraKakuProN-W6", "ヒラギノ角ゴ ProN W6", "FP-ヒラギノ角ゴ ProN W6", sans-serif;
            padding-top: 10px;
            line-height: 1.2; }
      #esform .in .t2 {
        padding-bottom: 30px;
        color: #fff; }
      #esform .in .fuki {
        color: #fff;
        border: 1px solid #fff;
        padding: 20px;
        margin: 30px 0; }
        #esform .in .fuki h3 {
          font-weight: bold;
          font-size: 16px;
          margin-bottom: 10px;
          text-align: center;
          line-height: 1.5; }
          #esform .in .fuki h3 strong {
            display: inline-block;
            border-top: 1px solid #fff;
            border-bottom: 1px solid #fff;
            font-size: 14px;
            padding: 5px 0 3px; }
          #esform .in .fuki h3 i {
            display: block;
            text-align: left;
            padding-top: 15px; }
        #esform .in .fuki .text {
          line-height: 1.8; }
        #esform .in .fuki .btn {
          padding-top: 20px;
          text-align: center; }
          #esform .in .fuki .btn img {
            max-width: 300px; }
        #esform .in .fuki .bnr {
          padding-top: 20px;
          text-align: center; }
          #esform .in .fuki .bnr img {
            max-width: 300px; }
      #esform .in .box {
        background: #fff;
        padding: 30px;
        box-shadow: 5px 5px 15px rgba(0, 0, 0, 0.3); }
        #esform .in .box .simulator li {
          padding-bottom: 20px; }
          #esform .in .box .simulator li i {
            display: block;
            padding-bottom: 10px;
            font-family: "HiraKakuProN-W6", "ヒラギノ角ゴ ProN W6", "FP-ヒラギノ角ゴ ProN W6", sans-serif;
            font-size: 18px; }
        #esform .in .box .t1 {
          line-height: 1.5;
          font-size: 12px;
          padding: 10px 0 20px; }
  header {
    position: fixed;
    width: 100%;
    height: 60px;
    line-height: 1;
    z-index: 100;
    top: 0px;
    left: 10px; }
    header .in {
      width: calc(100% - 20px);
      height: 50px;
      margin-top: 10px;
      background: #fff;
      position: relative;
      display: flex;
      flex-wrap: wrap;
      flex-direction: row;
      justify-content: space-between;
      align-items: center;
      padding-left: 15px; }
      header .in h1 {
        width: 150px;
        display: flex;
        flex-wrap: wrap;
        align-items: center; }
      header .in #menubtn {
        width: 65px;
        height: 50px;
        display: flex;
        align-items: center;
        justify-content: center;
        cursor: pointer;
        transition: 0.3s;
        -webkit-tap-highlight-color: transparent; }
  #menu {
    position: fixed;
    width: 100%;
    height: 100%;
    top: 0px;
    left: 0px;
    opacity: 0;
    transition-duration: 0.3s;
    line-height: 1;
    pointer-events: none;
    overflow-y: auto;
    background: #fff;
    transform: scale(1.2);
    display: flex;
    align-items: center;
    justify-content: center; }
    #menu.on {
      opacity: 1;
      pointer-events: auto;
      z-index: 99;
      transform: scale(1); }
    #menu .in .nav {
      padding-bottom: 20px; }
      #menu .in .nav > li {
        text-align: center;
        font-family: "HiraKakuProN-W6", "ヒラギノ角ゴ ProN W6", "FP-ヒラギノ角ゴ ProN W6", sans-serif;
        font-size: 16px; }
        #menu .in .nav > li a {
          display: inline-block;
          padding: 20px;
          position: relative; }
          #menu .in .nav > li a .new {
            position: absolute;
            top: 14px;
            left: calc(50% - 17px);
            background: #FF0000;
            color: #fff;
            font-size: 10px;
            font-family: "DIN 2014", "DINNextLTPro-Medium", sans-serif;
            font-weight: 700;
            padding: 3px;
            text-align: center;
            letter-spacing: 0.1em; }
    #menu .in .btn {
      width: 200px;
      border-radius: 3px;
      overflow: hidden; }
      #menu .in .btn .hbtn {
        display: flex;
        flex-wrap: wrap;
        flex-direction: row;
        align-items: center;
        justify-content: center;
        width: 200px;
        height: 50px;
        font-family: "HiraKakuProN-W6", "ヒラギノ角ゴ ProN W6", "FP-ヒラギノ角ゴ ProN W6", sans-serif;
        background: linear-gradient(to right, #213f9b, #03baf3);
        color: #fff;
        border: 4px solid #213f9b;
        border-image: linear-gradient(to right, #213f9b 0%, #03baf3 100%);
        border-image-slice: 1; }
  .hm_button * {
    margin: 0;
    padding: 0;
    outline: none;
    border: none;
    font: inherit;
    font-family: inherit;
    font-size: 100%;
    font-style: inherit;
    font-weight: inherit;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    text-align: left;
    text-decoration: none;
    list-style: none; }
  .hm_button {
    display: block;
    padding: 0;
    width: 30px;
    height: 18px;
    position: relative;
    background: none;
    border: none;
    text-align: center;
    letter-spacing: 0.1em;
    cursor: pointer;
    outline: none; }
  .hm_button .hm_bar {
    display: block;
    width: 30px;
    height: 3px;
    transition: all 0.2s;
    transform-origin: 0% 0%;
    transform: translateY(-50%);
    position: absolute;
    left: 0; }
  .hm_button .hm_bar1 {
    top: 0; }
  .hm_button .hm_bar2 {
    top: 50%; }
  .hm_button .hm_bar3 {
    top: 100%; }
  .hm_button.active .hm_bar {
    width: 25.46px;
    left: 6px; }
  .hm_button.active .hm_bar1 {
    transform: rotate(0.7854rad) translateY(-50%);
    top: 0px; }
  .hm_button.active .hm_bar2 {
    opacity: 0; }
  .hm_button.active .hm_bar3 {
    transform: rotate(-0.7854rad) translateY(-50%);
    top: calc(100% - 0px); }
  .hm_button.active .hm_menu_text {
    display: none; }
  .hm_button.active .hm_close {
    display: block; }
  .hm_button .hm_bar {
    background-color: #4f5254; }
  footer {
    position: relative;
    margin-top: 50px; }
    footer .bg {
      background: #fff;
      padding: 30px 20px 30px;
      position: relative; }
      footer .bg .in .sec1 .logo {
        padding-bottom: 20px; }
        footer .bg .in .sec1 .logo img {
          width: 240px; }
        footer .bg .in .sec1 .logo i {
          display: block;
          font-size: 12px;
          color: #004498;
          padding-top: 10px;
          line-height: 1.5; }
      footer .bg .in .sec1 .outlink {
        margin-top: 15px; }
      footer .bg .in .sec1 .nav1 {
        font-family: "HiraKakuProN-W6", "ヒラギノ角ゴ ProN W6", "FP-ヒラギノ角ゴ ProN W6", sans-serif; }
        footer .bg .in .sec1 .nav1 li {
          padding-bottom: 15px; }
      footer .bg .in .sec2 {
        position: absolute;
        bottom: 30px;
        right: 20px;
        width: 150px; }
        footer .bg .in .sec2 .nav2 {
          font-family: "HiraKakuProN-W6", "ヒラギノ角ゴ ProN W6", "FP-ヒラギノ角ゴ ProN W6", sans-serif;
          text-align: center; }
          footer .bg .in .sec2 .nav2 li {
            padding-bottom: 10px; }
            footer .bg .in .sec2 .nav2 li a {
              display: flex;
              justify-content: center;
              align-items: center;
              width: 150px;
              height: 40px; }
            footer .bg .in .sec2 .nav2 li .fbtn1 {
              background: #eff3f6; }
              footer .bg .in .sec2 .nav2 li .fbtn1 i {
                position: relative;
                padding-left: 25px; }
                footer .bg .in .sec2 .nav2 li .fbtn1 i::after {
                  position: absolute;
                  top: calc(50% - 10px);
                  left: 0;
                  content: '';
                  width: 20px;
                  height: 20px;
                  background: url("../img/mail.png") no-repeat center center;
                  background-size: 20px;
                  z-index: 1; }
            footer .bg .in .sec2 .nav2 li .fbtn2 {
              background: #4a535b;
              color: #fff; }
              footer .bg .in .sec2 .nav2 li .fbtn2 i {
                position: relative;
                padding-left: 25px; }
                footer .bg .in .sec2 .nav2 li .fbtn2 i::after {
                  position: absolute;
                  top: calc(50% - 10px);
                  left: 0;
                  content: '';
                  width: 20px;
                  height: 20px;
                  background: url("../img/dl.png") no-repeat center center;
                  background-size: 20px;
                  z-index: 1; }
            footer .bg .in .sec2 .nav2 li .fbtn3 {
              background: linear-gradient(to right, #213f9b, #03baf3);
              color: #fff; }
    footer .sec3 {
      text-align: center;
      padding: 20px 0 15px; }
      footer .sec3 .in {
        font-size: 12px; }
        footer .sec3 .in ul {
          display: flex;
          justify-content: center; }
          footer .sec3 .in ul li::after {
            content: '　｜　'; }
          footer .sec3 .in ul li:last-child::after {
            display: none; }
        footer .sec3 .in .copyright {
          font-family: "Helvetica-Light", "HelveticaLTPro-Light", sans-serif;
          line-height: 1.5;
          padding-top: 15px; }
  #pagetop {
    position: fixed;
    right: 10px;
    bottom: 10px;
    z-index: 1;
    cursor: pointer; }
    #pagetop img {
      width: 40px; }
  #toppage main {
    z-index: 1; }
  #toppage #zone1 {
    background: #fff; }
    #toppage #zone1 .in .set1 {
      background: url("../img/top/mainbg_sp.jpg") no-repeat center top;
      background-size: contain;
      height: 100vh;
      position: relative; }
      #toppage #zone1 .in .set1 h1 {
        width: 50%;
        position: absolute;
        top: 47%;
        left: 20px; }
      #toppage #zone1 .in .set1 .link {
        position: absolute;
        bottom: 60px;
        left: calc(50% - 150px); }
        #toppage #zone1 .in .set1 .link .btn1 {
          width: 300px;
          font-size: 15px;
          letter-spacing: 0; }
      #toppage #zone1 .in .set1 .sc {
        position: absolute;
        bottom: 5px;
        left: calc(50% - 20px); }
        #toppage #zone1 .in .set1 .sc img {
          width: 40px; }
    #toppage #zone1 .in .set2 {
      padding: 20px 20px 50px; }
      #toppage #zone1 .in .set2 .copy {
        width: 250px;
        padding-bottom: 20px; }
      #toppage #zone1 .in .set2 .text i {
        display: block;
        font-family: 28px;
        color: #004498;
        font-family: "HiraKakuProN-W6", "ヒラギノ角ゴ ProN W6", "FP-ヒラギノ角ゴ ProN W6", sans-serif;
        font-size: 22px;
        padding: 10px 0; }
  #toppage #zone_news {
    background: #fff;
    padding: 0px 20px 30px; }
    #toppage #zone_news h2 {
      text-align: center;
      font-weight: bold;
      font-size: 22px;
      padding-bottom: 20px; }
    #toppage #zone_news .in {
      max-width: 600px;
      margin: auto;
      font-weight: bold;
      line-height: 1.5; }
      #toppage #zone_news .in dl {
        padding-bottom: 20px; }
        #toppage #zone_news .in dl dt {
          color: #004498; }
        #toppage #zone_news .in dl dd a {
          text-decoration: underline; }
  #toppage #zone4, #toppage #zone5 {
    background: #fff;
    margin-top: 50px; }
  #modal {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100vh;
    background: #fff;
    display: flex;
    align-items: center;
    justify-content: center;
    opacity: 0;
    transition-duration: 0.3s;
    z-index: 0;
    pointer-events: none;
    transform: scale(1.2); }
    #modal.on {
      opacity: 1;
      pointer-events: auto;
      z-index: 99999;
      transform: scale(1); }
    #modal .modalbg {
      display: none; }
    #modal .in {
      background: #fff;
      padding: 20px;
      position: relative; }
      #modal .in figure {
        width: 100px;
        margin: auto; }
      #modal .in .mtitle {
        font-size: 16px;
        font-family: "HiraKakuProN-W6", "ヒラギノ角ゴ ProN W6", "FP-ヒラギノ角ゴ ProN W6", sans-serif;
        padding: 10px 0 5px;
        text-align: center;
        line-height: 1.5; }
      #modal .in h3 {
        font-family: "HiraKakuProN-W6", "ヒラギノ角ゴ ProN W6", "FP-ヒラギノ角ゴ ProN W6", sans-serif;
        padding-bottom: 5px;
        text-align: center;
        line-height: 1.5;
        color: #d2001e; }
      #modal .in h4 {
        font-family: "HiraKakuProN-W6", "ヒラギノ角ゴ ProN W6", "FP-ヒラギノ角ゴ ProN W6", sans-serif;
        padding: 10px 0 5px; }
      #modal .in .text {
        font-size: 13px;
        line-height: 1.5; }
      #modal .in .check {
        text-align: center;
        padding: 5px 0 15px;
        font-size: 16px;
        font-family: "HiraKakuProN-W6", "ヒラギノ角ゴ ProN W6", "FP-ヒラギノ角ゴ ProN W6", sans-serif; }
        #modal .in .check i {
          display: inline-block;
          padding: 10px 20px;
          border: 1px solid #4a535b; }
      #modal .in #cbtn {
        position: absolute;
        top: 15px;
        right: 10px;
        background: url("../img/close.png") no-repeat left top;
        background-size: 50px;
        width: 50px;
        height: 50px;
        cursor: pointer; }
      #modal .in .termsbox {
        overflow-y: scroll;
        width: 100%;
        height: 100px;
        padding: 10px 20px 10px 10px;
        border: 1px solid #e0e0e0;
        margin: 10px 0;
        background: #fcfcfc; }
        #modal .in .termsbox h2 {
          font-size: 12px;
          font-family: "HiraKakuProN-W6", "ヒラギノ角ゴ ProN W6", "FP-ヒラギノ角ゴ ProN W6", sans-serif;
          padding-bottom: 10px; }
        #modal .in .termsbox .text {
          font-size: 12px;
          padding-bottom: 20px; }
  #estimate #zone1 {
    padding: 0 20px 50px; }
    #estimate #zone1 .in figure {
      width: 120px;
      margin: auto; }
    #estimate #zone1 .in h2 {
      font-size: 22px;
      font-family: "HiraKakuProN-W6", "ヒラギノ角ゴ ProN W6", "FP-ヒラギノ角ゴ ProN W6", sans-serif;
      padding: 40px 0;
      text-align: center; }
    #estimate #zone1 .in h3 {
      font-size: 18px;
      font-family: "HiraKakuProN-W6", "ヒラギノ角ゴ ProN W6", "FP-ヒラギノ角ゴ ProN W6", sans-serif;
      text-align: center;
      padding-bottom: 10px; }
    #estimate #zone1 .in .text {
      text-align: center;
      font-size: 12px;
      padding-bottom: 10px; }
    #estimate #zone1 .in .box .price {
      background: #eff3f6;
      font-size: 24px;
      font-family: sans-serif;
      font-weight: bold;
      letter-spacing: 0.1em;
      text-align: center;
      padding: 30px 0; }
      #estimate #zone1 .in .box .price i {
        display: block;
        padding-top: 10px; }
    #estimate #zone1 .in .box .t1 {
      font-size: 12px;
      text-align: right;
      padding-top: 10px; }
    #estimate #zone1 .in .box .btn {
      padding-top: 20px; }
  #estimate #zone2 {
    padding: 0 20px 50px; }
    #estimate #zone2 .in h2 {
      line-height: 2;
      padding: 20px 0;
      border-top: 2px solid #4a535b; }
    #estimate #zone2 .in .t1 {
      padding-bottom: 30px;
      border-bottom: 2px solid #4a535b;
      margin-bottom: 30px; }
    #estimate #zone2 .in h3 {
      text-align: center;
      color: #004498;
      font-size: 16px;
      font-family: "HiraKakuProN-W6", "ヒラギノ角ゴ ProN W6", "FP-ヒラギノ角ゴ ProN W6", sans-serif;
      padding: 30px 0;
      line-height: 1.5; }
      #estimate #zone2 .in h3 small {
        display: block;
        padding-top: 10px;
        font-size: 12px;
        text-align: left; }
    #estimate #zone2 .in .text {
      padding-bottom: 20px; }
      #estimate #zone2 .in .text b {
        font-family: "HiraKakuProN-W6", "ヒラギノ角ゴ ProN W6", "FP-ヒラギノ角ゴ ProN W6", sans-serif; }
      #estimate #zone2 .in .text strong {
        color: #004498;
        font-family: "HiraKakuProN-W6", "ヒラギノ角ゴ ProN W6", "FP-ヒラギノ角ゴ ProN W6", sans-serif; }
      #estimate #zone2 .in .text i {
        font-family: "HiraKakuProN-W6", "ヒラギノ角ゴ ProN W6", "FP-ヒラギノ角ゴ ProN W6", sans-serif;
        color: #d2001e; }
  #estimate .gform {
    margin: -10px -20px 0;
    padding: 10px;
    background: #e2e2e2; }
    #estimate .gform iframe {
      width: 100%;
      height: 1800px; }
  #recommend #zone2 {
    padding: 50px 20px 0; }
  #recommend_single #zone2 {
    margin-bottom: 100px;
    padding: 40px 20px; }
    #recommend_single #zone2 .in {
      color: #fff; }
      #recommend_single #zone2 .in h2 {
        font-size: 20px;
        font-weight: bold;
        line-height: 1.5;
        text-align: center;
        padding-bottom: 35px;
        margin-bottom: 30px;
        position: relative; }
        #recommend_single #zone2 .in h2::after {
          position: absolute;
          bottom: 0;
          left: calc(50% - 50px);
          content: '';
          width: 100px;
          height: 2px;
          background: #fff; }
  #recommend_single #zone3 {
    margin: 0 20px;
    padding-bottom: 40px;
    background: #fff; }
    #recommend_single #zone3 ul li {
      padding-bottom: 30px; }
      #recommend_single #zone3 ul li .in {
        padding: 20px; }
        #recommend_single #zone3 ul li .in.ex {
          padding-top: 0; }
        #recommend_single #zone3 ul li .in h3 {
          font-size: 18px;
          font-weight: bold;
          line-height: 1.5;
          padding-bottom: 10px; }
        #recommend_single #zone3 ul li .in h4 {
          font-size: 16px;
          font-weight: bold;
          line-height: 1.5;
          margin: 30px 0 10px; }
        #recommend_single #zone3 ul li .in h5 {
          font-size: 15px;
          font-weight: bold;
          line-height: 1.5; }
        #recommend_single #zone3 ul li .in .text a {
          color: #004498;
          font-weight: bold; }
      #recommend_single #zone3 ul li .box {
        margin: 20px; }
        #recommend_single #zone3 ul li .box .bg {
          background: #EFF3F6; }
          #recommend_single #zone3 ul li .box .bg figure {
            position: relative; }
            #recommend_single #zone3 ul li .box .bg figure i {
              position: absolute;
              bottom: 0;
              left: 0;
              display: block;
              color: #fff;
              background: rgba(74, 83, 91, 0.5);
              width: 100%;
              text-align: center;
              padding: 5px;
              font-size: 12px;
              line-height: 1.2; }
          #recommend_single #zone3 ul li .box .bg .text {
            padding: 20px; }
    #recommend_single #zone3 .t1 {
      text-align: center;
      color: #004498;
      font-size: 16px;
      font-family: "HiraKakuProN-W6", "ヒラギノ角ゴ ProN W6", "FP-ヒラギノ角ゴ ProN W6", sans-serif;
      padding-bottom: 20px;
      line-height: 1.5; }
    #recommend_single #zone3 table {
      margin: 30px 0;
      border-top: 1px solid #818181;
      border-left: 1px solid #818181; }
      #recommend_single #zone3 table th, #recommend_single #zone3 table td {
        border-bottom: 1px solid #818181;
        border-right: 1px solid #818181;
        vertical-align: middle;
        padding: 5px;
        line-height: 1.5;
        font-size: 13px; }
      #recommend_single #zone3 table th {
        background-color: #F7F9FA;
        white-space: nowrap;
        font-weight: bold; }
      #recommend_single #zone3 table .c {
        text-align: center; }
  #recommend_single .page1 #zone2 {
    background: url("../img/recommend/001/bg.jpg") no-repeat center center;
    background-size: cover; }
  #recommend_single .page2 #zone2 {
    background: url("../img/recommend/002/bg.jpg") no-repeat center center;
    background-size: cover; }
  #recommend_single .page3 #zone2 {
    background: url("../img/recommend/003/bg.jpg") no-repeat center center;
    background-size: cover; }
  #recommend_single .page4 #zone2 {
    background: url("../img/recommend/004/bg.jpg") no-repeat center center;
    background-size: cover; }
  #recommend_single .page5 #zone2 {
    background: url("../img/recommend/005/bg.jpg") no-repeat center center;
    background-size: cover; }
  #recommend_single .page6 #zone2 {
    background: url("../img/recommend/006/bg.jpg") no-repeat center center;
    background-size: cover; }
  #recommend_single .page7 #zone2 {
    background: url("../img/recommend/007/bg.jpg") no-repeat center center;
    background-size: cover; }
  #recommend_single .page8 #zone2 {
    background: url("../img/recommend/008/bg.jpg") no-repeat center center;
    background-size: cover; }
  #recommend_single .page8 #zone3 .box {
    margin: 30px 0; }
    #recommend_single .page8 #zone3 .box .text b {
      display: block;
      font-weight: bold;
      background-color: #E1E7EC;
      padding-left: 5px;
      margin-bottom: 5px; }
  #recommend_single .page9 #zone2 {
    background: url("../img/recommend/009/bg.jpg") no-repeat center center;
    background-size: cover; }
  #recommend_single .page9 #zone3 .in.ex .btn {
    margin-top: 30px; }
  #recommend_single .page10 #zone2 {
    background: url("../img/recommend/010/bg.jpg") no-repeat center center;
    background-size: cover; }
  #recommend_single .page10 #zone3 table {
    margin: 10px 0; }
  #recommend_single .page10 #zone3 .in.ex .btn {
    margin-top: 30px; }
  #recommend_single .page10 #zone3 .in.ex2 {
    width: 100%; }
  #recommend_single .page10 #zone3 .c1 {
    color: #004498;
    font-weight: bold; }
  #recommend_single .page10 #zone3 .box {
    margin: 30px 20px; }
    #recommend_single .page10 #zone3 .box .text b {
      display: block;
      font-weight: bold;
      background-color: #E1E7EC;
      padding-left: 5px;
      margin-bottom: 5px; }
  #recommend_single .page11 #zone2 {
    background: url("../img/recommend/011/bg.jpg") no-repeat center center;
    background-size: cover; }
  #recommend_single .page11 #zone3 table {
    margin: 10px 0; }
  #recommend_single .page11 #zone3 .in.ex .btn {
    margin-top: 30px; }
  #recommend_single .page11 #zone3 .in.ex2 {
    width: 100%; }
  #recommend_single .page11 #zone3 .c1 {
    color: #004498;
    font-weight: bold; }
  #recommend_single .page11 #zone3 .box {
    margin: 30px 20px; }
    #recommend_single .page11 #zone3 .box .text b {
      display: block;
      font-weight: bold;
      background-color: #E1E7EC;
      padding-left: 5px;
      margin-bottom: 5px; }
  #recommend_single .page12 #zone2 {
    background: url("../img/recommend/012/bg.jpg") no-repeat center center;
    background-size: cover; }
  #casestudy #zone1 .dropsec {
    border-bottom: 2px solid #e5e5e5; }
    #casestudy #zone1 .dropsec .dropnav {
      display: flex;
      justify-content: space-between;
      text-align: center; }
      #casestudy #zone1 .dropsec .dropnav .dropnav_ttl {
        width: 50%;
        position: relative;
        font-size: 16px; }
        #casestudy #zone1 .dropsec .dropnav .dropnav_ttl .navttl {
          display: block;
          font-family: "HiraKakuProN-W6", "ヒラギノ角ゴ ProN W6", "FP-ヒラギノ角ゴ ProN W6", sans-serif;
          padding: 15px;
          padding-right: 30px;
          background: url("../img/arrow4.png") no-repeat right 20px center;
          background-size: 20px; }
        #casestudy #zone1 .dropsec .dropnav .dropnav_ttl .dropnav_box {
          display: none;
          position: absolute;
          top: 42px;
          left: 0;
          width: 100%;
          background: #fff;
          z-index: 2;
          padding-top: 5px;
          box-shadow: 5px 5px 10px rgba(0, 0, 0, 0.3); }
          #casestudy #zone1 .dropsec .dropnav .dropnav_ttl .dropnav_box::after {
            position: absolute;
            top: 0;
            left: 0;
            content: '';
            width: 100%;
            height: 5px;
            background: linear-gradient(to right, #213f9b, #03baf3); }
          #casestudy #zone1 .dropsec .dropnav .dropnav_ttl .dropnav_box a {
            display: block;
            padding: 15px; }
  #casestudy #zone2 {
    padding: 50px 20px 0; }
  #casestudy_single #zone1 {
    padding: 0 20px; }
    #casestudy_single #zone1 .mds1 {
      text-align: left; }
  #casestudy_single #zone2 .in {
    background: #fff;
    padding: 20px; }
    #casestudy_single #zone2 .in .t1 {
      color: #004498;
      font-family: "DIN 2014", "DINNextLTPro-Medium", sans-serif;
      font-size: 18px;
      font-weight: 700;
      display: flex;
      align-items: center; }
      #casestudy_single #zone2 .in .t1 b {
        font-size: 42px;
        margin-left: 5px; }
    #casestudy_single #zone2 .in h2 {
      font-family: "HiraKakuProN-W6", "ヒラギノ角ゴ ProN W6", "FP-ヒラギノ角ゴ ProN W6", sans-serif;
      font-size: 20px;
      line-height: 1.5;
      margin-bottom: 20px; }
    #casestudy_single #zone2 .in .t2 {
      line-height: 1.5; }
    #casestudy_single #zone2 .in dl {
      display: flex;
      flex-wrap: wrap;
      flex-direction: row;
      justify-content: space-between;
      line-height: 1.5; }
      #casestudy_single #zone2 .in dl dt {
        width: 7em;
        font-feature-settings: "palt";
        margin-bottom: 5px; }
      #casestudy_single #zone2 .in dl dd {
        width: calc(100% - 7em);
        margin-bottom: 5px;
        text-indent: -1em;
        padding-left: 1em; }
  #casestudy_single #zone3 {
    margin-top: 50px;
    background: #fff;
    padding: 50px 20px; }
    #casestudy_single #zone3 .set1 {
      padding-bottom: 30px; }
      #casestudy_single #zone3 .set1 > li {
        border: 5px solid #eff3f6;
        padding: 20px 20px 0px 20px;
        margin-bottom: 20px; }
        #casestudy_single #zone3 .set1 > li:first-child {
          background: #eff3f6; }
        #casestudy_single #zone3 .set1 > li ol {
          counter-reset: item;
          list-style-type: none;
          padding-left: 0;
          line-height: 1.6; }
          #casestudy_single #zone3 .set1 > li ol li {
            text-indent: -1.3em;
            padding-left: 1.3em;
            padding-bottom: 20px; }
            #casestudy_single #zone3 .set1 > li ol li::before {
              counter-increment: item;
              content: counter(item) ".";
              padding-right: .4em;
              color: #004498; }
    #casestudy_single #zone3 .set2 {
      padding-bottom: 50px; }
      #casestudy_single #zone3 .set2 h3 {
        font-family: "HiraKakuProN-W6", "ヒラギノ角ゴ ProN W6", "FP-ヒラギノ角ゴ ProN W6", sans-serif;
        font-size: 18px;
        line-height: 1.5;
        padding-bottom: 30px; }
        #casestudy_single #zone3 .set2 h3.ex {
          padding-bottom: 20px; }
      #casestudy_single #zone3 .set2 h4 {
        font-family: "HiraKakuProN-W6", "ヒラギノ角ゴ ProN W6", "FP-ヒラギノ角ゴ ProN W6", sans-serif;
        font-size: 16px;
        line-height: 1.5;
        color: #004498;
        text-indent: -1.2em;
        padding-left: 1.2em;
        padding-bottom: 10px; }
        #casestudy_single #zone3 .set2 h4::before {
          content: 'ー';
          padding-right: .2em; }
      #casestudy_single #zone3 .set2 .text {
        padding-bottom: 40px; }
        #casestudy_single #zone3 .set2 .text.ex {
          padding-bottom: 0px; }
    #casestudy_single #zone3 .pic_l {
      position: relative;
      margin-bottom: 50px; }
      #casestudy_single #zone3 .pic_l img {
        width: 95%;
        position: relative;
        z-index: 1; }
      #casestudy_single #zone3 .pic_l::after {
        position: absolute;
        top: -30px;
        right: 0;
        content: '';
        width: 200px;
        height: 100px;
        background: linear-gradient(to right, #213f9b, #03baf3); }
    #casestudy_single #zone3 .pic_r {
      position: relative;
      margin-bottom: 50px;
      text-align: right; }
      #casestudy_single #zone3 .pic_r img {
        width: 95%;
        position: relative;
        z-index: 1; }
      #casestudy_single #zone3 .pic_r::after {
        position: absolute;
        top: -30px;
        left: 0;
        content: '';
        width: 200px;
        height: 100px;
        background: linear-gradient(to right, #213f9b, #03baf3); }
    #casestudy_single #zone3 .set3 {
      border: 1px solid #818181;
      padding: 20px 20px 0px 20px; }
      #casestudy_single #zone3 .set3 .mds2 {
        line-height: 1.5; }
      #casestudy_single #zone3 .set3 .in {
        display: flex;
        justify-content: center;
        line-height: 1.6; }
        #casestudy_single #zone3 .set3 .in ul li {
          position: relative;
          padding-left: 30px;
          background: url("../img/casestudy/ck.png") no-repeat left top 2px;
          background-size: 20px;
          padding-bottom: 20px; }
    #casestudy_single #zone3 .photoset {
      margin-right: -10px;
      padding-top: 50px; }
      #casestudy_single #zone3 .photoset .slider {
        margin-right: 10px; }
      #casestudy_single #zone3 .photoset .thumbnail .slick-track {
        transform: unset !important;
        display: flex;
        flex-flow: row wrap; }
      #casestudy_single #zone3 .photoset .thumbnail .slick-slide {
        display: block !important;
        float: none !important;
        flex: 0 0 calc(100% / 5 - 11px);
        margin: 10px 10px 0 0; }
        #casestudy_single #zone3 .photoset .thumbnail .slick-slide img {
          cursor: pointer; }
      #casestudy_single #zone3 .photoset .thumbnail .slick-track:before {
        display: none; }
      #casestudy_single #zone3 .photoset .thumbnail .slick-track:after {
        display: none; }
      #casestudy_single #zone3 .photoset .thumbnail .slick-current {
        position: relative;
        z-index: 1; }
      #casestudy_single #zone3 .photoset .thumbnail .slick-current::after {
        content: '';
        width: 100%;
        height: 100%;
        border: 3px solid #004498;
        position: absolute;
        top: 0;
        left: 0;
        z-index: 2; }
  #qa #zone1 {
    padding: 0 20px 50px; }
    #qa #zone1 .mds1 {
      padding-bottom: 30px;
      border-bottom: 10px solid #eff3f6; }
    #qa #zone1 .qanav {
      padding: 30px 0; }
      #qa #zone1 .qanav li a {
        background: url("../img/qa/arrow.png") no-repeat left center;
        background-size: 10px;
        display: block;
        color: #004498;
        font-family: "HiraKakuProN-W6", "ヒラギノ角ゴ ProN W6", "FP-ヒラギノ角ゴ ProN W6", sans-serif;
        line-height: 1.5;
        padding: 5px 0px 5px 20px; }
    #qa #zone1 .set {
      padding-bottom: 50px; }
      #qa #zone1 .set h3 {
        background: url("../img/qa/q.png") no-repeat left 15px center #eff3f6;
        background-size: 30px;
        font-family: "HiraKakuProN-W6", "ヒラギノ角ゴ ProN W6", "FP-ヒラギノ角ゴ ProN W6", sans-serif;
        font-size: 16px;
        line-height: 1.5;
        padding: 10px;
        padding-left: 60px; }
      #qa #zone1 .set .text {
        background: url("../img/qa/a.png") no-repeat left 15px top 15px;
        background-size: 30px;
        padding: 10px;
        padding-left: 60px; }
  #contact #zone1 {
    padding: 0 20px 50px; }
  #download #f_download {
    background: #fff;
    margin-top: 0px; }
    #download #f_download .in {
      padding-top: 0;
      color: #4a535b; }
      #download #f_download .in .t1 {
        color: #fff; }
  #terms #zone1 {
    padding: 0 20px; }
    #terms #zone1 h2 {
      color: #004498;
      font-family: "HiraKakuProN-W6", "ヒラギノ角ゴ ProN W6", "FP-ヒラギノ角ゴ ProN W6", sans-serif;
      font-size: 16px;
      padding-bottom: 10px;
      line-height: 1.5; }
    #terms #zone1 .text {
      padding-bottom: 50px; }
  #policy #zone1 {
    padding: 0 20px; }
    #policy #zone1 h2 {
      color: #004498;
      font-family: "HiraKakuProN-W6", "ヒラギノ角ゴ ProN W6", "FP-ヒラギノ角ゴ ProN W6", sans-serif;
      font-size: 16px;
      padding-bottom: 10px;
      line-height: 1.5; }
    #policy #zone1 .text {
      padding-bottom: 50px; }
      #policy #zone1 .text a {
        color: #004498;
        text-decoration: underline; }
    #policy #zone1 .btn {
      padding-bottom: 50px; }
      #policy #zone1 .btn a {
        width: 280px;
        font-size: 14px; }
  #cookie #zone1 {
    padding: 0 20px; }
    #cookie #zone1 h2 {
      color: #004498;
      font-family: "HiraKakuProN-W6", "ヒラギノ角ゴ ProN W6", "FP-ヒラギノ角ゴ ProN W6", sans-serif;
      font-size: 16px;
      padding-bottom: 10px;
      line-height: 1.5; }
    #cookie #zone1 .text {
      padding-bottom: 50px; }
      #cookie #zone1 .text.ex {
        word-break: break-all; }
      #cookie #zone1 .text a {
        text-decoration: underline; }
  #foundation #zone1 {
    position: relative;
    margin-bottom: 50px;
    background: #EFF3F6; }
    #foundation #zone1 .in {
      position: relative;
      z-index: 1;
      padding: 50px 20px; }
      #foundation #zone1 .in .set .mds1 {
        padding-bottom: 20px; }
      #foundation #zone1 .in .set figure {
        padding-top: 30px; }
  #foundation #zone2 {
    margin-bottom: 50px;
    padding: 0 20px; }
    #foundation #zone2 ul li {
      background: #EFF3F6;
      border: 1px solid #CFDDEA;
      padding: 20px;
      margin-bottom: 20px; }
      #foundation #zone2 ul li .mds2 {
        font-size: 26px;
        color: #004498;
        padding-bottom: 10px; }
      #foundation #zone2 ul li h4 {
        text-align: center;
        font-size: 16px;
        font-weight: bold;
        padding-bottom: 20px; }
      #foundation #zone2 ul li figure {
        padding-top: 30px; }
  #foundation #zone3 {
    background: #EFF3F6;
    padding: 50px 20px;
    margin-bottom: 50px; }
    #foundation #zone3 .in .sec1 {
      padding-bottom: 20px; }
      #foundation #zone3 .in .sec1 h4 {
        text-align: center;
        color: #fff;
        font-weight: bold;
        padding: 7px;
        margin-bottom: 10px; }
      #foundation #zone3 .in .sec1 .text {
        line-height: 1.6;
        padding-top: 10px; }
      #foundation #zone3 .in .sec1 .set1 {
        background: #fff;
        border: 1px solid #CFDDEA;
        padding: 20px;
        margin-bottom: 20px; }
        #foundation #zone3 .in .sec1 .set1 .pos1 {
          padding-bottom: 30px; }
          #foundation #zone3 .in .sec1 .set1 .pos1 .m1 {
            background: #004498; }
        #foundation #zone3 .in .sec1 .set1 .pos2 {
          width: 220px;
          margin: auto; }
      #foundation #zone3 .in .sec1 .set2 {
        background: #fff;
        border: 1px solid #CFDDEA;
        padding: 20px; }
        #foundation #zone3 .in .sec1 .set2 .m2 {
          background: #4A535B; }
    #foundation #zone3 .in .sec2 {
      background: #fff;
      border: 1px solid #CFDDEA;
      padding: 20px; }
      #foundation #zone3 .in .sec2 .set1 {
        padding-bottom: 20px; }
        #foundation #zone3 .in .sec2 .set1 h4 {
          text-align: center;
          color: #004498;
          font-weight: bold;
          font-size: 18px;
          padding-bottom: 10px; }
      #foundation #zone3 .in .sec2 .t1 {
        padding-top: 10px;
        line-height: 1.5;
        font-size: 12px; }
  #foundation #zone4 {
    padding: 0 20px 50px; }
    #foundation #zone4 .mds1 {
      padding-bottom: 20px; }
    #foundation #zone4 .text {
      text-align: center;
      padding-bottom: 10px; }
    #foundation #zone4 ul {
      display: flex;
      flex-wrap: wrap;
      flex-direction: row;
      justify-content: space-between; }
      #foundation #zone4 ul li {
        width: 48%;
        padding-bottom: 20px; }
        #foundation #zone4 ul li .t1 {
          text-align: center;
          color: #004498;
          font-weight: bold;
          padding-top: 10px; }
  #members_login #zone2 {
    margin: 50px 20px 0;
    padding: 50px 20px;
    background: #fff; }
    #members_login #zone2 .t1 {
      padding-bottom: 30px;
      font-family: "HiraKakuProN-W6", "ヒラギノ角ゴ ProN W6", "FP-ヒラギノ角ゴ ProN W6", sans-serif;
      font-size: 16px; }
      #members_login #zone2 .t1 input {
        border: 3px solid #eff3f6;
        width: 100%;
        height: 50px;
        margin-top: 10px;
        padding: 10px; }
    #members_login #zone2 .red {
      padding-bottom: 30px;
      font-family: "HiraKakuProN-W6", "ヒラギノ角ゴ ProN W6", "FP-ヒラギノ角ゴ ProN W6", sans-serif; }
    #members_login #zone2 .btn {
      padding-top: 20px; }
  #members_menu #zone2 {
    padding: 0px 20px 50px; }
    #members_menu #zone2 .menu1 a {
      display: block;
      box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.2); }
      #members_menu #zone2 .menu1 a .in {
        padding: 20px 20px 40px;
        position: relative;
        background: url("../img/arrow6.png") no-repeat right bottom;
        background-size: 40px auto; }
  #members_menu #zone3 {
    padding: 0px 20px 50px; }
    #members_menu #zone3 .in {
      background: #4a535b;
      color: #fff;
      padding: 40px 20px;
      box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.2); }
      #members_menu #zone3 .in dl {
        border-bottom: 1px dotted #fff;
        padding: 10px 0;
        line-height: 1.6; }
        #members_menu #zone3 .in dl dt {
          font-weight: 700; }
  #members_movie #zone2 {
    padding: 50px 20px; }
    #members_movie #zone2 .set {
      background: #fff;
      padding: 40px 20px 20px;
      margin-bottom: 30px; }
      #members_movie #zone2 .set ul li {
        padding-bottom: 10px; }
        #members_movie #zone2 .set ul li a {
          display: block;
          padding: 15px 10px;
          border: 1px solid #d1d7db;
          color: #004498;
          font-weight: 700;
          background: url("../img/arrow1.png") no-repeat right 20px center;
          background-size: 50px auto; }
          #members_movie #zone2 .set ul li a:hover {
            background-color: #f2f4f6; }
    #members_movie #zone2 .btn1 {
      width: 280px;
      font-size: 16px; }
  #members_movie #zone3 {
    padding: 50px 20px 0; }
    #members_movie #zone3 .mds1 {
      padding-bottom: 20px;
      padding-top: 10px; }
    #members_movie #zone3 .mds2 {
      color: #004498;
      margin-bottom: 0; }
    #members_movie #zone3 .set {
      background: #fff;
      padding: 20px;
      margin-bottom: 30px; }
      #members_movie #zone3 .set .movie {
        width: 100%;
        padding-bottom: 56.25%;
        height: 0px;
        position: relative; }
        #members_movie #zone3 .set .movie iframe {
          position: absolute;
          top: 0;
          left: 0;
          width: 100%;
          height: 100%; }
      #members_movie #zone3 .set dl {
        padding-top: 20px; }
        #members_movie #zone3 .set dl dd {
          line-height: 2;
          padding-top: 15px; }
    #members_movie #zone3 .btn1 {
      width: 280px;
      font-size: 16px; }
    #members_movie #zone3 .col2 .mds2 {
      font-size: 15px; } }

@media print, screen and (min-width: 761px) {
  .none, .sp {
    display: none; }
  a[href^="tel:"] {
    cursor: default;
    color: #4a535b;
    text-decoration: none; }
    a[href^="tel:"]:hover {
      opacity: 1; }
  a {
    transition: 0.3s;
    color: #4a535b; }
    a:hover {
      opacity: 0.7; }
  main {
    position: relative; }
  #maintitle {
    position: relative;
    height: 460px;
    background: url("../img/top/mainbg.png") no-repeat center top;
    background-size: 1600px auto;
    overflow: hidden; }
    #maintitle .in {
      max-width: 1000px;
      margin: auto;
      padding-top: 270px; }
      #maintitle .in h1, #maintitle .in .h1 {
        font-size: 24px;
        font-family: "HiraKakuProN-W6", "ヒラギノ角ゴ ProN W6", "FP-ヒラギノ角ゴ ProN W6", sans-serif; }
        #maintitle .in h1 strong, #maintitle .in .h1 strong {
          display: block;
          font-size: 42px;
          font-family: "DIN 2014", "DINNextLTPro-Medium", sans-serif;
          font-weight: 700;
          letter-spacing: 0.1em;
          color: #004498;
          padding-bottom: 10px; }
  #bread {
    max-width: 1000px;
    margin: auto;
    font-size: 14px;
    letter-spacing: 0.1em;
    padding: 30px 0 60px; }
    #bread a {
      display: inline-block;
      white-space: nowrap; }
    #bread li {
      display: inline; }
      #bread li::after {
        content: ' ｜ '; }
      #bread li:last-child::after {
        display: none; }
  .list_a {
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;
    justify-content: center;
    margin: 0 -15px; }
    .list_a li {
      width: calc(50% - 30px);
      background: #fff;
      margin: 0 15px 70px; }
      .list_a li a {
        display: block;
        padding: 25px;
        position: relative; }
        .list_a li a .new {
          position: absolute;
          top: 0;
          right: 0;
          background: #FF0000;
          color: #fff;
          font-size: 18px;
          font-family: "DIN 2014", "DINNextLTPro-Medium", sans-serif;
          font-weight: 700;
          padding: 8px;
          width: 85px;
          text-align: center;
          letter-spacing: 0.1em; }
        .list_a li a figure {
          overflow: hidden;
          position: relative; }
          .list_a li a figure::after {
            position: absolute;
            bottom: 0;
            right: 0;
            content: '';
            width: 50px;
            height: 50px;
            background: url("../img/arrow5.png") no-repeat center center;
            background-size: 50px auto; }
          .list_a li a figure img {
            transition: 0.5s; }
        .list_a li a:hover figure img {
          transform: scale(1.1); }
        .list_a li a .t1 {
          font-size: 24px;
          font-family: "HiraKakuProN-W6", "ヒラギノ角ゴ ProN W6", "FP-ヒラギノ角ゴ ProN W6", sans-serif;
          line-height: 1.5;
          padding: 20px 0; }
        .list_a li a .t2 {
          line-height: 1.5; }
  .list_c {
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;
    padding-bottom: 50px;
    margin: 0 -15px; }
    .list_c li {
      width: 33.333%;
      padding: 0 15px 50px; }
      .list_c li a {
        position: relative;
        display: block;
        line-height: 1.5; }
        .list_c li a .new {
          position: absolute;
          top: 0;
          right: 0;
          background: #FF0000;
          color: #fff;
          font-size: 14px;
          font-family: "DIN 2014", "DINNextLTPro-Medium", sans-serif;
          font-weight: 700;
          padding: 4px;
          width: 50px;
          text-align: center;
          letter-spacing: 0.1em;
          z-index: 1; }
        .list_c li a figure {
          overflow: hidden;
          position: relative; }
          .list_c li a figure::after {
            position: absolute;
            bottom: 0;
            right: 0;
            content: '';
            width: 40px;
            height: 40px;
            background: url("../img/arrow5.png") no-repeat center center;
            background-size: 40px auto; }
          .list_c li a figure img {
            transition: 0.5s; }
        .list_c li a:hover figure img {
          transform: scale(1.1); }
        .list_c li a .t1 {
          font-size: 18px;
          font-family: "HiraKakuProN-W6", "ヒラギノ角ゴ ProN W6", "FP-ヒラギノ角ゴ ProN W6", sans-serif;
          padding: 20px 0 15px; }
          .list_c li a .t1 i {
            display: inline-block;
            padding: 5px 20px;
            background: #eff3f6; }
        .list_c li a .t4 {
          font-size: 18px;
          font-family: "HiraKakuProN-W6", "ヒラギノ角ゴ ProN W6", "FP-ヒラギノ角ゴ ProN W6", sans-serif;
          padding-bottom: 10px; }
  #f_download {
    margin-top: 100px; }
    #f_download::after {
      position: absolute;
      top: 0;
      left: 0;
      content: '';
      width: calc(50% + 600px);
      min-width: 1200px;
      height: 100%;
      background: linear-gradient(to right, #213f9b, #03baf3);
      z-index: -1; }
    #f_download .in {
      max-width: 1000px;
      margin: auto;
      padding: 100px 0;
      text-align: center;
      color: #fff; }
      #f_download .in h2 {
        padding-bottom: 30px; }
        #f_download .in h2 i {
          display: block;
          font-size: 20px;
          padding-bottom: 20px; }
        #f_download .in h2 b {
          display: block;
          font-size: 36px;
          font-family: "HiraKakuProN-W6", "ヒラギノ角ゴ ProN W6", "FP-ヒラギノ角ゴ ProN W6", sans-serif; }
      #f_download .in .text {
        padding-bottom: 40px; }
      #f_download .in ul {
        display: flex;
        flex-wrap: wrap;
        flex-direction: row;
        justify-content: center; }
        #f_download .in ul li {
          width: calc(50% - 40px);
          box-shadow: 5px 5px 15px rgba(0, 0, 0, 0.3);
          padding: 30px 0;
          margin: 20px; }
          #f_download .in ul li .t1 {
            font-size: 20px;
            font-family: "HiraKakuProN-W6", "ヒラギノ角ゴ ProN W6", "FP-ヒラギノ角ゴ ProN W6", sans-serif;
            line-height: 1.5;
            padding-bottom: 20px; }
          #f_download .in ul li figure {
            width: 220px;
            margin: auto;
            padding-bottom: 20px;
            position: relative; }
            #f_download .in ul li figure.ex1 {
              width: 140px; }
            #f_download .in ul li figure a {
              display: block;
              position: absolute;
              top: 0;
              left: 0; }
              #f_download .in ul li figure a:hover {
                opacity: 0; }
        #f_download .in ul .s0 {
          background: linear-gradient(to right, #213f9b, #03baf3); }
        #f_download .in ul .s1 {
          background: url("../img/download/p1.jpg") no-repeat center center;
          background-size: cover; }
        #f_download .in ul .s2 {
          background: url("../img/download/p2.jpg") no-repeat center center;
          background-size: cover; }
        #f_download .in ul .s3 {
          background: url("../img/download/p3.jpg") no-repeat center center;
          background-size: cover; }
        #f_download .in ul .s4 {
          background: url("../img/download/p4.jpg") no-repeat center center;
          background-size: cover; }
      #f_download .in .catalog {
        position: relative;
        background: url("../img/download/ca1.jpg") no-repeat center center;
        background-size: cover;
        padding: 30px 0;
        margin: 20px;
        box-shadow: 5px 5px 15px rgba(0, 0, 0, 0.3); }
        #f_download .in .catalog .t1 {
          font-size: 20px;
          font-family: "HiraKakuProN-W6", "ヒラギノ角ゴ ProN W6", "FP-ヒラギノ角ゴ ProN W6", sans-serif;
          line-height: 1.5;
          padding-bottom: 20px; }
        #f_download .in .catalog figure {
          position: absolute;
          bottom: 0;
          right: 40px;
          width: 140px;
          box-shadow: 5px 5px 15px rgba(0, 0, 0, 0.3); }
  .jirei {
    max-width: 1100px;
    margin: auto;
    background: #fff; }
    .jirei .in {
      max-width: 1000px;
      margin: auto;
      padding-bottom: 120px; }
      .jirei .in h2 {
        padding-bottom: 60px;
        text-align: center; }
        .jirei .in h2 i {
          color: #004498;
          font-size: 20px;
          display: inline-block;
          position: relative; }
          .jirei .in h2 i::after {
            position: absolute;
            bottom: -3px;
            left: 0;
            content: '';
            width: 100%;
            height: 10px;
            background: #e1e7ec;
            z-index: -1; }
        .jirei .in h2 b {
          display: block;
          font-family: "HiraKakuProN-W6", "ヒラギノ角ゴ ProN W6", "FP-ヒラギノ角ゴ ProN W6", sans-serif;
          font-size: 36px;
          padding-top: 20px; }
  .susumelist2 {
    max-width: 1100px;
    margin: auto;
    background: #fff; }
    .susumelist2 .in {
      max-width: 1000px;
      margin: auto;
      padding-bottom: 120px; }
      .susumelist2 .in .list_c {
        padding-bottom: 0; }
        .susumelist2 .in .list_c .t1 {
          padding-bottom: 5px; }
  .susume .in {
    max-width: 1000px;
    margin: auto;
    padding-top: 120px; }
    .susume .in h2 {
      padding-bottom: 60px; }
      .susume .in h2 i {
        color: #004498;
        font-size: 20px;
        display: inline-block;
        position: relative; }
        .susume .in h2 i::after {
          position: absolute;
          bottom: -3px;
          left: 0;
          content: '';
          width: 100%;
          height: 10px;
          background: #e1e7ec;
          z-index: -1; }
      .susume .in h2 b {
        display: block;
        font-family: "HiraKakuProN-W6", "ヒラギノ角ゴ ProN W6", "FP-ヒラギノ角ゴ ProN W6", sans-serif;
        font-size: 36px;
        padding-top: 20px; }
  #esform::after {
    position: absolute;
    top: 0;
    left: 0;
    content: '';
    width: calc(50% + 600px);
    min-width: 1200px;
    height: 100%;
    background: linear-gradient(to right, #213f9b, #03baf3);
    z-index: -1; }
  #esform .in {
    max-width: 1000px;
    margin: auto;
    padding: 100px 0; }
    #esform .in h2 {
      text-align: center;
      margin-bottom: 30px;
      color: #fff; }
      #esform .in h2 i {
        display: inline-block;
        text-align: left;
        background: url("../img/top/cal.png") no-repeat left top;
        background-size: 90px auto;
        height: 120px;
        padding-left: 110px;
        padding-top: 20px; }
        #esform .in h2 i strong {
          display: block;
          font-size: 36px;
          font-family: "HiraKakuProN-W6", "ヒラギノ角ゴ ProN W6", "FP-ヒラギノ角ゴ ProN W6", sans-serif;
          padding-top: 20px; }
    #esform .in figure {
      padding-bottom: 50px; }
    #esform .in .t2 {
      max-width: 800px;
      margin: auto;
      color: #fff; }
    #esform .in .fuki {
      color: #fff;
      border: 1px solid #fff;
      padding: 30px 50px;
      margin: 30px 0; }
      #esform .in .fuki h3 {
        display: flex;
        flex-wrap: wrap;
        flex-direction: row;
        align-items: center;
        font-weight: bold;
        font-size: 18px;
        margin-bottom: 20px; }
        #esform .in .fuki h3 strong {
          display: inline-block;
          border-top: 1px solid #fff;
          border-bottom: 1px solid #fff;
          font-size: 16px;
          padding: 8px 0;
          margin-right: 20px; }
      #esform .in .fuki .btnset {
        display: flex;
        flex-wrap: wrap;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        padding-top: 10px; }
        #esform .in .fuki .btnset .btn img {
          width: auto;
          height: 50px; }
        #esform .in .fuki .btnset .bnr {
          width: 320px; }
    #esform .in .box {
      background: #fff;
      padding: 50px;
      box-shadow: 5px 5px 15px rgba(0, 0, 0, 0.3); }
      #esform .in .box .simulator {
        display: flex;
        flex-wrap: wrap;
        flex-direction: row;
        justify-content: space-between; }
        #esform .in .box .simulator li {
          width: 18%; }
          #esform .in .box .simulator li i {
            display: block;
            padding-bottom: 10px;
            font-family: "HiraKakuProN-W6", "ヒラギノ角ゴ ProN W6", "FP-ヒラギノ角ゴ ProN W6", sans-serif;
            font-size: 18px; }
          #esform .in .box .simulator li:nth-of-type(2) {
            width: 25%; }
      #esform .in .box .t1 {
        font-size: 14px;
        line-height: 1.5;
        padding: 30px 0; }
  header {
    position: fixed;
    width: calc(100% - 80px);
    min-width: 1170px;
    height: 130px;
    line-height: 1;
    z-index: 10;
    top: 0px;
    left: 40px; }
    header .in {
      margin-top: 30px;
      height: 100px;
      background: #fff;
      display: flex;
      flex-wrap: wrap;
      flex-direction: row;
      align-items: center;
      justify-content: space-between;
      padding: 0 30px; }
      header .in h1 {
        width: 200px; }
      header .in .set {
        width: 900px;
        display: flex;
        flex-wrap: wrap;
        flex-direction: row;
        align-items: center;
        justify-content: space-between; }
      header .in ul {
        width: 770px;
        display: flex;
        flex-wrap: wrap;
        flex-direction: row;
        align-items: center;
        justify-content: space-between;
        font-family: "HiraKakuProN-W6", "ヒラギノ角ゴ ProN W6", "FP-ヒラギノ角ゴ ProN W6", sans-serif; }
        header .in ul a {
          display: inline-block;
          padding: 20px 0;
          position: relative; }
          header .in ul a::after {
            position: absolute;
            bottom: 10px;
            left: 50%;
            content: '';
            width: 0;
            height: 4px;
            background: linear-gradient(to right, #213f9b, #03baf3);
            transition: .3s;
            transform: translateX(-50%); }
          header .in ul a:hover {
            opacity: 1;
            color: #004498; }
            header .in ul a:hover::after {
              width: 100%; }
          header .in ul a .new {
            position: absolute;
            top: -3px;
            left: 0;
            background: #FF0000;
            color: #fff;
            font-size: 10px;
            font-family: "DIN 2014", "DINNextLTPro-Medium", sans-serif;
            font-weight: 700;
            padding: 3px;
            text-align: center;
            letter-spacing: 0.1em; }
      header .in .btn {
        width: 115px;
        border-radius: 3px;
        overflow: hidden; }
        header .in .btn .hbtn {
          display: flex;
          flex-wrap: wrap;
          flex-direction: row;
          align-items: center;
          justify-content: center;
          width: 100%;
          height: 60px;
          font-family: "HiraKakuProN-W6", "ヒラギノ角ゴ ProN W6", "FP-ヒラギノ角ゴ ProN W6", sans-serif;
          background: linear-gradient(to right, #213f9b, #03baf3);
          color: #fff;
          border: 4px solid #213f9b;
          border-image: linear-gradient(to right, #213f9b 0%, #03baf3 100%);
          border-image-slice: 1; }
          header .in .btn .hbtn:hover {
            opacity: 1;
            background: #fff;
            color: #004498; }
  #menu {
    position: fixed;
    width: 100%;
    height: 100%;
    top: 0px;
    left: 0px;
    opacity: 0;
    transition-duration: 0.3s;
    z-index: 0;
    line-height: 1;
    pointer-events: none;
    display: flex;
    justify-content: flex-end;
    font-size: 18px;
    background: rgba(0, 0, 0, 0.5);
    transform: scale(1.2); }
    #menu.on {
      opacity: 1;
      pointer-events: auto;
      z-index: 99;
      transform: scale(1); }
    #menu #mclose {
      width: calc(100% - 850px); }
    #menu .in {
      min-height: 100%;
      padding: 150px 100px 50px;
      background: #9da4a8;
      color: #fff;
      overflow-y: auto; }
      #menu .in .nav {
        width: 650px; }
        #menu .in .nav > li {
          padding-bottom: 50px; }
          #menu .in .nav > li > i {
            font-family: "DIN 2014", "DINNextLTPro-Medium", sans-serif;
            font-weight: 400;
            font-size: 13px;
            letter-spacing: 0.1em;
            display: block;
            padding-bottom: 30px; }
          #menu .in .nav > li ul {
            display: flex;
            flex-wrap: wrap;
            flex-direction: row;
            justify-content: space-between; }
            #menu .in .nav > li ul li {
              width: 300px;
              padding-bottom: 30px; }
              #menu .in .nav > li ul li a {
                position: relative;
                display: block;
                color: #fff;
                letter-spacing: 0.1em;
                font-size: 12px;
                font-weight: bold;
                z-index: 1; }
                #menu .in .nav > li ul li a i {
                  display: block;
                  font-size: 22px;
                  padding-bottom: 5px;
                  font-family: "DIN 2014", "DINNextLTPro-Medium", sans-serif;
                  font-weight: 400; }
                #menu .in .nav > li ul li a::before {
                  position: absolute;
                  top: calc(50% - 24px);
                  right: 0;
                  content: '';
                  width: 48px;
                  height: 48px;
                  background: url("../img/arrow1.png") no-repeat center center;
                  background-size: 15px;
                  border: 1px solid rgba(255, 255, 255, 0.3);
                  border-radius: 25px;
                  z-index: -1;
                  transition: 0.3s; }
                #menu .in .nav > li ul li a:hover::before {
                  background: url("../img/arrow1.png") no-repeat center center rgba(255, 255, 255, 0.2);
                  background-size: 15px; }
      #menu .in .sub {
        display: flex;
        flex-wrap: wrap;
        flex-direction: row;
        justify-content: space-between;
        align-items: center; }
        #menu .in .sub .copyright {
          letter-spacing: 0.1em;
          font-size: 10px;
          font-family: "DIN 2014", "DINNextLTPro-Medium", sans-serif;
          font-weight: 400; }
  .hm_button * {
    margin: 0;
    padding: 0;
    outline: none;
    border: none;
    font: inherit;
    font-family: inherit;
    font-size: 100%;
    font-style: inherit;
    font-weight: inherit;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    text-align: left;
    text-decoration: none;
    list-style: none; }
  .hm_button {
    display: block;
    padding: 0;
    width: 64px;
    height: 17px;
    position: relative;
    background: none;
    border: none;
    text-align: center;
    letter-spacing: 0.1em;
    cursor: pointer;
    outline: none; }
  .hm_button .hm_bar {
    display: block;
    width: 64px;
    height: 1px;
    transition: all 0.2s;
    transform-origin: 0% 0%;
    transform: translateY(-50%);
    position: absolute;
    left: 0; }
  .hm_button .hm_bar1 {
    top: 0; }
  .hm_button .hm_bar2 {
    top: 50%; }
  .hm_button .hm_bar3 {
    top: 100%; }
  .hm_button.active .hm_bar {
    width: 66.22px;
    left: 0px; }
  .hm_button.active .hm_bar1 {
    transform: rotate(0.25963rad) translateY(-50%);
    top: 0px; }
  .hm_button.active .hm_bar2 {
    opacity: 0; }
  .hm_button.active .hm_bar3 {
    transform: rotate(-0.25963rad) translateY(-50%);
    top: calc(100% - 0px); }
  .hm_button.active .hm_menu_text {
    display: none; }
  .hm_button.active .hm_close {
    display: block; }
  .hm_button .hm_bar {
    background-color: #4f5254; }
  footer {
    min-width: 950px;
    position: relative;
    margin-top: 100px; }
    footer .bg {
      background: #fff;
      padding: 50px 0 40px; }
      footer .bg .in {
        max-width: 1000px;
        margin: auto;
        display: flex;
        flex-wrap: wrap;
        flex-direction: row;
        justify-content: space-between; }
        footer .bg .in .sec1 {
          width: 800px; }
          footer .bg .in .sec1 .logo img {
            width: 240px; }
          footer .bg .in .sec1 .logo i {
            display: block;
            font-size: 14px;
            color: #004498;
            padding: 15px 0; }
          footer .bg .in .sec1 .nav1 {
            display: flex;
            flex-wrap: wrap;
            flex-direction: row;
            justify-content: space-between;
            width: 800px;
            font-family: "HiraKakuProN-W6", "ヒラギノ角ゴ ProN W6", "FP-ヒラギノ角ゴ ProN W6", sans-serif;
            padding-top: 40px; }
          footer .bg .in .sec1 .ex {
            margin-left: 30px; }
        footer .bg .in .sec2 {
          width: 200px; }
          footer .bg .in .sec2 .nav2 {
            font-family: "HiraKakuProN-W6", "ヒラギノ角ゴ ProN W6", "FP-ヒラギノ角ゴ ProN W6", sans-serif;
            text-align: center; }
            footer .bg .in .sec2 .nav2 li {
              padding-bottom: 10px; }
              footer .bg .in .sec2 .nav2 li a {
                display: flex;
                justify-content: center;
                align-items: center;
                width: 200px;
                height: 50px; }
              footer .bg .in .sec2 .nav2 li .fbtn1 {
                background: #eff3f6; }
                footer .bg .in .sec2 .nav2 li .fbtn1 i {
                  position: relative;
                  padding-left: 25px; }
                  footer .bg .in .sec2 .nav2 li .fbtn1 i::after {
                    position: absolute;
                    top: calc(50% - 10px);
                    left: 0;
                    content: '';
                    width: 20px;
                    height: 20px;
                    background: url("../img/mail.png") no-repeat center center;
                    background-size: 20px;
                    z-index: 1; }
              footer .bg .in .sec2 .nav2 li .fbtn2 {
                background: #4a535b;
                color: #fff; }
                footer .bg .in .sec2 .nav2 li .fbtn2 i {
                  position: relative;
                  padding-left: 25px; }
                  footer .bg .in .sec2 .nav2 li .fbtn2 i::after {
                    position: absolute;
                    top: calc(50% - 10px);
                    left: 0;
                    content: '';
                    width: 20px;
                    height: 20px;
                    background: url("../img/dl.png") no-repeat center center;
                    background-size: 20px;
                    z-index: 1; }
              footer .bg .in .sec2 .nav2 li .fbtn3 {
                background: linear-gradient(to right, #213f9b, #03baf3);
                color: #fff; }
    footer .sec3 .in {
      max-width: 1000px;
      margin: auto;
      height: 80px;
      display: flex;
      flex-wrap: wrap;
      flex-direction: row;
      justify-content: space-between;
      align-items: center;
      font-size: 14px; }
      footer .sec3 .in ul {
        display: flex; }
        footer .sec3 .in ul li::after {
          content: '　｜　'; }
        footer .sec3 .in ul li:last-child::after {
          display: none; }
      footer .sec3 .in .copyright {
        font-family: "Helvetica-Light", "HelveticaLTPro-Light", sans-serif; }
  #pagetop {
    position: fixed;
    right: 10px;
    bottom: 10px;
    z-index: 1;
    cursor: pointer; }
    #pagetop img {
      width: 60px; }
  #toppage main {
    z-index: 1; }
    #toppage main::before {
      position: absolute;
      top: -800px;
      left: calc(50% - 950px);
      content: '';
      width: 0;
      height: 0;
      border-style: solid;
      border-width: 0 0 2000px 4000px;
      border-color: transparent transparent #fff transparent;
      z-index: -1; }
    #toppage main::after {
      position: absolute;
      top: 1200px;
      left: calc(50% - 950px);
      content: '';
      width: 0;
      height: 0;
      border-style: solid;
      border-width: 0 3000px 2000px 0;
      border-color: transparent #fff transparent transparent;
      z-index: -1; }
  #toppage #zone1 {
    height: 1200px;
    background: url("../img/top/mainbg.png") no-repeat center top;
    background-size: 1800px auto; }
    #toppage #zone1 .in {
      max-width: 1000px;
      margin: auto;
      padding-top: 310px; }
      #toppage #zone1 .in h1 {
        width: 360px;
        padding-bottom: 70px; }
      #toppage #zone1 .in .link {
        padding-bottom: 100px; }
        #toppage #zone1 .in .link .btn1 {
          width: 400px;
          margin: 0; }
      #toppage #zone1 .in .copy {
        width: 620px;
        padding-bottom: 30px; }
      #toppage #zone1 .in .text i {
        display: block;
        font-family: 28px;
        color: #004498;
        font-family: "HiraKakuProN-W6", "ヒラギノ角ゴ ProN W6", "FP-ヒラギノ角ゴ ProN W6", sans-serif;
        font-size: 28px;
        padding: 30px 0; }
  #toppage #zone_news {
    padding-bottom: 70px; }
    #toppage #zone_news h2 {
      text-align: center;
      font-weight: bold;
      font-size: 32px;
      padding-bottom: 30px; }
    #toppage #zone_news .in {
      max-width: 800px;
      margin: auto;
      font-weight: bold;
      line-height: 1.5; }
      #toppage #zone_news .in dl {
        display: flex;
        flex-wrap: wrap;
        flex-direction: row;
        justify-content: space-between;
        padding-bottom: 10px; }
        #toppage #zone_news .in dl dt {
          width: 120px;
          color: #004498; }
        #toppage #zone_news .in dl dd {
          width: calc(100% - 120px); }
          #toppage #zone_news .in dl dd a {
            text-decoration: underline; }
  #toppage #zone3 {
    padding-bottom: 120px; }
  #toppage #zone4, #toppage #zone5 {
    padding-top: 120px; }
    #toppage #zone4::after, #toppage #zone5::after {
      position: absolute;
      top: 0;
      right: 0;
      content: '';
      width: calc(50% + 600px);
      min-width: 1200px;
      height: 100%;
      background: #fff;
      z-index: -10; }
  #toppage #zone5 {
    margin-top: 100px; }
  #modal {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100vh;
    background: rgba(255, 255, 255, 0.8);
    display: flex;
    align-items: center;
    justify-content: center;
    opacity: 0;
    transition-duration: 0.3s;
    z-index: 0;
    pointer-events: none;
    transform: scale(1.2); }
    #modal.on {
      opacity: 1;
      pointer-events: auto;
      z-index: 99999;
      transform: scale(1); }
    #modal .modalbg {
      height: 100vh;
      width: 100%;
      position: absolute; }
    #modal .in {
      background: #fff;
      width: 700px;
      padding: 30px 50px;
      position: relative;
      box-shadow: 5px 5px 15px rgba(0, 0, 0, 0.3); }
      #modal .in figure {
        width: 100px;
        margin: auto; }
      #modal .in .mtitle {
        font-size: 24px;
        font-family: "HiraKakuProN-W6", "ヒラギノ角ゴ ProN W6", "FP-ヒラギノ角ゴ ProN W6", sans-serif;
        padding: 15px 0;
        text-align: center;
        line-height: 1.5; }
      #modal .in h3 {
        font-size: 18px;
        font-family: "HiraKakuProN-W6", "ヒラギノ角ゴ ProN W6", "FP-ヒラギノ角ゴ ProN W6", sans-serif;
        padding-bottom: 15px;
        text-align: center;
        line-height: 1.5;
        color: #d2001e; }
      #modal .in h4 {
        font-family: "HiraKakuProN-W6", "ヒラギノ角ゴ ProN W6", "FP-ヒラギノ角ゴ ProN W6", sans-serif;
        padding: 20px 0 5px; }
      #modal .in .check {
        text-align: center;
        padding: 20px;
        font-size: 18px;
        font-family: "HiraKakuProN-W6", "ヒラギノ角ゴ ProN W6", "FP-ヒラギノ角ゴ ProN W6", sans-serif; }
        #modal .in .check i {
          display: inline-block;
          padding: 15px 25px;
          border: 1px solid #4a535b; }
      #modal .in #cbtn {
        position: absolute;
        top: 15px;
        right: 10px;
        background: url("../img/close.png") no-repeat left top;
        background-size: 50px;
        width: 50px;
        height: 50px;
        cursor: pointer; }
      #modal .in .termsbox {
        overflow-y: scroll;
        width: 100%;
        height: 100px;
        font-size: 14px;
        padding: 10px 20px 10px 10px;
        border: 1px solid #e0e0e0;
        margin: 10px 0;
        background: #fcfcfc; }
        #modal .in .termsbox h2 {
          font-family: "HiraKakuProN-W6", "ヒラギノ角ゴ ProN W6", "FP-ヒラギノ角ゴ ProN W6", sans-serif;
          padding-bottom: 10px; }
        #modal .in .termsbox .text {
          padding-bottom: 20px; }
  #estimate #zone1 {
    padding-bottom: 50px; }
    #estimate #zone1 .in {
      max-width: 1000px;
      margin: auto; }
      #estimate #zone1 .in figure {
        width: 140px;
        margin: auto; }
      #estimate #zone1 .in h2 {
        font-size: 36px;
        font-family: "HiraKakuProN-W6", "ヒラギノ角ゴ ProN W6", "FP-ヒラギノ角ゴ ProN W6", sans-serif;
        padding: 40px 0;
        text-align: center; }
      #estimate #zone1 .in h3 {
        font-size: 24px;
        font-family: "HiraKakuProN-W6", "ヒラギノ角ゴ ProN W6", "FP-ヒラギノ角ゴ ProN W6", sans-serif;
        text-align: center;
        padding-bottom: 10px; }
      #estimate #zone1 .in .text {
        text-align: center;
        padding-bottom: 30px; }
      #estimate #zone1 .in .box {
        width: 500px;
        margin: auto; }
        #estimate #zone1 .in .box .price {
          background: #eff3f6;
          font-size: 24px;
          font-family: sans-serif;
          font-weight: bold;
          letter-spacing: 0.1em;
          text-align: center;
          padding: 30px; }
        #estimate #zone1 .in .box .t1 {
          text-align: right;
          padding-top: 10px; }
        #estimate #zone1 .in .box .btn {
          padding-top: 30px; }
  #estimate #zone2 {
    padding-bottom: 100px; }
    #estimate #zone2 .in {
      max-width: 1000px;
      margin: auto; }
      #estimate #zone2 .in h2 {
        text-align: center;
        line-height: 2;
        padding: 40px 0 20px;
        border-top: 2px solid #4a535b; }
      #estimate #zone2 .in .t1 {
        padding-bottom: 40px;
        border-bottom: 2px solid #4a535b;
        margin-bottom: 40px; }
      #estimate #zone2 .in h3 {
        text-align: center;
        color: #004498;
        font-size: 20px;
        font-family: "HiraKakuProN-W6", "ヒラギノ角ゴ ProN W6", "FP-ヒラギノ角ゴ ProN W6", sans-serif;
        padding: 50px 0; }
        #estimate #zone2 .in h3 small {
          display: block;
          padding-top: 15px;
          font-size: 14px; }
      #estimate #zone2 .in .text {
        padding-bottom: 20px; }
        #estimate #zone2 .in .text b {
          font-family: "HiraKakuProN-W6", "ヒラギノ角ゴ ProN W6", "FP-ヒラギノ角ゴ ProN W6", sans-serif; }
        #estimate #zone2 .in .text strong {
          color: #004498;
          font-family: "HiraKakuProN-W6", "ヒラギノ角ゴ ProN W6", "FP-ヒラギノ角ゴ ProN W6", sans-serif; }
        #estimate #zone2 .in .text i {
          font-family: "HiraKakuProN-W6", "ヒラギノ角ゴ ProN W6", "FP-ヒラギノ角ゴ ProN W6", sans-serif;
          color: #d2001e; }
  #estimate .gform {
    width: 780px;
    margin: auto;
    margin-top: -30px;
    padding: 30px;
    background: #e2e2e2; }
    #estimate .gform iframe {
      width: 740px;
      height: 1300px; }
  #recommend #zone2 {
    max-width: 1000px;
    margin: auto;
    padding-top: 100px; }
  #recommend_single #zone2 {
    height: 490px;
    margin-bottom: 100px;
    display: flex;
    align-items: center; }
    #recommend_single #zone2 .in {
      max-width: 730px;
      margin: auto;
      color: #fff; }
      #recommend_single #zone2 .in h2 {
        font-size: 28px;
        font-weight: bold;
        text-align: center;
        padding-bottom: 35px;
        margin-bottom: 30px;
        position: relative; }
        #recommend_single #zone2 .in h2::after {
          position: absolute;
          bottom: 0;
          left: calc(50% - 50px);
          content: '';
          width: 100px;
          height: 2px;
          background: #fff; }
  #recommend_single #zone3 {
    max-width: 1100px;
    margin: auto;
    background: #fff;
    padding: 80px 0 120px; }
    #recommend_single #zone3 ul li {
      display: flex;
      flex-wrap: wrap;
      flex-direction: row-reverse;
      padding-bottom: 120px; }
      #recommend_single #zone3 ul li.ex {
        align-items: center; }
      #recommend_single #zone3 ul li figure {
        width: 600px; }
      #recommend_single #zone3 ul li .in {
        width: calc(100% - 600px);
        padding: 0 50px; }
        #recommend_single #zone3 ul li .in.ex {
          width: 100%;
          margin-top: 30px; }
        #recommend_single #zone3 ul li .in h3 {
          font-size: 23px;
          font-weight: bold;
          line-height: 1.5;
          padding-bottom: 20px; }
        #recommend_single #zone3 ul li .in h4 {
          font-size: 18px;
          font-weight: bold;
          line-height: 1.5;
          margin: 30px 0 10px; }
        #recommend_single #zone3 ul li .in h5 {
          font-size: 16px;
          font-weight: bold;
          line-height: 1.5; }
        #recommend_single #zone3 ul li .in .text a {
          color: #004498;
          font-weight: bold; }
      #recommend_single #zone3 ul li:nth-of-type(2n) {
        flex-direction: row; }
      #recommend_single #zone3 ul li .box {
        width: 100%;
        margin-top: 65px; }
        #recommend_single #zone3 ul li .box .bg {
          max-width: 900px;
          margin: auto;
          background: #EFF3F6;
          padding: 50px; }
          #recommend_single #zone3 ul li .box .bg figure {
            width: 480px;
            margin: auto;
            position: relative;
            margin-bottom: 30px; }
            #recommend_single #zone3 ul li .box .bg figure i {
              position: absolute;
              bottom: 0;
              left: 0;
              display: block;
              color: #fff;
              background: rgba(74, 83, 91, 0.5);
              width: 100%;
              text-align: center;
              padding: 5px; }
        #recommend_single #zone3 ul li .box .text {
          max-width: 700px;
          margin: auto;
          padding-bottom: 20px; }
    #recommend_single #zone3 ul table {
      width: 100%;
      margin: 30px 0;
      border-top: 1px solid #818181;
      border-left: 1px solid #818181; }
      #recommend_single #zone3 ul table th, #recommend_single #zone3 ul table td {
        border-bottom: 1px solid #818181;
        border-right: 1px solid #818181;
        vertical-align: middle;
        padding: 20px;
        line-height: 1.5; }
      #recommend_single #zone3 ul table th {
        background-color: #F7F9FA;
        white-space: nowrap;
        font-weight: bold; }
      #recommend_single #zone3 ul table .c {
        text-align: center; }
    #recommend_single #zone3 .t1 {
      color: #004498;
      font-size: 24px;
      font-family: "HiraKakuProN-W6", "ヒラギノ角ゴ ProN W6", "FP-ヒラギノ角ゴ ProN W6", sans-serif;
      text-align: center;
      padding-bottom: 30px; }
  #recommend_single .page1 #zone2 {
    background: url("../img/recommend/001/bg.jpg") no-repeat center center;
    background-size: cover; }
  #recommend_single .page2 #zone2 {
    background: url("../img/recommend/002/bg.jpg") no-repeat center center;
    background-size: cover; }
  #recommend_single .page3 #zone2 {
    background: url("../img/recommend/003/bg.jpg") no-repeat center center;
    background-size: cover; }
  #recommend_single .page4 #zone2 {
    background: url("../img/recommend/004/bg.jpg") no-repeat center center;
    background-size: cover; }
  #recommend_single .page5 #zone2 {
    background: url("../img/recommend/005/bg.jpg") no-repeat center center;
    background-size: cover; }
  #recommend_single .page6 #zone2 {
    background: url("../img/recommend/006/bg.jpg") no-repeat center center;
    background-size: cover; }
  #recommend_single .page7 #zone2 {
    background: url("../img/recommend/007/bg.jpg") no-repeat center center;
    background-size: cover; }
  #recommend_single .page8 #zone2 {
    background: url("../img/recommend/008/bg.jpg") no-repeat center center;
    background-size: cover; }
  #recommend_single .page8 #zone3 .box {
    margin: 30px 0; }
    #recommend_single .page8 #zone3 .box .text b {
      display: block;
      font-weight: bold;
      background-color: #E1E7EC;
      padding-left: 5px;
      margin-bottom: 5px; }
  #recommend_single .page9 #zone2 {
    background: url("../img/recommend/009/bg.jpg") no-repeat center center;
    background-size: cover; }
  #recommend_single .page9 #zone3 .in.ex .btn {
    margin-top: 30px; }
  #recommend_single .page10 #zone2 {
    background: url("../img/recommend/010/bg.jpg") no-repeat center center;
    background-size: cover; }
  #recommend_single .page10 #zone3 table {
    margin: 10px 0; }
  #recommend_single .page10 #zone3 .in.ex .btn {
    margin-top: 30px; }
  #recommend_single .page10 #zone3 .in.ex1 {
    width: 900px;
    margin: 50px auto; }
  #recommend_single .page10 #zone3 .c1 {
    color: #004498;
    font-weight: bold; }
  #recommend_single .page10 #zone3 .in.ex2 {
    width: 100%; }
  #recommend_single .page10 #zone3 .box {
    margin: 50px 0; }
    #recommend_single .page10 #zone3 .box .text b {
      display: block;
      font-weight: bold;
      background-color: #E1E7EC;
      padding-left: 5px;
      margin-bottom: 5px; }
  #recommend_single .page11 #zone2 {
    background: url("../img/recommend/011/bg.jpg") no-repeat center center;
    background-size: cover; }
  #recommend_single .page11 #zone3 table {
    margin: 10px 0; }
  #recommend_single .page11 #zone3 .in.ex .btn {
    margin-top: 30px; }
  #recommend_single .page11 #zone3 .in.ex1 {
    width: 900px;
    margin: 50px auto; }
  #recommend_single .page11 #zone3 .c1 {
    color: #004498;
    font-weight: bold; }
  #recommend_single .page11 #zone3 .in.ex2 {
    width: 100%; }
  #recommend_single .page11 #zone3 .box {
    margin: 50px 0; }
    #recommend_single .page11 #zone3 .box .text b {
      display: block;
      font-weight: bold;
      background-color: #E1E7EC;
      padding-left: 5px;
      margin-bottom: 5px; }
  #recommend_single .page12 #zone2 {
    background: url("../img/recommend/012/bg.jpg") no-repeat center center;
    background-size: cover; }
  #recommend_single .page12 #zone3 .in.ex {
    width: 100%; }
  #casestudy #zone1 .dropsec {
    border-bottom: 2px solid #e5e5e5; }
    #casestudy #zone1 .dropsec .dropnav {
      width: 700px;
      margin: auto;
      display: flex;
      justify-content: space-between;
      text-align: center; }
      #casestudy #zone1 .dropsec .dropnav .dropnav_ttl {
        width: 330px;
        position: relative;
        font-size: 18px; }
        #casestudy #zone1 .dropsec .dropnav .dropnav_ttl .navttl {
          display: block;
          font-family: "HiraKakuProN-W6", "ヒラギノ角ゴ ProN W6", "FP-ヒラギノ角ゴ ProN W6", sans-serif;
          padding: 20px;
          background: url("../img/arrow4.png") no-repeat right 20px center;
          background-size: 20px;
          cursor: pointer; }
          #casestudy #zone1 .dropsec .dropnav .dropnav_ttl .navttl::after {
            position: absolute;
            bottom: 0;
            left: 0;
            content: '';
            width: 100%;
            height: 7px;
            background: linear-gradient(to right, #213f9b, #03baf3);
            transition: 0.3s;
            opacity: 0; }
          #casestudy #zone1 .dropsec .dropnav .dropnav_ttl .navttl:hover::after {
            opacity: 1; }
        #casestudy #zone1 .dropsec .dropnav .dropnav_ttl .dropnav_box {
          display: none;
          position: absolute;
          top: 51px;
          left: 0;
          width: 100%;
          background: #fff;
          z-index: 2;
          padding-top: 7px;
          box-shadow: 5px 5px 10px rgba(0, 0, 0, 0.3); }
          #casestudy #zone1 .dropsec .dropnav .dropnav_ttl .dropnav_box::after {
            position: absolute;
            top: 0;
            left: 0;
            content: '';
            width: 100%;
            height: 7px;
            background: linear-gradient(to right, #213f9b, #03baf3); }
          #casestudy #zone1 .dropsec .dropnav .dropnav_ttl .dropnav_box a {
            display: block;
            padding: 20px; }
            #casestudy #zone1 .dropsec .dropnav .dropnav_ttl .dropnav_box a:hover {
              background: #eff3f6;
              opacity: 1; }
  #casestudy #zone2 {
    max-width: 1000px;
    margin: auto;
    padding-top: 100px; }
  #casestudy_single #zone2 {
    max-width: 1120px;
    margin: auto;
    height: 510px;
    display: flex;
    align-items: center; }
    #casestudy_single #zone2 figure {
      width: 840px;
      position: absolute;
      top: 0;
      left: calc(50% - 150px); }
    #casestudy_single #zone2 .in {
      background: #fff;
      width: 520px;
      min-height: 330px;
      display: flex;
      align-items: center;
      position: relative;
      padding: 25px 50px; }
      #casestudy_single #zone2 .in .t1 {
        color: #004498;
        font-family: "DIN 2014", "DINNextLTPro-Medium", sans-serif;
        font-size: 18px;
        font-weight: 700;
        display: flex;
        align-items: center; }
        #casestudy_single #zone2 .in .t1 b {
          font-size: 42px;
          margin-left: 5px; }
      #casestudy_single #zone2 .in h2 {
        font-family: "HiraKakuProN-W6", "ヒラギノ角ゴ ProN W6", "FP-ヒラギノ角ゴ ProN W6", sans-serif;
        font-size: 24px;
        line-height: 1.5;
        margin-bottom: 20px; }
      #casestudy_single #zone2 .in .t2 {
        line-height: 1.5; }
      #casestudy_single #zone2 .in dl {
        display: flex;
        flex-wrap: wrap;
        flex-direction: row;
        justify-content: space-between;
        line-height: 1.5; }
        #casestudy_single #zone2 .in dl dt {
          width: 7em;
          font-feature-settings: "palt";
          margin-bottom: 5px; }
        #casestudy_single #zone2 .in dl dd {
          width: calc(100% - 7em);
          margin-bottom: 5px;
          text-indent: -1em;
          padding-left: 1em; }
  #casestudy_single #zone3 {
    max-width: 1000px;
    margin: 50px auto 0;
    background: #fff;
    padding: 100px; }
    #casestudy_single #zone3 .set1 {
      display: flex;
      justify-content: space-between;
      padding-bottom: 120px; }
      #casestudy_single #zone3 .set1 > li {
        width: calc(50% - 15px);
        border: 5px solid #eff3f6;
        padding: 40px 40px 20px 40px; }
        #casestudy_single #zone3 .set1 > li:first-child {
          background: #eff3f6; }
        #casestudy_single #zone3 .set1 > li ol {
          counter-reset: item;
          list-style-type: none;
          padding-left: 0;
          line-height: 1.6; }
          #casestudy_single #zone3 .set1 > li ol li {
            text-indent: -1.3em;
            padding-left: 1.3em;
            padding-bottom: 20px; }
            #casestudy_single #zone3 .set1 > li ol li::before {
              counter-increment: item;
              content: counter(item) ".";
              padding-right: .4em;
              color: #004498; }
    #casestudy_single #zone3 .set2 {
      padding: 0px 50px 50px 50px; }
      #casestudy_single #zone3 .set2 h3 {
        font-family: "HiraKakuProN-W6", "ヒラギノ角ゴ ProN W6", "FP-ヒラギノ角ゴ ProN W6", sans-serif;
        font-size: 24px;
        line-height: 1.5;
        padding-bottom: 40px; }
        #casestudy_single #zone3 .set2 h3.ex {
          padding-bottom: 30px; }
      #casestudy_single #zone3 .set2 h4 {
        font-family: "HiraKakuProN-W6", "ヒラギノ角ゴ ProN W6", "FP-ヒラギノ角ゴ ProN W6", sans-serif;
        font-size: 18px;
        line-height: 1.5;
        color: #004498;
        text-indent: -1.2em;
        padding-left: 1.2em;
        padding-bottom: 10px; }
        #casestudy_single #zone3 .set2 h4::before {
          content: 'ー';
          padding-right: .2em; }
      #casestudy_single #zone3 .set2 .text {
        padding-bottom: 40px; }
        #casestudy_single #zone3 .set2 .text.ex {
          padding-bottom: 0px; }
    #casestudy_single #zone3 .pic_l {
      position: relative;
      width: 700px;
      margin-bottom: 80px; }
      #casestudy_single #zone3 .pic_l::after {
        position: absolute;
        top: -45px;
        left: calc(50% + 50px);
        content: '';
        width: 400px;
        height: 220px;
        background: linear-gradient(to right, #213f9b, #03baf3);
        z-index: -1; }
    #casestudy_single #zone3 .pic_r {
      position: relative;
      margin-bottom: 80px;
      text-align: right; }
      #casestudy_single #zone3 .pic_r img {
        width: 700px; }
      #casestudy_single #zone3 .pic_r::after {
        position: absolute;
        top: -45px;
        left: 0;
        content: '';
        width: 400px;
        height: 220px;
        background: linear-gradient(to right, #213f9b, #03baf3);
        z-index: -1; }
    #casestudy_single #zone3 .set3 {
      border: 1px solid #818181;
      padding: 50px; }
      #casestudy_single #zone3 .set3 .mds2 {
        font-size: 24px;
        line-height: 1.5; }
      #casestudy_single #zone3 .set3 .in {
        display: flex;
        justify-content: center;
        line-height: 1.6; }
        #casestudy_single #zone3 .set3 .in ul li {
          position: relative;
          padding-left: 30px;
          background: url("../img/casestudy/ck.png") no-repeat left 0;
          background-size: 25px;
          padding-bottom: 20px; }
    #casestudy_single #zone3 .photoset {
      margin-right: -15px;
      padding-top: 100px; }
      #casestudy_single #zone3 .photoset .slider {
        margin-right: 15px; }
      #casestudy_single #zone3 .photoset .thumbnail .slick-track {
        transform: unset !important;
        display: flex;
        flex-flow: row wrap; }
      #casestudy_single #zone3 .photoset .thumbnail .slick-slide {
        display: block !important;
        float: none !important;
        flex: 0 0 calc(100% / 6 - 15px);
        margin: 20px 14px 0 0; }
        #casestudy_single #zone3 .photoset .thumbnail .slick-slide img {
          cursor: pointer; }
      #casestudy_single #zone3 .photoset .thumbnail .slick-track:before {
        display: none; }
      #casestudy_single #zone3 .photoset .thumbnail .slick-track:after {
        display: none; }
      #casestudy_single #zone3 .photoset .thumbnail .slick-current {
        position: relative;
        z-index: 1; }
      #casestudy_single #zone3 .photoset .thumbnail .slick-current::after {
        content: '';
        width: 100%;
        height: 100%;
        border: 5px solid #004498;
        position: absolute;
        top: 0;
        left: 0;
        z-index: 2; }
  #qa #zone1 {
    max-width: 1000px;
    margin: auto; }
    #qa #zone1 .mds1 {
      padding-bottom: 50px;
      border-bottom: 10px solid #eff3f6; }
    #qa #zone1 .qanav {
      width: 700px;
      margin: auto;
      padding: 50px 0 80px; }
      #qa #zone1 .qanav li a {
        background: url("../img/qa/arrow.png") no-repeat left center;
        background-size: 20px;
        display: block;
        color: #004498;
        font-family: "HiraKakuProN-W6", "ヒラギノ角ゴ ProN W6", "FP-ヒラギノ角ゴ ProN W6", sans-serif;
        font-size: 18px;
        padding: 15px 40px; }
    #qa #zone1 .set {
      padding-bottom: 80px; }
      #qa #zone1 .set h3 {
        background: url("../img/qa/q.png") no-repeat left 20px center #eff3f6;
        background-size: 45px;
        font-family: "HiraKakuProN-W6", "ヒラギノ角ゴ ProN W6", "FP-ヒラギノ角ゴ ProN W6", sans-serif;
        font-size: 18px;
        padding: 30px;
        padding-left: 85px; }
      #qa #zone1 .set .text {
        background: url("../img/qa/a.png") no-repeat left 20px center;
        background-size: 45px;
        padding: 30px;
        padding-left: 85px; }
  #contact #zone1 {
    max-width: 1000px;
    margin: auto;
    padding-bottom: 50px; }
  #download #f_download {
    margin-top: 0px; }
    #download #f_download::after {
      display: none; }
    #download #f_download .in {
      padding-top: 0;
      color: #4a535b; }
      #download #f_download .in .t1 {
        color: #fff; }
  #terms #zone1 {
    max-width: 1000px;
    margin: auto;
    padding-bottom: 50px; }
    #terms #zone1 h2 {
      color: #004498;
      font-family: "HiraKakuProN-W6", "ヒラギノ角ゴ ProN W6", "FP-ヒラギノ角ゴ ProN W6", sans-serif;
      font-size: 18px;
      padding-bottom: 10px; }
    #terms #zone1 .text {
      padding-bottom: 50px; }
  #policy #zone1 {
    max-width: 1000px;
    margin: auto;
    padding-bottom: 50px; }
    #policy #zone1 h2 {
      color: #004498;
      font-family: "HiraKakuProN-W6", "ヒラギノ角ゴ ProN W6", "FP-ヒラギノ角ゴ ProN W6", sans-serif;
      font-size: 18px;
      padding-bottom: 10px; }
    #policy #zone1 .text {
      padding-bottom: 50px; }
      #policy #zone1 .text a {
        color: #004498;
        text-decoration: underline; }
    #policy #zone1 .btn {
      padding-bottom: 50px; }
      #policy #zone1 .btn a {
        width: 400px; }
  #cookie #zone1 {
    max-width: 1000px;
    margin: auto;
    padding-bottom: 50px; }
    #cookie #zone1 h2 {
      color: #004498;
      font-family: "HiraKakuProN-W6", "ヒラギノ角ゴ ProN W6", "FP-ヒラギノ角ゴ ProN W6", sans-serif;
      font-size: 18px;
      padding-bottom: 10px; }
    #cookie #zone1 .text {
      padding-bottom: 50px; }
      #cookie #zone1 .text a {
        text-decoration: underline; }
  #foundation #zone1 {
    position: relative;
    margin-bottom: 100px; }
    #foundation #zone1::after {
      position: absolute;
      top: 0;
      right: calc(50% - 320px);
      content: '';
      width: 200%;
      height: 100%;
      background: #EFF3F6; }
    #foundation #zone1 .in {
      position: relative;
      max-width: 1000px;
      margin: auto;
      z-index: 1;
      padding: 80px 0; }
      #foundation #zone1 .in .set {
        width: 500px; }
        #foundation #zone1 .in .set .mds1 {
          text-align: left;
          padding-bottom: 20px; }
        #foundation #zone1 .in .set figure {
          max-width: 410px;
          margin: auto;
          padding-top: 30px; }
    #foundation #zone1 .pic {
      position: absolute;
      top: calc(50% - 190px);
      left: calc(50% + 60px);
      width: 500px;
      z-index: 1; }
  #foundation #zone2 {
    max-width: 1000px;
    margin: auto;
    margin-bottom: 100px; }
    #foundation #zone2 ul {
      display: flex;
      flex-wrap: wrap;
      flex-direction: row;
      justify-content: space-between; }
      #foundation #zone2 ul li {
        width: 31%;
        background: #EFF3F6;
        border: 1px solid #CFDDEA;
        padding: 30px; }
        #foundation #zone2 ul li .mds2 {
          font-size: 30px;
          color: #004498;
          padding-bottom: 10px; }
        #foundation #zone2 ul li h4 {
          text-align: center;
          font-size: 20px;
          font-weight: bold;
          padding-bottom: 20px; }
        #foundation #zone2 ul li figure {
          padding-top: 30px; }
  #foundation #zone3 {
    background: #EFF3F6;
    padding: 100px 0;
    margin-bottom: 100px; }
    #foundation #zone3 .in {
      max-width: 1000px;
      margin: auto; }
      #foundation #zone3 .in .sec1 {
        display: flex;
        flex-wrap: wrap;
        flex-direction: row;
        justify-content: space-between;
        padding-bottom: 40px; }
        #foundation #zone3 .in .sec1 h4 {
          text-align: center;
          color: #fff;
          font-weight: bold;
          padding: 7px;
          margin-bottom: 10px; }
        #foundation #zone3 .in .sec1 .text {
          line-height: 1.6;
          padding-top: 10px; }
        #foundation #zone3 .in .sec1 .set1 {
          width: 62%;
          background: #fff;
          border: 1px solid #CFDDEA;
          padding: 30px 20px;
          display: flex;
          flex-wrap: wrap;
          flex-direction: row;
          justify-content: space-between; }
          #foundation #zone3 .in .sec1 .set1 .pos1 {
            width: 315px; }
            #foundation #zone3 .in .sec1 .set1 .pos1 .m1 {
              background: #004498; }
          #foundation #zone3 .in .sec1 .set1 .pos2 {
            width: 220px; }
        #foundation #zone3 .in .sec1 .set2 {
          width: 34%;
          background: #fff;
          border: 1px solid #CFDDEA;
          padding: 30px 20px; }
          #foundation #zone3 .in .sec1 .set2 .m2 {
            background: #4A535B; }
      #foundation #zone3 .in .sec2 {
        background: #fff;
        border: 1px solid #CFDDEA;
        padding: 50px; }
        #foundation #zone3 .in .sec2 .set1 {
          display: flex;
          justify-content: space-between;
          align-items: center;
          padding-bottom: 20px; }
          #foundation #zone3 .in .sec2 .set1 h4 {
            color: #004498;
            font-weight: bold;
            font-size: 20px; }
        #foundation #zone3 .in .sec2 .t1 {
          padding-top: 10px; }
  #foundation #zone4 {
    max-width: 1000px;
    margin: auto;
    padding-bottom: 100px; }
    #foundation #zone4 .mds1 {
      padding-bottom: 30px; }
    #foundation #zone4 .text {
      text-align: center;
      padding-bottom: 20px; }
    #foundation #zone4 ul {
      display: flex;
      flex-wrap: wrap;
      flex-direction: row;
      justify-content: space-between; }
      #foundation #zone4 ul li {
        width: 19%; }
        #foundation #zone4 ul li .t1 {
          text-align: center;
          color: #004498;
          font-weight: bold;
          padding-top: 10px; }
  #members_login #zone2 {
    max-width: 1000px;
    margin: auto;
    margin-top: 50px;
    padding: 100px 0;
    background: #fff; }
    #members_login #zone2 .t1 {
      width: 640px;
      margin: auto;
      padding-bottom: 30px;
      font-family: "HiraKakuProN-W6", "ヒラギノ角ゴ ProN W6", "FP-ヒラギノ角ゴ ProN W6", sans-serif;
      font-size: 18px; }
      #members_login #zone2 .t1 input {
        border: 3px solid #eff3f6;
        width: 100%;
        height: 50px;
        margin-top: 10px;
        padding: 10px; }
    #members_login #zone2 .red {
      width: 640px;
      margin: auto;
      padding-bottom: 30px;
      font-family: "HiraKakuProN-W6", "ヒラギノ角ゴ ProN W6", "FP-ヒラギノ角ゴ ProN W6", sans-serif; }
    #members_login #zone2 .btn {
      padding-top: 20px; }
  #members_menu #zone2 {
    max-width: 1000px;
    margin: auto;
    padding-bottom: 100px; }
    #members_menu #zone2 .menu1 a {
      display: flex;
      flex-wrap: wrap;
      flex-direction: row-reverse;
      justify-content: space-between;
      box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.2); }
      #members_menu #zone2 .menu1 a figure {
        width: 620px;
        overflow: hidden; }
        #members_menu #zone2 .menu1 a figure img {
          transition: 0.5s; }
      #members_menu #zone2 .menu1 a .in {
        width: calc(100% - 620px);
        padding: 40px;
        position: relative;
        background: url("../img/arrow6.png") no-repeat right bottom;
        background-size: 50px auto; }
      #members_menu #zone2 .menu1 a:hover figure img {
        transform: scale(1.1); }
  #members_menu #zone3 {
    max-width: 1000px;
    margin: auto;
    padding-bottom: 100px; }
    #members_menu #zone3 .in {
      background: #4a535b;
      color: #fff;
      padding: 50px;
      box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.2); }
      #members_menu #zone3 .in dl {
        border-bottom: 1px dotted #fff;
        padding: 10px 0;
        line-height: 1.6;
        display: flex;
        flex-wrap: wrap;
        flex-direction: row;
        justify-content: space-between; }
        #members_menu #zone3 .in dl dt {
          width: 130px;
          font-weight: 700; }
        #members_menu #zone3 .in dl dd {
          width: calc(100% - 130px); }
  #members_movie #zone2 {
    max-width: 1000px;
    margin: auto;
    padding-top: 100px; }
    #members_movie #zone2 .set {
      background: #fff;
      padding: 50px;
      margin-bottom: 50px; }
      #members_movie #zone2 .set ul {
        display: flex;
        flex-wrap: wrap;
        flex-direction: row;
        justify-content: space-between; }
        #members_movie #zone2 .set ul li {
          width: calc(50% - 15px);
          padding-bottom: 20px; }
          #members_movie #zone2 .set ul li a {
            display: block;
            padding: 20px;
            border: 1px solid #d1d7db;
            color: #004498;
            font-size: 18px;
            font-weight: 700;
            background: url("../img/arrow1.png") no-repeat right 20px center;
            background-size: 50px auto; }
            #members_movie #zone2 .set ul li a:hover {
              background-color: #f2f4f6; }
  #members_movie #zone3 {
    max-width: 1000px;
    margin: auto;
    padding-top: 100px; }
    #members_movie #zone3 .mds1 {
      padding-bottom: 50px;
      padding-top: 10px; }
    #members_movie #zone3 .mds2 {
      color: #004498;
      margin-bottom: 0; }
    #members_movie #zone3 .set {
      background: #fff;
      padding: 50px;
      margin-bottom: 100px; }
      #members_movie #zone3 .set .movie {
        width: 100%;
        padding-bottom: 56.25%;
        height: 0px;
        position: relative; }
        #members_movie #zone3 .set .movie iframe {
          position: absolute;
          top: 0;
          left: 0;
          width: 100%;
          height: 100%; }
      #members_movie #zone3 .set dl {
        display: flex;
        flex-wrap: wrap;
        flex-direction: row;
        justify-content: space-between;
        padding-top: 30px; }
        #members_movie #zone3 .set dl dt {
          width: 250px; }
        #members_movie #zone3 .set dl dd {
          max-width: calc(100% - 250px);
          line-height: 2; }
    #members_movie #zone3 .col2 {
      display: flex;
      flex-wrap: wrap;
      flex-direction: row;
      justify-content: center;
      margin: 0 -20px; }
      #members_movie #zone3 .col2 li {
        width: calc(50% - 40px);
        margin: 0 20px;
        background: #fff;
        margin-bottom: 50px; }
        #members_movie #zone3 .col2 li .set {
          padding: 30px;
          margin-bottom: 0; }
          #members_movie #zone3 .col2 li .set dt {
            width: 100%; }
            #members_movie #zone3 .col2 li .set dt .mds2 {
              font-size: 18px; }
          #members_movie #zone3 .col2 li .set dd {
            max-width: 100%;
            padding-top: 15px; } }

@media print {
  body {
    -webkit-print-color-adjust: exact;
    width: 1300px;
    transform: scale(0.8);
    -moz-transform: scale(0.8);
    -webkit-transform: scale(0.8);
    transform-origin: 0 0; }
  .effect1, .effect4 {
    opacity: 1; } }
