#members_login {
	#zone2 {
		margin: 50px 20px 0;
		padding: 50px 20px;
		background: #fff;
		.t1 {
			padding-bottom: 30px;
			font-family: $f_b;
			font-size: 16px;
			input {
				border: 3px solid #eff3f6;
				width: 100%;
				height: 50px;
				margin-top: 10px;
				padding: 10px;
			}
		}
		.red {
			padding-bottom: 30px;
			font-family: $f_b;
		}
		.btn {
			padding-top: 20px;
		}
	}
}
#members_menu {
	#zone2 {
		padding: 0px 20px 50px;
		.menu1 {
			a {
				display: block;
				box-shadow: 0px 0px 10px rgba(#000, 0.2);
				.in {
					padding: 20px 20px 40px;
					position: relative;
					background: url("../img/arrow6.png") no-repeat right bottom;
					background-size: 40px auto;
				}
			}
		}
	}
	#zone3 {
		padding: 0px 20px 50px;
		.in {
			background: #4a535b;
			color: #fff;
			padding: 40px 20px;
			box-shadow: 0px 0px 10px rgba(#000, 0.2);
			dl {
				border-bottom: 1px dotted #fff;
				padding: 10px 0;
				line-height: 1.6;
				dt {
					font-weight: 700;
				}
			}
		}
	}
}
#members_movie {
	#zone2 {
		padding: 50px 20px;
		.set {
			background: #fff;
			padding: 40px 20px 20px;
			margin-bottom: 30px;
			ul {
				li {
					padding-bottom: 10px;
					a {
						display: block;
						padding: 15px 10px;
						border: 1px solid #d1d7db;
						color: #004498;
						font-weight: 700;
						background: url("../img/arrow1.png") no-repeat right 20px center;
						background-size: 50px auto;
						&:hover {
							background-color: #f2f4f6;
						}
					}
				}
			}
		}
		.btn1 {
			width: 280px;
			font-size: 16px;
		}
	}
	#zone3 {
		padding: 50px 20px 0;
		.mds1 {
			padding-bottom: 20px;
			padding-top: 10px;
		}
		.mds2 {
			color: #004498;
			margin-bottom: 0;
		}
		.set {
			background: #fff;
			padding: 20px;
			margin-bottom: 30px;
			.movie {
				width: 100%;
				padding-bottom: 56.25%;
				height:0px;
				position: relative;
				iframe{
					position: absolute;
					top: 0;
					left: 0;
					width: 100%;
					height: 100%;
				}
			}
			dl {
				padding-top: 20px;
				dd {
					line-height: 2;
					padding-top: 15px;
				}
			}
		}
		.btn1 {
			width: 280px;
			font-size: 16px;
		}
		.col2 {
			.mds2 {
				font-size: 15px;
			}
		}
	}
}