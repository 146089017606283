@charset "UTF-8";
$sp:  "screen and (max-width:760px)";
$pc:  "print,screen and (min-width:761px)";

//fonts
$f_n: "Hiragino Kaku Gothic ProN","ヒラギノ角ゴ ProN W3","FP-ヒラギノ角ゴ ProN W3", sans-serif;
$f_b: "HiraKakuProN-W6","ヒラギノ角ゴ ProN W6","FP-ヒラギノ角ゴ ProN W6", sans-serif;
$f_en: 'DIN 2014','DINNextLTPro-Medium', sans-serif;
$f_en2: 'Helvetica-Light','HelveticaLTPro-Light', sans-serif;

@import "base";
@media #{$sp} {
	@import "sp_format.scss";
	@import "sp_header.scss";
	@import "sp_footer.scss";
	@import "sp_top.scss";
	@import "sp_page.scss";
	@import "sp_members.scss";
}
@media #{$pc} {
	@import "pc_format.scss";
	@import "pc_header.scss";
	@import "pc_footer.scss";
	@import "pc_top.scss";
	@import "pc_page.scss";
	@import "pc_members.scss";
}



@media print {
	body {
		-webkit-print-color-adjust: exact;
		width: 1300px;
		transform: scale(0.8);
		-moz-transform: scale(0.8);
		-webkit-transform: scale(0.8);
		transform-origin: 0 0;
	}
	.effect1,.effect4 {
		opacity: 1;
	}
}