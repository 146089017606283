
.none, .pc { display: none;}
main {
	font-size: 15px;
	position: relative;
	overflow: hidden;
	a {
		color: #4a535b;
	}
}




#maintitle {
	position: relative;
	background: url("../img/mainbg_sp.png") no-repeat center top;
	background-size: auto 400px;
	.in {
		padding: 140px 20px 60px;
		h1,.h1 {
			font-size: 16px;
			font-family: $f_b;
			strong {
				display: block;
				font-size: 30px;
				font-family: $f_en;
				font-weight: 700;
				letter-spacing: 0.05em;
				color: #004498;
				padding-bottom: 10px;
			}
		}
	}
}
#bread {
	font-size: 12px;
	letter-spacing: 0.1em;
	padding: 20px 20px 50px;
	a {
		display: inline-block;
		white-space: nowrap;
	}
	li {
		display: inline;
		&::after {
			content: ' ｜ ';
		}
		&:last-child::after {
			display: none;
		}
	}
}

.list_a {
	padding-bottom: 30px;
	li {
		background: #fff;
		margin-bottom: 20px;
		a {
			padding: 20px;
			display: block;
			position: relative;
			.new {
				position: absolute;
				top: 0;
				right: 0;
				background: #FF0000;
				color: #fff;
				font-size: 16px;
				font-family: $f_en;
				font-weight: 700;
				padding: 8px;
				width: 75px;
				text-align: center;
				letter-spacing: 0.1em;
			}
			figure {
				overflow: hidden;
				position: relative;
				&::after {
					position: absolute;
					bottom: 0;
					right:  0;
					content: '';
					width: 40px;
					height: 40px;
					background: url("../img/arrow5.png") no-repeat center center;
					background-size: 40px auto;
				}
			}
			.t1 {
				font-size: 18px;
				font-family: $f_b;
				line-height: 1.5;
				padding: 20px 0;
			}
			.t2 {
				line-height: 1.5;
			}
		}
	}
}

.list_c {
	padding-bottom: 30px;
	li {
		padding-bottom: 30px;
		a {
			position: relative;
			display: block;
			line-height: 1.5;
			.new {
				position: absolute;
				top: 0;
				right: 0;
				background: #FF0000;
				color: #fff;
				font-size: 12px;
				font-family: $f_en;
				font-weight: 700;
				padding: 4px;
				width: 50px;
				text-align: center;
				letter-spacing: 0.1em;
				z-index: 1;
			}
			figure {
				overflow: hidden;
				position: relative;
				&::after {
					position: absolute;
					bottom: 0;
					right:  0;
					content: '';
					width: 40px;
					height: 40px;
					background: url("../img/arrow5.png") no-repeat center center;
					background-size: 40px auto;
				}
			}
			.t1 {
				font-size: 16px;
				font-family: $f_b;
				padding: 20px 0 15px;
				i {
					display: inline-block;
					padding: 5px 20px;
					background: #eff3f6;
				}
			}
			.t2 {
				font-size: 18px;
				font-family: $f_b;
				padding-bottom: 10px;
			}
			.t3 {
				color: #004498;
				font-family: $f_b;
				padding-bottom: 10px;
			}
		}
	}
}

#f_download {
	margin-top: 50px;
	background: linear-gradient(to right, #213f9b, #03baf3);
	.in {
		padding: 50px 20px;
		color: #fff;
		h2 {
			padding-bottom: 30px;
			text-align: center;
			i {
				display: block;
				font-size: 16px;
				padding-bottom: 10px;
				line-height: 1.2;
			}
			b {
				display: block;
				font-size: 22px;
				font-family: $f_b;
			}
		}
		.text {
			padding-bottom: 40px;
		}
		ul {
			li {
				box-shadow: 5px 5px 15px rgba(#000, 0.3);
				padding: 30px 0;
				margin-top: 20px;
				text-align: center;
				.t1 {
					font-family: $f_b;
					line-height: 1.5;
					padding-bottom: 20px;
				}
				figure {
					width: 220px;
					margin: auto;
					padding-bottom: 20px;
					position: relative;
					&.ex1 {
						width: 140px;
					}
					a {
						display: block;
						position: absolute;
						top: 0;
						left: 0;
						&:hover {
							opacity: 0;
						}
					}
				}
			}
			.s0 {
				background: linear-gradient(to right, #213f9b, #03baf3);
			}
			.s1 {
				background: url("../img/download/p1.jpg") no-repeat center center;
				background-size: cover;
			}
			.s2 {
				background: url("../img/download/p2.jpg") no-repeat center center;
				background-size: cover;
			}
			.s3 {
				background: url("../img/download/p3.jpg") no-repeat center center;
				background-size: cover;
			}
			.s4 {
				background: url("../img/download/p4.jpg") no-repeat center center;
				background-size: cover;
			}
		}
		.catalog {
			position: relative;
			background: url("../img/download/ca1.jpg") no-repeat center center;
			background-size: cover;
			padding-bottom: 30px;
			margin-top: 20px;
			text-align: center;
			box-shadow: 5px 5px 15px rgba(#000, 0.3);
			.t1 {
				font-family: $f_b;
				line-height: 1.5;
				padding-bottom: 20px;
			}
			figure {
				width: 140px;
				margin: auto;
				padding-bottom: 20px;
				box-shadow: 5px 5px 15px rgba(#000, 0.3);
				img {
					margin-top: -40px;
				}
			}
		}
	}
}
.jirei {
	background: #fff;
	margin: 0 20px;
	.in {
		padding: 50px 20px;
		h2 {
			padding-bottom: 30px;
			text-align: center;
			i {
				color: #004498;
				font-size: 16px;
				display: inline-block;
				position: relative;
				&::after {
					position: absolute;
					bottom: -3px;
					left: 0;
					content: '';
					width: 100%;
					height: 10px;
					background: #e1e7ec;
					z-index: -1;
				}
			}
			b {
				display: block;
				font-family: $f_b;
				font-size: 22px;
				padding-top: 20px;
			}
		}
	}
}
.susumelist2 {
	background: #fff;
	margin: 0 20px;
	.in {
		padding: 50px 20px;
		.list_c {
			padding-bottom: 0;
			.t1 {
				padding-bottom: 5px;
			}
			.t2 {
				font-size: 14px;
				font-weight: normal;
			}
		}
	}
}
.susume {
	.in {
		padding: 50px 20px;
		h2 {
			padding-bottom: 30px;
			i {
				color: #004498;
				font-size: 16px;
				display: inline-block;
				position: relative;
				&::after {
					position: absolute;
					bottom: -3px;
					left: 0;
					content: '';
					width: 100%;
					height: 10px;
					background: #e1e7ec;
					z-index: -1;
				}
			}
			b {
				display: block;
				font-family: $f_b;
				font-size: 22px;
				padding-top: 20px;
			}
		}
	}
}

#esform {
	background: linear-gradient(to right, #213f9b, #03baf3);
	.in {
		padding: 50px 20px;
		h2 {
			text-align: center;
			margin-bottom: 20px;
			color: #fff;
			i {
				display: inline-block;
				text-align: left;
				background: url("../img/top/cal.png") no-repeat left center;
				background-size: 40px auto;
				padding: 10px 0 10px 55px;
				font-size: 13px;
				strong {
					display: block;
					font-size: 22px;
					font-family: $f_b;
					padding-top: 10px;
					line-height: 1.2;
				}
			}
		}
		.t2 {
			padding-bottom: 30px;
			color: #fff;
		}
		.fuki {
			color: #fff;
			border: 1px solid #fff;
			padding: 20px;
			margin: 30px 0;
			h3 {
				font-weight: bold;
				font-size: 16px;
				margin-bottom: 10px;
				text-align: center;
				line-height: 1.5;
				strong {
					display: inline-block;
					border-top: 1px solid #fff;
					border-bottom: 1px solid #fff;
					font-size: 14px;
					padding: 5px 0 3px;
				}
				i {
					display: block;
					text-align: left;
					padding-top: 15px;

				}
			}
			.text {
				line-height: 1.8;
			}
			.btn {
				padding-top: 20px;
				text-align: center;
				img {
					max-width: 300px;
				}
			}
			.bnr {
				padding-top: 20px;
				text-align: center;
				img {
					max-width: 300px;
				}
			}
		}
		.box {
			background: #fff;
			padding: 30px;
			box-shadow: 5px 5px 15px rgba(#000, 0.3);
			.simulator {
				li {
					padding-bottom: 20px;
					i {
						display: block;
						padding-bottom: 10px;
						font-family: $f_b;
						font-size: 18px;
					}
				}
			}
			.t1 {
				line-height: 1.5;
				font-size: 12px;
				padding: 10px 0 20px;
			}
		}
	}
}