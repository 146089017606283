header {
	position: fixed;
	width: 100%;
	height: 60px;
	line-height: 1;
	z-index: 100;
	top: 0px;
	left: 10px;
	.in {
		width: calc(100% - 20px);
		height: 50px;
		margin-top: 10px;
		background: #fff;
		position: relative;
		display: flex;
		flex-wrap: wrap;
		flex-direction: row;
		justify-content: space-between;
		align-items: center;
		padding-left: 15px;
		h1 {
			width: 150px;
			display: flex;
			flex-wrap: wrap;
			align-items: center;
		}
		#menubtn {
			width: 65px;
			height: 50px;
			display: flex;
			align-items: center;
			justify-content: center;
			cursor: pointer;
			transition: 0.3s;
			-webkit-tap-highlight-color:rgba(0,0,0,0);
		}
	}
}

#menu {
	position: fixed;
	width: 100%;
	height: 100%;
	top: 0px;
	left: 0px;
	opacity: 0;
	transition-duration: 0.3s;
	line-height: 1;
	pointer-events: none;
	overflow-y: auto;
	background: #fff;
	transform: scale(1.2);
	display: flex;
	align-items: center;
	justify-content: center;
	&.on {
		opacity: 1;
		pointer-events: auto;
		z-index: 99;
		transform: scale(1.0);
	}
	.in {
		.nav {
			padding-bottom: 20px;
			> li {
				text-align: center;
				font-family: $f_b;
				font-size: 16px;
				a {
					display: inline-block;
					padding: 20px;
					position: relative;
					.new {
						position: absolute;
						top: 14px;
						left: calc(50% - 17px);
						background: #FF0000;
						color: #fff;
						font-size: 10px;
						font-family: $f_en;
						font-weight: 700;
						padding: 3px;
						text-align: center;
						letter-spacing: 0.1em;
					}
				}
			}
		}
		.btn {
			width: 200px;
			border-radius: 3px;
			overflow: hidden;
			.hbtn {
				display: flex;
				flex-wrap: wrap;
				flex-direction: row;
				align-items: center;
				justify-content: center;
				width: 200px;
				height: 50px;
				font-family: $f_b;
				background: linear-gradient(to right, #213f9b, #03baf3);
				color: #fff;
				border: 4px solid #213f9b;
				border-image: linear-gradient(to right, #213f9b 0%, #03baf3 100%);
				border-image-slice: 1;
			}
		}
	}
}



.hm_button * {
	margin: 0;
	padding: 0;
	outline: none;
	border: none;
	font: inherit;
	font-family: inherit;
	font-size: 100%;
	font-style: inherit;
	font-weight: inherit;
	-webkit-appearance: none;
	-moz-appearance: none;
	appearance: none;
	text-align: left;
	text-decoration: none;
	list-style: none;
}

.hm_button {
	display: block;
	padding: 0;
	width: 30px;
	height: 18px;
	position: relative;
	background: none;
	border: none;
	text-align: center;
	letter-spacing: 0.1em;
	cursor: pointer;
	outline: none;
}

.hm_button .hm_bar {
	display: block;
	width: 30px;
	height: 3px;
	transition: all 0.2s;
	transform-origin: 0% 0%;
	transform: translateY(-50%);
	position: absolute;
	left: 0;
}

.hm_button .hm_bar1 {
	top: 0;
}

.hm_button .hm_bar2 {
	top: 50%;
}

.hm_button .hm_bar3 {
	top: 100%;
}

.hm_button.active .hm_bar {
	width: 25.46px;
	left: 6px
}

.hm_button.active .hm_bar1 {
	transform: rotate(0.7853981633974483rad) translateY(-50%);
	top: 0px
}

.hm_button.active .hm_bar2 {
	opacity: 0;
}

.hm_button.active .hm_bar3 {
	transform: rotate(-0.7853981633974483rad) translateY(-50%);
	top: calc(100% - 0px)
}

.hm_button.active .hm_menu_text {
	display: none;
}

.hm_button.active .hm_close {
	display: block;
}

.hm_button .hm_bar {
	background-color: #4f5254;
}