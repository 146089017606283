
.none, .sp { display: none;}
a[href^="tel:"] { 
	cursor: default;
	color: #4a535b;
	text-decoration: none;
	&:hover {
		opacity:1;
	}
}
a {
	transition: 0.3s;
	color: #4a535b;
	&:hover {
		opacity: 0.7;
	}
}
main {
	position: relative;
}




#maintitle {
	position: relative;
	height: 460px;
	background: url("../img/top/mainbg.png") no-repeat center top;
	background-size: 1600px auto;
	overflow: hidden;
	.in {
		max-width: 1000px;
		margin: auto;
		padding-top: 270px;
		h1,.h1 {
			font-size: 24px;
			font-family: $f_b;
			strong {
				display: block;
				font-size: 42px;
				font-family: $f_en;
				font-weight: 700;
				letter-spacing: 0.1em;
				color: #004498;
				padding-bottom: 10px;
			}
		}
	}
}
#bread {
	max-width: 1000px;
	margin: auto;
	font-size: 14px;
	letter-spacing: 0.1em;
	padding: 30px 0 60px;
	a {
		display: inline-block;
		white-space: nowrap;
	}
	li {
		display: inline;
		&::after {
			content: ' ｜ ';
		}
		&:last-child::after {
			display: none;
		}
	}
}

.list_a {
	display: flex;
	flex-wrap: wrap;
	flex-direction: row;
	justify-content: center;
	margin: 0 -15px;
	li {
		width: calc(50% - 30px);
		background: #fff;
		margin: 0 15px 70px;
		a {
			display: block;
			padding: 25px;
			position: relative;
			.new {
				position: absolute;
				top: 0;
				right: 0;
				background: #FF0000;
				color: #fff;
				font-size: 18px;
				font-family: $f_en;
				font-weight: 700;
				padding: 8px;
				width: 85px;
				text-align: center;
				letter-spacing: 0.1em;
			}
			figure {
				overflow: hidden;
				position: relative;
				&::after {
					position: absolute;
					bottom: 0;
					right:  0;
					content: '';
					width: 50px;
					height: 50px;
					background: url("../img/arrow5.png") no-repeat center center;
					background-size: 50px auto;
				}
				img {
					transition: 0.5s;
				}
			}
			&:hover {
				figure {
					img {
						transform: scale(1.1);
					}
				}
			}
			.t1 {
				font-size: 24px;
				font-family: $f_b;
				line-height: 1.5;
				padding: 20px 0;
			}
			.t2 {
				line-height: 1.5;
			}
		}
	}
}

.list_c {
	display: flex;
	flex-wrap: wrap;
	flex-direction: row;
	padding-bottom: 50px;
	margin: 0 -15px;
	li {
		width: 33.333%;
		padding: 0 15px 50px;
		a {
			position: relative;
			display: block;
			line-height: 1.5;
			.new {
				position: absolute;
				top: 0;
				right: 0;
				background: #FF0000;
				color: #fff;
				font-size: 14px;
				font-family: $f_en;
				font-weight: 700;
				padding: 4px;
				width: 50px;
				text-align: center;
				letter-spacing: 0.1em;
				z-index: 1;
			}
			figure {
				overflow: hidden;
				position: relative;
				&::after {
					position: absolute;
					bottom: 0;
					right:  0;
					content: '';
					width: 40px;
					height: 40px;
					background: url("../img/arrow5.png") no-repeat center center;
					background-size: 40px auto;
				}
				img {
					transition: 0.5s;
				}
			}
			&:hover {
				figure {
					img {
						transform: scale(1.1);
					}
				}
			}
			.t1 {
				font-size: 18px;
				font-family: $f_b;
				padding: 20px 0 15px;
				i {
					display: inline-block;
					padding: 5px 20px;
					background: #eff3f6;
				}
			}
			.t4 {
				font-size: 18px;
				font-family: $f_b;
				padding-bottom: 10px;
			}
		}
	}
}

#f_download {
	margin-top: 100px;
	&::after {
		position: absolute;
		top: 0;
		left: 0;
		content: '';
		width: calc(50% + 600px);
		min-width: 1200px;
		height: 100%;
		background: linear-gradient(to right, #213f9b, #03baf3);
		z-index: -1;
	}
	.in {
		max-width: 1000px;
		margin: auto;
		padding: 100px 0;
		text-align: center;
		color: #fff;
		h2 {
			padding-bottom: 30px;
			i {
				display: block;
				font-size: 20px;
				padding-bottom: 20px;
			}
			b {
				display: block;
				font-size: 36px;
				font-family: $f_b;
			}
		}
		.text {
			padding-bottom: 40px;
		}
		ul {
			display: flex;
			flex-wrap: wrap;
			flex-direction: row;
			justify-content: center;
			li {
				width: calc(50% - 40px);
				box-shadow: 5px 5px 15px rgba(#000, 0.3);
				padding: 30px 0;
				margin: 20px;
				.t1 {
					font-size: 20px;
					font-family: $f_b;
					line-height: 1.5;
					padding-bottom: 20px;
				}
				figure {
					width: 220px;
					margin: auto;
					padding-bottom: 20px;
					position: relative;
					&.ex1 {
						width: 140px;
					}
					a {
						display: block;
						position: absolute;
						top: 0;
						left: 0;
						&:hover {
							opacity: 0;
						}
					}
				}
			}
			.s0 {
				background: linear-gradient(to right, #213f9b, #03baf3);
			}
			.s1 {
				background: url("../img/download/p1.jpg") no-repeat center center;
				background-size: cover;
			}
			.s2 {
				background: url("../img/download/p2.jpg") no-repeat center center;
				background-size: cover;
			}
			.s3 {
				background: url("../img/download/p3.jpg") no-repeat center center;
				background-size: cover;
			}
			.s4 {
				background: url("../img/download/p4.jpg") no-repeat center center;
				background-size: cover;
			}
		}
		.catalog {
			position: relative;
			background: url("../img/download/ca1.jpg") no-repeat center center;
			background-size: cover;
			padding: 30px 0;
			margin: 20px;
			box-shadow: 5px 5px 15px rgba(#000, 0.3);
			.t1 {
				font-size: 20px;
				font-family: $f_b;
				line-height: 1.5;
				padding-bottom: 20px;
			}
			figure {
				position: absolute;
				bottom: 0;
				right: 40px;
				width: 140px;
				box-shadow: 5px 5px 15px rgba(#000, 0.3);
			}
		}
	}
}
.jirei {
	max-width: 1100px;
	margin: auto;
	background: #fff;
	.in {
		max-width: 1000px;
		margin: auto;
		padding-bottom: 120px;
		h2 {
			padding-bottom: 60px;
			text-align: center;
			i {
				color: #004498;
				font-size: 20px;
				display: inline-block;
				position: relative;
				&::after {
					position: absolute;
					bottom: -3px;
					left: 0;
					content: '';
					width: 100%;
					height: 10px;
					background: #e1e7ec;
					z-index: -1;
				}
			}
			b {
				display: block;
				font-family: $f_b;
				font-size: 36px;
				padding-top: 20px;
			}
		}
	}
}
.susumelist2 {
	max-width: 1100px;
	margin: auto;
	background: #fff;
	.in {
		max-width: 1000px;
		margin: auto;
		padding-bottom: 120px;
		.list_c {
			padding-bottom: 0;
			.t1 {
				padding-bottom: 5px;
			}
		}
	}
}
.susume {
	.in {
		max-width: 1000px;
		margin: auto;
		padding-top: 120px;
		h2 {
			padding-bottom: 60px;
			i {
				color: #004498;
				font-size: 20px;
				display: inline-block;
				position: relative;
				&::after {
					position: absolute;
					bottom: -3px;
					left: 0;
					content: '';
					width: 100%;
					height: 10px;
					background: #e1e7ec;
					z-index: -1;
				}
			}
			b {
				display: block;
				font-family: $f_b;
				font-size: 36px;
				padding-top: 20px;
			}
		}
	}
}

#esform {
	&::after {
		position: absolute;
		top: 0;
		left: 0;
		content: '';
		width: calc(50% + 600px);
		min-width: 1200px;
		height: 100%;
		background: linear-gradient(to right, #213f9b, #03baf3);
		z-index: -1;
	}
	.in {
		max-width: 1000px;
		margin: auto;
		padding: 100px 0;
		h2 {
			text-align: center;
			margin-bottom: 30px;
			color: #fff;
			i {
				display: inline-block;
				text-align: left;
				background: url("../img/top/cal.png") no-repeat left top;
				background-size: 90px auto;
				height: 120px;
				padding-left: 110px;
				padding-top: 20px;
				strong {
					display: block;
					font-size: 36px;
					font-family: $f_b;
					padding-top: 20px;
				}
			}
		}
		figure {
			padding-bottom: 50px;
		}
		.t2 {
			max-width: 800px;
			margin: auto;
			color: #fff;
		}
		.fuki {
			color: #fff;
			border: 1px solid #fff;
			padding: 30px 50px;
			margin: 30px 0;
			h3 {
				display: flex;
				flex-wrap: wrap;
				flex-direction: row;
				align-items: center;
				font-weight: bold;
				font-size: 18px;
				margin-bottom: 20px;
				strong {
					display: inline-block;
					border-top: 1px solid #fff;
					border-bottom: 1px solid #fff;
					font-size: 16px;
					padding: 8px 0;
					margin-right: 20px;
				}
			}
			.btnset {
				display: flex;
				flex-wrap: wrap;
				flex-direction: row;
				justify-content: space-between;
				align-items: center;
				padding-top: 10px;
				.btn {
					img {
						width: auto;
						height: 50px;
					}
				}
				.bnr {
					width: 320px;
				}
			}
		}
		.box {
			background: #fff;
			padding: 50px;
			box-shadow: 5px 5px 15px rgba(#000, 0.3);
			.simulator {
				display: flex;
				flex-wrap: wrap;
				flex-direction: row;
				justify-content: space-between;
				li {
					width: 18%;
					i {
						display: block;
						padding-bottom: 10px;
						font-family: $f_b;
						font-size: 18px;
					}
					&:nth-of-type(2) {
						width: 25%;
					}
				}
			}
			.t1 {
				font-size: 14px;
				line-height: 1.5;
				padding: 30px 0;
			}
		}
	}
}