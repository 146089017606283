html {
    font-family: $f_n;
    font-size: 16px;
    font-weight: 500;
    font-style: normal;
    color: #4a535b;
    line-height: 1;
    -webkit-text-size-adjust: 100%;
    vertical-align: bottom;
    box-sizing:border-box;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    @media #{$sp} {
        font-size: 14px;
    }
}
body,
body *,
body *:before,
body *:after{
    margin: 0;
    padding: 0;
    list-style: none;
    font-family: inherit;
    font-size: inherit;
    letter-spacing: inherit;
    box-sizing:inherit;
    vertical-align:inherit;
    font-weight: inherit;
    font-style: inherit;
    color: inherit;
    font-feature-settings: inherit;
    outline: none;
}
html,
body {
    height: 100%;
    background: #eff3f6;
}
a,
a img,
button{
    outline: none;
    border: none;
    cursor: pointer;
}
button{
    background-color: transparent;
    appearance: none;
    margin: 0;
    padding: 0;
    background: none;
    border: none;
    border-radius: 0;
    outline: none;
}
input,
textarea{
    padding: 0;
    border: none;
    border-radius: 0;
    outline: none;
    background: none;
}
input[type="text"]:focus,
textarea:focus{
    outline: 0;
}
sup{
    vertical-align: super;
    font-size: smaller;
}
sub{
    vertical-align: baseline;
    font-size: 0.7em;
}
img,
video,
svg{
    vertical-align: bottom;
    height: auto;
    width: 100%;
}
a,
button{
    text-decoration: none;
    -webkit-tap-highlight-color:rgba(0,0,0,0);
}
a:hover,
button:hover{
    outline: none;
}
a:disabled,
button:disabled,
input:disabled{pointer-events:none;}
table {
    width: 100%;
    border-collapse: collapse;
}
main {
    text-align: justify;
    text-justify: inter-ideograph;
    display: block;
    position: relative;
    overflow: hidden;
}
section {
    position: relative;
}
small {
    font-size: 12px;
    @media #{$sp} {
        font-size: 11px;
    }
}
strong {
    font-weight: bold;
}



@mixin clearfix {
    &::after {
        content: ".";
        display: block; 
        clear: both;
        height: 0;
        visibility: hidden;
    }
}
.red {
    color: #d2001e;
}
.bg_w {
    background: #fff;
}
.text,.text2 {
    line-height: 2;
}
.mds1 {
    padding-bottom: 80px;
    text-align: center;
    font-size: 28px;
    font-family: $f_b;
    b {
        display: inline;
        position: relative;
        z-index: 1;
        margin-bottom: 20px;
        letter-spacing: 0.1em;
        background: linear-gradient(transparent 70%, #e1e7ec 0%);
        line-height: 1.6;
    }
    i {
        display: block;
        font-family: $f_en;
        font-weight: 700;
        color: #004498;
        font-size: 16px;
        letter-spacing: 0.1em;
        padding-top: 10px;
    }
    span {
        display: block;
        font-size: 16px;
        padding-top: 15px;
    }
    @media #{$sp} {
        padding-bottom: 50px;
        font-size: 20px;
        i {
            font-size: 16px;
        }
        span {
            font-size: 14px;
            line-height: 1.5;
        }
    }
    &.ex {
        font-size: 30px;
        padding-bottom: 50px;
        color: #004498;
        b {
            background: linear-gradient(transparent 70%, #CFDDEA 0%);
        }
        @media #{$sp} {
            padding-bottom: 30px;
            font-size: 18px;
        }
    }
}
.mds2 {
    text-align: center;
    font-family: $f_b;
    font-size: 20px;
    padding-bottom: 20px;
    margin-bottom: 30px;
    position: relative;
    &::after {
        position: absolute;
        bottom: 0;
        left: calc(50% - 30px);
        content: '';
        width: 60px;
        height: 3px;
        background: linear-gradient(to right, #213f9b, #03baf3);
    }
    &.ex {
        text-align: left;
        line-height: 1.4;
        &::after {
            left: 0;
        }
    }
    &.ex2 {
        color: #fff;
        font-weight: 700;
        letter-spacing: 0.1em;
        font-family: $f_en;
        font-size: 24px;
        &::after {
            background: #fff;
        }
    }
    &.ex3 {
        font-weight: 700;
        letter-spacing: 0.1em;
        font-family: $f_en;
        font-size: 24px;
    }
    @media #{$sp} {
        font-size: 18px;
    }
}
.btn1 {
    color: #fff;
    font-family: $f_b;
    font-size: 18px;
    letter-spacing: 0.1em;
    position: relative;
    width: 300px;
    height: 70px;
    border-radius: 35px;
    background: linear-gradient(to right, #213f9b, #03baf3);
    z-index: 1;
    display: flex;
    align-items: center;
    justify-content: center;
    margin: auto;
    i {
        position: relative;
        z-index: 2;
    }
    &::before {
        position: absolute;
        top: calc(50% - 5px);
        right: -25px;
        content: '';
        width: 50px;
        height: 7px;
        background: url("../img/arrow1.png") no-repeat center center;
        background-size: 50px auto;
        z-index: 2;
    }
    &::after {
        position: absolute;
        top: 4px;
        left: 4px;
        content: '';
        width: calc(100% - 8px);
        height: calc(100% - 8px);
        background: #fff;
        border-radius: 35px;
        opacity: 0;
        z-index: 1;
        transition: 0.3s;
    }
    @media #{$pc} {
        &:hover {
            opacity: 1;
            color: #004498;
            &::after {
                opacity: 1;
            }
        }
    }
    @media #{$sp} {
        width: 280px;
        height: 50px;
    }
    &.ex {
        font-size: 16px;
        width: 200px;
        height: 50px;
        box-shadow: 0px 0px 15px rgba(#fff, 0.7);
    }
    &.ex2 {
        font-size: 16px;
        width: 200px;
        height: 50px;
        background: #4A535B;
        &::before {
            display: none;
        }
        @media #{$pc} {
            &:hover {
                color: #4A535B;
            }
        }
    }
    &.ex3 {
        width: 400px;
        @media #{$sp} {
            width: 280px;
            letter-spacing: 0;
        }
    }
}
.btn2 {
    color: #fff;
    font-family: $f_b;
    font-size: 16px;
    letter-spacing: 0.1em;
    position: relative;
    width: 200px;
    height: 50px;
    border-radius: 35px;
    border: 2px solid #fff;
    z-index: 1;
    display: flex;
    align-items: center;
    justify-content: center;
    margin: auto;
    i {
        position: relative;
        z-index: 2;
    }
    &::before {
        position: absolute;
        top: calc(50% - 5px);
        right: -25px;
        content: '';
        width: 50px;
        height: 7px;
        background: url("../img/arrow2.png") no-repeat center center;
        background-size: 50px auto;
        z-index: 2;
    }
    @media #{$pc} {
        &:hover {
            opacity: 1;
            color: #004498;
            background: #fff;
        }
    }
    @media #{$sp} {
        height: 40px;
        font-size: 14px;
    }
}
.outlink {
    position: relative;
    display: inline-block;
    color: #004498;
    font-family: $f_b;
    padding-right: 25px;
    &::after {
        position: absolute;
        top: calc(50% - 7px);
        right: 0;
        content: '';
        width: 15px;
        height: 15px;
        background: url("../img/outlink.png") no-repeat center center;
        background-size: 15px;
        z-index: 1;
    }
}
.gbd1 {
    display: inline-block;
    position: relative;
    color: #004498;
    font-size: 18px;
    font-family: $f_b;
    letter-spacing: 0.1em;
    padding-bottom: 13px;
    margin-bottom: 20px;
    min-width: 80px;
    text-align: center;
    &::after {
        position: absolute;
        bottom: 0;
        left: 0;
        content: '';
        width: 100%;
        height: 3px;
        background: linear-gradient(to right, #213f9b, #03baf3);
    }
    @media #{$sp} {
        font-size: 16px;
        padding-bottom: 10px;
    }
}
.marulist {
    margin-bottom: 50px;
    line-height: 1.5;
    font-size: 20px;
    @media #{$sp} {
        font-size: 16px;
    }
    li {
        margin-bottom: 0.7em;
        padding-left: 1.5em;
        text-indent: -1.5em;
        i {
            margin-right: 0.5em;
        }
    }
}




@media #{$pc} {
    .effect1 {
        opacity: 0;
        &.on {
            animation: ef1 1.0s forwards;
        }
    }
    .effect3 {
        opacity: 0;
        &.on {
            animation: ef3 0.5s forwards 0.3s;
        }
    }
    .effect4 {
        .line,.set {
            opacity: 0;
        }
        &.on { 
            .line {
                animation: mask 0.5s forwards 0.2s;
            }
            .set {
                animation: ef1 1.0s forwards 0.7s;
            }
        }
    }
}
@keyframes ef1 {
    0% {
        opacity: 0;
        transform: translate(0px,50px);
    }
    100% {
        opacity: 1;
        transform: translate(0px,0px);
    }
}
@keyframes ef2 {
    0% {
        opacity: 0;
        transform: scale(0.9);
    }
    100% {
        opacity: 1;
        transform: scale(1.0);
    }
}
@keyframes ef3 {
    0% {
        opacity: 0;
    }
    100% {
        opacity: 1;
    }
}
@keyframes mask {
    0% {
        opacity: 0;
        clip-path: inset(0 100% 0 0);
    }
    100% {
        opacity: 1;
        clip-path: inset(0);
    }
}
@keyframes circle {
    0% {
        opacity: 0;
        transform: scale(0.2);
    }
    60% {
        opacity: 1;
        transform: scale(1.0);
    }
    100% {
        opacity: 0;
        transform: scale(1.1);
    }
}
@keyframes pop {
    0% {
        opacity: 0;
        transform: scale(0.2);
    }
    80% {
        opacity: 1;
        transform: scale(1.0);
    }
    100% {
        opacity: 1;
        transform: scale(1.0);
    }
}




#loading {
    width: 100vw;
    height: 100vh;
    transition: all 1s;
    background-color: #fff;
    position: fixed;
    top: 0;
    left: 0;
    z-index: 9999;
}
.loaded {
    opacity: 0;
    visibility: hidden;
}


select {
    display: inline-block;
    width: 100%;
    cursor: pointer;
    padding: 10px 30px 10px 15px;
    outline: 0;
    border: 0;
    border-radius: 0;
    color: #4a535b;
    appearance: none;
    background: url("../img/arrow3.png") no-repeat right 5px center #eff3f6;
    background-size: 20px;
    font-family: sans-serif;
    &::-ms-expand {
        display: none;
    }
}
.check {
    input[type="checkbox"]{
        display: none;
    }
    input[type="checkbox"]+label{
        display: none;
        cursor: pointer;
        display: inline-block;
        position: relative;
        padding-left: 30px;
        padding-right: 10px;
    }
    input[type="checkbox"]+label::before{
        content: "";
        position: absolute;
        display: block;
        box-sizing: border-box;
        width: 20px;
        height: 20px;
        margin-top: -10px;
        left: 0;
        top: 50%;
        border: 1px solid;
        border-radius: 50%;
        border-color:  #4a535b;
        background-color: #FFF;
    }
    input[type="checkbox"]:checked+label::after{
        content: "";
        position: absolute;
        display: block;
        box-sizing: border-box;
        width: 18px;
        height: 9px;
        margin-top: -9px;
        top: 50%;
        left: 3px;
        transform: rotate(-45deg);
        border-bottom: 3px solid;
        border-left: 3px solid;
        border-color:  #004498;
    }
    input[type="checkbox"]:checked+label::before{
        background-color: #eff3f6;
    }
}
#button:disabled {
    opacity: 0.2;
}