#members_login {
	#zone2 {
		max-width: 1000px;
		margin: auto;
		margin-top: 50px;
		padding: 100px 0;
		background: #fff;
		.t1 {
			width: 640px;
			margin: auto;
			padding-bottom: 30px;
			font-family: $f_b;
			font-size: 18px;
			input {
				border: 3px solid #eff3f6;
				width: 100%;
				height: 50px;
				margin-top: 10px;
				padding: 10px;
			}
		}
		.red {
			width: 640px;
			margin: auto;
			padding-bottom: 30px;
			font-family: $f_b;
		}
		.btn {
			padding-top: 20px;
		}
	}
}
#members_menu {
	#zone2 {
		max-width: 1000px;
		margin: auto;
		padding-bottom: 100px;
		.menu1 {
			a {
				display: flex;
				flex-wrap: wrap;
				flex-direction: row-reverse;
				justify-content: space-between;
				box-shadow: 0px 0px 10px rgba(#000, 0.2);
				figure {
					width: 620px;
					overflow: hidden;
					img {
						transition: 0.5s;
					}
				}
				.in {
					width: calc(100% - 620px);
					padding: 40px;
					position: relative;
					background: url("../img/arrow6.png") no-repeat right bottom;
					background-size: 50px auto;
				}
				&:hover {
					figure {
						img {
							transform: scale(1.1);
						}
					}
				}
			}
		}
	}
	#zone3 {
		max-width: 1000px;
		margin: auto;
		padding-bottom: 100px;
		.in {
			background: #4a535b;
			color: #fff;
			padding: 50px;
			box-shadow: 0px 0px 10px rgba(#000, 0.2);
			dl {
				border-bottom: 1px dotted #fff;
				padding: 10px 0;
				line-height: 1.6;
				display: flex;
				flex-wrap: wrap;
				flex-direction: row;
				justify-content: space-between;
				dt {
					width: 130px;
					font-weight: 700;
				}
				dd {
					width: calc(100% - 130px);
				}
			}
		}
	}
}
#members_movie {
	#zone2 {
		max-width: 1000px;
		margin: auto;
		padding-top: 100px;
		.set {
			background: #fff;
			padding: 50px;
			margin-bottom: 50px;
			ul {
				display: flex;
				flex-wrap: wrap;
				flex-direction: row;
				justify-content: space-between;
				li {
					width: calc(50% - 15px);
					padding-bottom: 20px;
					a {
						display: block;
						padding: 20px;
						border: 1px solid #d1d7db;
						color: #004498;
						font-size: 18px;
						font-weight: 700;
						background: url("../img/arrow1.png") no-repeat right 20px center;
						background-size: 50px auto;
						&:hover {
							background-color: #f2f4f6;
						}
					}
				}
			}
		}
	}
	#zone3 {
		max-width: 1000px;
		margin: auto;
		padding-top: 100px;
		.mds1 {
			padding-bottom: 50px;
			padding-top: 10px;
		}
		.mds2 {
			color: #004498;
			margin-bottom: 0;
		}
		.set {
			background: #fff;
			padding: 50px;
			margin-bottom: 100px;
			.movie {
				width: 100%;
				padding-bottom: 56.25%;
				height:0px;
				position: relative;
				iframe{
					position: absolute;
					top: 0;
					left: 0;
					width: 100%;
					height: 100%;
				}
			}
			dl {
				display: flex;
				flex-wrap: wrap;
				flex-direction: row;
				justify-content: space-between;
				padding-top: 30px;
				dt {
					width: 250px;
				}
				dd {
					max-width: calc(100% - 250px);
					line-height: 2;
				}
			}
		}
		.col2 {
			display: flex;
			flex-wrap: wrap;
			flex-direction: row;
			justify-content: center;
			margin: 0 -20px;
			li {
				width: calc(50% - 40px);
				margin: 0 20px;
				background: #fff;
				margin-bottom: 50px;
				.set {
					padding: 30px;
					margin-bottom: 0;
					dt {
						width: 100%;
						.mds2 {
							font-size: 18px;
						}
					}
					dd {
						max-width: 100%;
						padding-top: 15px;
					}
				}
			}
		}
	}
}