#toppage {
	main {
		z-index: 1;
	}
	#zone1 {
		background: #fff;
		.in {
			.set1 {
				background: url("../img/top/mainbg_sp.jpg") no-repeat center top;
				background-size: contain;
				height: 100vh;
				position: relative;
				h1 {
					width: 50%;
					position: absolute;
					top: 47%;
					left: 20px;
				}
				.link {
					position: absolute;
					bottom: 60px;
					left: calc(50% - 150px);
					.btn1 {
						width: 300px;
						font-size: 15px;
						letter-spacing: 0;
					}
				}
				.sc {
					position: absolute;
					bottom: 5px;
					left: calc(50% - 20px);
					img {
						width: 40px;
					}
				}
			}
			.set2 {
				padding: 20px 20px 50px;
				.copy {
					width: 250px;
					padding-bottom: 20px;
				}
				.text {
					i {
						display: block;
						font-family: 28px;
						color: #004498;
						font-family: $f_b;
						font-size: 22px;
						padding: 10px 0;
					}
				}
			}
		}
	}
	#zone_news {
		background: #fff;
		padding: 0px 20px 30px;
		h2 {
			text-align: center;
			font-weight: bold;
			font-size: 22px;
			padding-bottom: 20px;
		}
		.in {
			max-width: 600px;
			margin: auto;
			font-weight: bold;
			line-height: 1.5;
			dl {
				padding-bottom: 20px;
				dt {
					color: #004498;
				}
				dd {
					a {
						text-decoration: underline;
					}
				}
			}
		}
	}
	#zone3 {
	}
	#zone4,#zone5 {
		background: #fff;
		margin-top: 50px;
	}
}
#modal {
	position: fixed;
	top: 0;
	left: 0;
	width: 100%;
	height: 100vh;
	background: #fff;
	display: flex;
	align-items: center;
	justify-content: center;
	opacity: 0;
	transition-duration: 0.3s;
	z-index: 0;
	pointer-events: none;
	transform: scale(1.2);
	&.on {
		opacity: 1;
		pointer-events: auto;
		z-index: 99999;
		transform: scale(1.0);
	}
	.modalbg {
		display: none;
	}
	.in {
		background: #fff;
		padding: 20px;
		position: relative;
		figure {
			width: 100px;
			margin: auto;
		}
		.mtitle {
			font-size: 16px;
			font-family: $f_b;
			padding: 10px 0 5px;
			text-align: center;
			line-height: 1.5;
		}
		h3 {
			font-family: $f_b;
			padding-bottom: 5px;
			text-align: center;
			line-height: 1.5;
			color: #d2001e;
		}
		h4 {
			font-family: $f_b;
			padding: 10px 0 5px;
		}
		.text {
			font-size: 13px;
			line-height: 1.5;
		}
		.check {
			text-align: center;
			padding: 5px 0 15px;
			font-size: 16px;
			font-family: $f_b;
			i {
				display: inline-block;
				padding: 10px 20px;
				border: 1px solid #4a535b;
			}
		}
		#cbtn {
			position: absolute;
			top: 15px;
			right: 10px;
			background: url("../img/close.png") no-repeat left top;
			background-size: 50px;
			width: 50px;
			height: 50px;
			cursor: pointer;
		}
		.termsbox {
			overflow-y: scroll;
			width: 100%;
			height: 100px;
			padding: 10px 20px 10px 10px;
			border: 1px solid #e0e0e0;
			margin: 10px 0;
			background: #fcfcfc;
			h2 {
				font-size: 12px;
				font-family: $f_b;
				padding-bottom: 10px;
			}
			.text {
				font-size: 12px;
				padding-bottom: 20px;
			}
		}
	}
}