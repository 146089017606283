footer {
	position: relative;
	margin-top: 50px;
	.bg {
		background: #fff;
		padding: 30px 20px 30px;
		position: relative;
		.in {
			.sec1 {
				.logo {
					padding-bottom: 20px;
					img {
						width: 240px;
					}
					i {
						display: block;
						font-size: 12px;
						color: #004498;
						padding-top: 10px;
						line-height: 1.5;
					}
				}
				.outlink {
					margin-top: 15px;
				}
				.nav1 {
					font-family: $f_b;
					li {
						padding-bottom: 15px;
					}
				}
			}
			.sec2 {
				position: absolute;
				bottom: 30px;
				right: 20px;
				width: 150px;
				.nav2 {
					font-family: $f_b;
					text-align: center;
					li {
						padding-bottom: 10px;
						a {
							display: flex;
							justify-content: center;
							align-items: center;
							width: 150px;
							height: 40px;
						}
						.fbtn1 {
							background: #eff3f6;
							i {
								position: relative;
								padding-left: 25px;
								&::after {
									position: absolute;
									top: calc(50% - 10px);
									left: 0;
									content: '';
									width: 20px;
									height: 20px;
									background: url("../img/mail.png") no-repeat center center;
									background-size: 20px;
									z-index: 1;
								}
							}
						}
						.fbtn2 {
							background: #4a535b;
							color: #fff;
							i {
								position: relative;
								padding-left: 25px;
								&::after {
									position: absolute;
									top: calc(50% - 10px);
									left: 0;
									content: '';
									width: 20px;
									height: 20px;
									background: url("../img/dl.png") no-repeat center center;
									background-size: 20px;
									z-index: 1;
								}
							}
						}
						.fbtn3 {
							background: linear-gradient(to right, #213f9b, #03baf3);
							color: #fff;
						}
					}
				}
			}
		}
	}
	.sec3 {
		text-align: center;
		padding: 20px 0 15px;
		.in {
			font-size: 12px;
			ul {
				display: flex;
				justify-content: center;
				li {
					&::after {
						content: '　｜　';
					}
					&:last-child {
						&::after {
							display: none;
						}
					}
				}
			}
			.copyright {
				font-family: $f_en2;
				line-height: 1.5;
				padding-top: 15px;
			}
		}
	}
}

#pagetop { 
	position: fixed;
	right : 10px;
	bottom: 10px;
	z-index: 1;
	cursor: pointer;
	img {
		width: 40px;
	}
}