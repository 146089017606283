#toppage {
	main {
		z-index: 1;
		&::before {
			position: absolute;
			top: -800px;
			left: calc(50% - 950px);
			content: '';
			width: 0;
			height: 0;
			border-style: solid;
			border-width: 0 0 2000px 4000px;
			border-color: transparent transparent #fff transparent;
			z-index: -1;
		}
		&::after {
			position: absolute;
			top: 1200px;
			left: calc(50% - 950px);
			content: '';
			width: 0;
			height: 0;
			border-style: solid;
			border-width: 0 3000px 2000px 0;
			border-color: transparent #fff transparent transparent;
			z-index: -1;
		}
	}
	#zone1 {
		height: 1200px;
		background: url("../img/top/mainbg.png") no-repeat center top;
		background-size: 1800px auto;
		.in {
			max-width: 1000px;
			margin: auto;
			padding-top: 310px;
			h1 {
				width: 360px;
				padding-bottom: 70px;
			}
			.link {
				padding-bottom: 100px;
				.btn1 {
					width: 400px;
					margin: 0;
				}
			}
			.copy {
				width: 620px;
				padding-bottom: 30px;
			}
			.text {
				i {
					display: block;
					font-family: 28px;
					color: #004498;
					font-family: $f_b;
					font-size: 28px;
					padding: 30px 0;
				}
			}
		}
	}
	#zone_news {
		padding-bottom: 70px;
		h2 {
			text-align: center;
			font-weight: bold;
			font-size: 32px;
			padding-bottom: 30px;
		}
		.in {
			max-width: 800px;
			margin: auto;
			font-weight: bold;
			line-height: 1.5;
			dl {
				display: flex;
				flex-wrap: wrap;
				flex-direction: row;
				justify-content: space-between;
				padding-bottom: 10px;
				dt {
					width: 120px;
					color: #004498;
				}
				dd {
					width: calc(100% - 120px);
					a {
						text-decoration: underline;
					}
				}
			}
		}
	}
	#zone3 {
		padding-bottom: 120px;
	}
	#zone4,#zone5 {
		padding-top: 120px;
		&::after {
			position: absolute;
			top: 0;
			right: 0;
			content: '';
			width: calc(50% + 600px);
			min-width: 1200px;
			height: 100%;
			background: #fff;
			z-index: -10;
		}
	}
	#zone5 {
		margin-top: 100px;
	}
}
#modal {
	position: fixed;
	top: 0;
	left: 0;
	width: 100%;
	height: 100vh;
	background: rgba(#fff, 0.8);
	display: flex;
	align-items: center;
	justify-content: center;
	opacity: 0;
	transition-duration: 0.3s;
	z-index: 0;
	pointer-events: none;
	transform: scale(1.2);
	&.on {
		opacity: 1;
		pointer-events: auto;
		z-index: 99999;
		transform: scale(1.0);
	}
	.modalbg {
		height: 100vh;
		width: 100%;
		position: absolute;
	}
	.in {
		background: #fff;
		width: 700px;
		padding: 30px 50px;
		position: relative;
		box-shadow: 5px 5px 15px rgba(#000, 0.3);
		figure {
			width: 100px;
			margin: auto;
		}
		.mtitle {
			font-size: 24px;
			font-family: $f_b;
			padding: 15px 0;
			text-align: center;
			line-height: 1.5;
		}
		h3 {
			font-size: 18px;
			font-family: $f_b;
			padding-bottom: 15px;
			text-align: center;
			line-height: 1.5;
			color: #d2001e;
		}
		h4 {
			font-family: $f_b;
			padding: 20px 0 5px;
		}
		.check {
			text-align: center;
			padding: 20px;
			font-size: 18px;
			font-family: $f_b;
			i {
				display: inline-block;
				padding: 15px 25px;
				border: 1px solid #4a535b;
			}
		}
		#cbtn {
			position: absolute;
			top: 15px;
			right: 10px;
			background: url("../img/close.png") no-repeat left top;
			background-size: 50px;
			width: 50px;
			height: 50px;
			cursor: pointer;
		}
		.termsbox {
			overflow-y: scroll;
			width: 100%;
			height: 100px;
			font-size: 14px;
			padding: 10px 20px 10px 10px;
			border: 1px solid #e0e0e0;
			margin: 10px 0;
			background: #fcfcfc;
			h2 {
				font-family: $f_b;
				padding-bottom: 10px;
			}
			.text {
				padding-bottom: 20px;
			}
		}
	}
}