#estimate {
	#zone1 {
		padding: 0 20px 50px;
		.in {
			figure {
				width: 120px;
				margin: auto;
			}
			h2 {
				font-size: 22px;
				font-family: $f_b;
				padding: 40px 0;
				text-align: center;
			}
			h3 {
				font-size: 18px;
				font-family: $f_b;
				text-align: center;
				padding-bottom: 10px;
			}
			.text {
				text-align: center;
				font-size: 12px;
				padding-bottom: 10px;
			}
			.box {
				.price {
					background: #eff3f6;
					font-size: 24px;
					font-family: sans-serif;
					font-weight: bold;
					letter-spacing: 0.1em;
					text-align: center;
					padding: 30px 0;
					i {
						display: block;
						padding-top: 10px;
					}
				}
				.t1 {
					font-size: 12px;
					text-align: right;
					padding-top: 10px;
				}
				.btn {
					padding-top: 20px;
				}
			}
		}
	}
	#zone2 {
		padding: 0 20px 50px;
		.in {
			h2 {
				line-height: 2;
				padding: 20px 0;
				border-top: 2px solid #4a535b;
			}
			.t1 {
				padding-bottom: 30px;
				border-bottom: 2px solid #4a535b;
				margin-bottom: 30px;
			}
			h3 {
				text-align: center;
				color: #004498;
				font-size: 16px;
				font-family: $f_b;
				padding: 30px 0;
				line-height: 1.5;
				small {
					display: block;
					padding-top: 10px;
					font-size: 12px;
					text-align: left;
				}
			}
			.text {
				padding-bottom: 20px;
				b {
					font-family: $f_b;
				}
				strong {
					color: #004498;
					font-family: $f_b;
				}
				i {
					font-family: $f_b;
					color: #d2001e;
				}
			}
		}
	}
	.gform {
		margin: -10px -20px 0;
		padding: 10px;
		background: #e2e2e2;
		iframe {
			width: 100%;
			height: 1800px;
		}
	}
}



#recommend {
	#zone2 {
		padding: 50px 20px 0;
	}
}



#recommend_single {
	#zone2 {
		margin-bottom: 100px;
		padding: 40px 20px;
		.in {
			color: #fff;
			h2 {
				font-size: 20px;
				font-weight: bold;
				line-height: 1.5;
				text-align: center;
				padding-bottom: 35px;
				margin-bottom: 30px;
				position: relative;
				&::after {
					position: absolute;
					bottom: 0;
					left: calc(50% - 50px);
					content: '';
					width: 100px;
					height: 2px;
					background: #fff;
				}
			}
		}
	}
	#zone3 {
		margin: 0 20px;
		padding-bottom: 40px;
		background: #fff;
		ul {
			li {
				padding-bottom: 30px;
				.in {
					padding: 20px;
					&.ex {
						padding-top: 0;
					}
					h3 {
						font-size: 18px;
						font-weight: bold;
						line-height: 1.5;
						padding-bottom: 10px;
					}
					h4 {
						font-size: 16px;
						font-weight: bold;
						line-height: 1.5;
						margin: 30px 0 10px;
					}
					h5 {
						font-size: 15px;
						font-weight: bold;
						line-height: 1.5;
					}
					.text a {
						color: #004498;
						font-weight: bold;
					}
				}
				.box {
					margin: 20px;
					.bg {
						background: #EFF3F6;
						figure {
							position: relative;
							i {
								position: absolute;
								bottom: 0;
								left: 0;
								display: block;
								color: #fff;
								background: rgba(#4A535B, 0.5);
								width: 100%;
								text-align: center;
								padding: 5px;
								font-size: 12px;
								line-height: 1.2;
							}
						}
						.text {
							padding: 20px;
						}
					}
				}
			}
		}
		.t1 {
			text-align: center;
			color: #004498;
			font-size: 16px;
			font-family: $f_b;
			padding-bottom: 20px;
			line-height: 1.5;
		}
		table {
			margin: 30px 0;
			border-top: 1px solid #818181;
			border-left: 1px solid #818181;
			th,td {
				border-bottom: 1px solid #818181;
				border-right: 1px solid #818181;
				vertical-align: middle;
				padding: 5px;
				line-height: 1.5;
				font-size: 13px;
			}
			th {
				background-color: #F7F9FA;
				white-space: nowrap;
				font-weight: bold;
			}
			.c {
				text-align: center;
			}
		}
	}
	.page1 {
		#zone2 {
			background: url("../img/recommend/001/bg.jpg") no-repeat center center;
			background-size: cover;
		}
	}
	.page2 {
		#zone2 {
			background: url("../img/recommend/002/bg.jpg") no-repeat center center;
			background-size: cover;
		}
	}
	.page3 {
		#zone2 {
			background: url("../img/recommend/003/bg.jpg") no-repeat center center;
			background-size: cover;
		}
	}
	.page4 {
		#zone2 {
			background: url("../img/recommend/004/bg.jpg") no-repeat center center;
			background-size: cover;
		}
	}
	.page5 {
		#zone2 {
			background: url("../img/recommend/005/bg.jpg") no-repeat center center;
			background-size: cover;
		}
	}
	.page6 {
		#zone2 {
			background: url("../img/recommend/006/bg.jpg") no-repeat center center;
			background-size: cover;
		}
	}
	.page7 {
		#zone2 {
			background: url("../img/recommend/007/bg.jpg") no-repeat center center;
			background-size: cover;
		}
	}
	.page8 {
		#zone2 {
			background: url("../img/recommend/008/bg.jpg") no-repeat center center;
			background-size: cover;
		}
		#zone3 {
			.box {
				margin: 30px 0;
				.text {
					b {
						display: block;
						font-weight: bold;
						background-color: #E1E7EC;
						padding-left: 5px;
						margin-bottom: 5px;
					}
				}
			}
		}
	}
	.page9 {
		#zone2 {
			background: url("../img/recommend/009/bg.jpg") no-repeat center center;
			background-size: cover;
		}
		#zone3 {
			.in.ex .btn {
				margin-top: 30px;
			}
		}
	}
	.page10 {
		#zone2 {
			background: url("../img/recommend/010/bg.jpg") no-repeat center center;
			background-size: cover;
		}
		#zone3 {
			table {
				margin: 10px 0;
			}
			.in.ex .btn {
				margin-top: 30px;
			}
			.in.ex2 {
				width: 100%;
			}
			.c1 {
				color: #004498;
				font-weight: bold;
			}
			.box {
				margin: 30px 20px;
				.text {
					b {
						display: block;
						font-weight: bold;
						background-color: #E1E7EC;
						padding-left: 5px;
						margin-bottom: 5px;
					}
				}
			}
		}
	}
	.page11 {
		#zone2 {
			background: url("../img/recommend/011/bg.jpg") no-repeat center center;
			background-size: cover;
		}
		#zone3 {
			table {
				margin: 10px 0;
			}
			.in.ex .btn {
				margin-top: 30px;
			}
			.in.ex2 {
				width: 100%;
			}
			.c1 {
				color: #004498;
				font-weight: bold;
			}
			.box {
				margin: 30px 20px;
				.text {
					b {
						display: block;
						font-weight: bold;
						background-color: #E1E7EC;
						padding-left: 5px;
						margin-bottom: 5px;
					}
				}
			}
		}
	}
	.page12 {
		#zone2 {
			background: url("../img/recommend/012/bg.jpg") no-repeat center center;
			background-size: cover;
		}
	}
}



#casestudy {
	#zone1 {
		.dropsec {
			border-bottom: 2px solid #e5e5e5;
			.dropnav {
				display: flex;
				justify-content: space-between;
				text-align: center;
				.dropnav_ttl {
					width: 50%;
					position: relative;
					font-size: 16px;
					.navttl {
						display: block;
						font-family: $f_b;
						padding: 15px;
						padding-right: 30px;
						background: url("../img/arrow4.png") no-repeat right 20px center;
						background-size: 20px;
					}
					.dropnav_box {
						display: none;
						position: absolute;
						top: 42px;
						left: 0;
						width: 100%;
						background: #fff;
						z-index: 2;
						padding-top: 5px;
						box-shadow: 5px 5px 10px rgba(#000, 0.3);
						&::after {
							position: absolute;
							top: 0;
							left: 0;
							content: '';
							width: 100%;
							height: 5px;
							background: linear-gradient(to right, #213f9b, #03baf3);
						}
						a {
							display: block;
							padding: 15px;
						}
					}
				}
			}
		}
	}
	#zone2 {
		padding: 50px 20px 0;
	}
}



#casestudy_single {
	#zone1 {
		padding: 0 20px;
		.mds1 {
			text-align: left;
		}
	}
	#zone2 {
		.in {
			background: #fff;
			padding: 20px;
			.t1 {
				color: #004498;
				font-family: $f_en;
				font-size: 18px;
				font-weight: 700;
				display: flex;
				align-items: center;
				b {
					font-size: 42px;
					margin-left: 5px;
				}
			}
			h2 {
				font-family: $f_b;
				font-size: 20px;
				line-height: 1.5;
				margin-bottom: 20px;
			}
			.t2 {
				line-height: 1.5;
			}
			dl {
				display: flex;
				flex-wrap: wrap;
				flex-direction: row;
				justify-content: space-between;
				line-height: 1.5;
				dt {
					width: 7em;
					font-feature-settings: "palt";
					margin-bottom: 5px;
				}
				dd {
					width: calc(100% - 7em);
					margin-bottom: 5px;
					text-indent: -1em;
					padding-left: 1em;
				}
			}
		}
	}
	#zone3 {
		margin-top: 50px;
		background: #fff;
		padding: 50px 20px;
		.set1 {
			padding-bottom: 30px;
			>li {
				border: 5px solid #eff3f6;
				padding: 20px 20px 0px 20px;
				margin-bottom: 20px;
				&:first-child {
					background: #eff3f6;
				}
				ol {
					counter-reset: item;
					list-style-type: none;
					padding-left: 0;
					line-height: 1.6;
					li {
						text-indent: -1.3em;
						padding-left: 1.3em;
						padding-bottom: 20px;
						&::before{
							counter-increment: item;
							content: counter(item)'.';
							padding-right: .4em;
							color: #004498;
						}
					}
				}
			}
		}
		.set2 {
			padding-bottom: 50px;
			h3 {
				font-family: $f_b;
				font-size: 18px;
				line-height: 1.5;
				padding-bottom: 30px;
				&.ex {
					padding-bottom: 20px;
				}
			}
			h4 {
				font-family: $f_b;
				font-size: 16px;
				line-height: 1.5;
				color: #004498;
				text-indent: -1.2em;
				padding-left: 1.2em;
				padding-bottom: 10px;
				&::before{
					content: 'ー';
					padding-right: .2em;
				}
			}
			.text {
				padding-bottom: 40px;
				&.ex {
					padding-bottom: 0px;
				}
			}
		}
		.pic_l {
			position: relative;
			margin-bottom: 50px;
			img {
				width: 95%;
				position: relative;
				z-index: 1;
			} 
			&::after {
				position: absolute;
				top: -30px;
				right: 0;
				content: '';
				width: 200px;
				height: 100px;
				background: linear-gradient(to right, #213f9b, #03baf3);
			}
		}
		.pic_r {
			position: relative;
			margin-bottom: 50px;
			text-align: right;
			img {
				width: 95%;
				position: relative;
				z-index: 1;
			} 
			&::after {
				position: absolute;
				top: -30px;
				left: 0;
				content: '';
				width: 200px;
				height: 100px;
				background: linear-gradient(to right, #213f9b, #03baf3);
			}
		}
		.set3 {
			border: 1px solid #818181;
			padding: 20px 20px 0px 20px;
			.mds2 {
				line-height: 1.5;
			}
			.in {
				display: flex;
				justify-content: center;
				line-height: 1.6;
				ul {
					li {
						position: relative;
						padding-left: 30px;
						background: url("../img/casestudy/ck.png") no-repeat left top 2px;
						background-size: 20px;
						padding-bottom: 20px;
					}
				}
			}
		}
		.photoset {
			margin-right: -10px;
			padding-top: 50px;
			.slider {
				margin-right: 10px;
			}
			.thumbnail {
				.slick-track {
					transform: unset !important;
					display: flex;
					flex-flow: row wrap;
				}
				.slick-slide {
					display: block!important;
					float: none !important;
					flex: 0 0 calc(100% / 5 - 11px);
					margin: 10px 10px 0 0;
					img {
						cursor: pointer;
					}
				}
				.slick-track:before {
					display: none;
				}
				.slick-track:after {
					display: none;
				}
				.slick-current {
					position: relative;
					z-index: 1;
				}
				.slick-current::after {
					content: '';
					width: 100%;
					height: 100%;
					border: 3px solid #004498;
					position: absolute;
					top: 0;
					left: 0;
					z-index: 2;
				}
			}
		}
	}
}



#qa {
	#zone1 {
		padding: 0 20px 50px;
		.mds1 {
			padding-bottom: 30px;
			border-bottom: 10px solid #eff3f6;
		}
		.qanav {
			padding: 30px 0;
			li {
				a {
					background: url("../img/qa/arrow.png") no-repeat left center;
					background-size: 10px;
					display: block;
					color: #004498;
					font-family: $f_b;
					line-height: 1.5;
					padding: 5px 0px 5px 20px;
				}
			}
		}
		.set {
			padding-bottom: 50px;
			h3 {
				background: url("../img/qa/q.png") no-repeat left 15px center #eff3f6;
				background-size: 30px;
				font-family: $f_b;
				font-size: 16px;
				line-height: 1.5;
				padding: 10px;
				padding-left: 60px;
			}
			.text {
				background: url("../img/qa/a.png") no-repeat left 15px top 15px;
				background-size: 30px;
				padding: 10px;
				padding-left: 60px;
			}
		}
	}
}



#contact {
	#zone1 {
		padding: 0 20px 50px;
	}
}



#download {
	#f_download {
		background: #fff;
		margin-top: 0px;
		.in {
			padding-top: 0;
			color: #4a535b;
			.t1 {
				color: #fff;
			}
		}
	}
}



#terms {
	#zone1 {
		padding: 0 20px;
		h2 {
			color: #004498;
			font-family: $f_b;
			font-size: 16px;
			padding-bottom: 10px;
			line-height: 1.5;
		}
		.text {
			padding-bottom: 50px;
		}
	}
}



#policy {
	#zone1 {
		padding: 0 20px;
		h2 {
			color: #004498;
			font-family: $f_b;
			font-size: 16px;
			padding-bottom: 10px;
			line-height: 1.5;
		}
		.text {
			padding-bottom: 50px;
			a {
				color: #004498;
				text-decoration: underline;
			}
		}
		.btn {
			padding-bottom: 50px;
			a {
				width: 280px;
				font-size: 14px;
			}
		}
	}
}



#cookie {
	#zone1 {
		padding: 0 20px;
		h2 {
			color: #004498;
			font-family: $f_b;
			font-size: 16px;
			padding-bottom: 10px;
			line-height: 1.5;
		}
		.text {
			padding-bottom: 50px;
			&.ex {
				word-break: break-all;
			}
			a {
				text-decoration: underline;
			}
		}
	}
}



#foundation {
	#zone1 {
		position: relative;
		margin-bottom: 50px;
		background: #EFF3F6;
		.in {
			position: relative;
			z-index: 1;
			padding: 50px 20px;
			.set {
				.mds1 {
					padding-bottom: 20px;
				}
				figure {
					padding-top: 30px;
				}
			}
		}
		.pic {
		}
	}
	#zone2 {
		margin-bottom: 50px;
		padding: 0 20px;
		ul {
			li {
				background: #EFF3F6;
				border: 1px solid #CFDDEA;
				padding: 20px;
				margin-bottom: 20px;
				.mds2 {
					font-size: 26px;
					color: #004498;
					padding-bottom: 10px;
				}
				h4 {
					text-align: center;
					font-size: 16px;
					font-weight: bold;
					padding-bottom: 20px;
				}
				figure {
					padding-top: 30px;
				}
			}
		}
	}
	#zone3 {
		background: #EFF3F6;
		padding: 50px 20px;
		margin-bottom: 50px;
		.in {
			.sec1 {
				padding-bottom: 20px;
				h4 {
					text-align: center;
					color: #fff;
					font-weight: bold;
					padding: 7px;
					margin-bottom: 10px;
				}
				.text {
					line-height: 1.6;
					padding-top: 10px;
				}
				.set1 {
					background: #fff;
					border: 1px solid #CFDDEA;
					padding: 20px;
					margin-bottom: 20px;
					.pos1 {
						padding-bottom: 30px;
						.m1 {
							background: #004498;
						}
					}
					.pos2 {
						width: 220px;
						margin: auto;
					}
				}
				.set2 {
					background: #fff;
					border: 1px solid #CFDDEA;
					padding: 20px;
					.m2 {
						background: #4A535B;
					}
				}
			}
			.sec2 {
				background: #fff;
				border: 1px solid #CFDDEA;
				padding: 20px;
				.set1 {
					padding-bottom: 20px;
					h4 {
						text-align: center;
						color: #004498;
						font-weight: bold;
						font-size: 18px;
						padding-bottom: 10px;
					}
				}
				.t1 {
					padding-top: 10px;
					line-height: 1.5;
					font-size: 12px;
				}
			}
		}
	}
	#zone4 {
		padding: 0 20px 50px;
		.mds1 {
			padding-bottom: 20px;
		}
		.text {
			text-align: center;
			padding-bottom: 10px;
		}
		ul {
			display: flex;
			flex-wrap: wrap;
			flex-direction: row;
			justify-content: space-between;
			li {
				width: 48%;
				padding-bottom: 20px;
				.t1 {
					text-align: center;
					color: #004498;
					font-weight: bold;
					padding-top: 10px;
				}
			}
		}
	}
}