header {
	position: fixed;
	width: calc(100% - 80px);
	min-width: 1170px;
	height: 130px;
	line-height: 1;
	z-index: 10;
	top: 0px;
	left: 40px;
	.in {
		margin-top: 30px;
		height: 100px;
		background: #fff;
		display: flex;
		flex-wrap: wrap;
		flex-direction: row;
		align-items: center;
		justify-content: space-between;
		padding: 0 30px;
		h1 {
			width: 200px;
		}
		.set {
			width: 900px;
			display: flex;
			flex-wrap: wrap;
			flex-direction: row;
			align-items: center;
			justify-content: space-between;
		}
		ul {
			width: 770px;
			display: flex;
			flex-wrap: wrap;
			flex-direction: row;
			align-items: center;
			justify-content: space-between;
			font-family: $f_b;
			a {
				display: inline-block;
				padding: 20px 0;
				position: relative;
				&::after {
					position: absolute;
					bottom: 10px;
					left: 50%;
					content: '';
					width: 0;
					height: 4px;
					background: linear-gradient(to right, #213f9b, #03baf3);
					transition: .3s;
					transform: translateX(-50%);
				}
				&:hover {
					opacity: 1;
					color: #004498;
					&::after {
						width: 100%;
					}
				}
				.new {
					position: absolute;
					top: -3px;
					left: 0;
					background: #FF0000;
					color: #fff;
					font-size: 10px;
					font-family: $f_en;
					font-weight: 700;
					padding: 3px;
					text-align: center;
					letter-spacing: 0.1em;
				}
			}
		}
		.btn {
			width: 115px;
			border-radius: 3px;
			overflow: hidden;
			.hbtn {
				display: flex;
				flex-wrap: wrap;
				flex-direction: row;
				align-items: center;
				justify-content: center;
				width: 100%;
				height: 60px;
				font-family: $f_b;
				background: linear-gradient(to right, #213f9b, #03baf3);
				color: #fff;
				border: 4px solid #213f9b;
				border-image: linear-gradient(to right, #213f9b 0%, #03baf3 100%);
				border-image-slice: 1;
				&:hover {
					opacity: 1;
					background: #fff;
					color: #004498;
				}
			}
		}
	}
}

#menu {
	position: fixed;
	width: 100%;
	height: 100%;
	top: 0px;
	left: 0px;
	opacity: 0;
	transition-duration: 0.3s;
	z-index: 0;
	line-height: 1;
	pointer-events: none;
	display:flex;
	justify-content: flex-end;
	font-size: 18px;
	background: rgba(#000, 0.5);
	transform: scale(1.2);
	&.on {
		opacity: 1;
		pointer-events: auto;
		z-index: 99;
		transform: scale(1.0);
	}
	#mclose {
		width: calc(100% - 850px);
	}
	.in {
		min-height: 100%;
		padding: 150px 100px 50px;
		background: #9da4a8;
		color: #fff;
		overflow-y: auto;
		.nav {
			width: 650px;
			> li {
				padding-bottom: 50px;
				> i {
					font-family: $f_en;
					font-weight: 400;
					// font-size: 14px;
					font-size: 13px;
					letter-spacing: 0.1em;
					display: block;
					padding-bottom: 30px;
				}
				ul {
					display: flex;
					flex-wrap: wrap;
					flex-direction: row;
					justify-content: space-between;
					li {
						width: 300px;
						padding-bottom: 30px;
						a {
							position: relative;
							display: block;
							color: #fff;
							letter-spacing: 0.1em;
							font-size: 12px;
							font-weight: bold;
							z-index: 1;
							i {
								display: block;
								// font-size: 24px;
								font-size: 22px;
								padding-bottom: 5px;
								font-family: $f_en;
								font-weight: 400;
							}
							&::before {
								position: absolute;
								top: calc(50% - 24px);
								right: 0;
								content: '';
								width: 48px;
								height: 48px;
								background: url("../img/arrow1.png") no-repeat center center;
								background-size: 15px;
								border: 1px solid rgba(#fff,0.3);
								border-radius: 25px;
								z-index: -1;
								transition: 0.3s;
							}
							&:hover {
								&::before {
									background: url("../img/arrow1.png") no-repeat center center rgba(#fff,0.2);
									background-size: 15px;
								}
							}
						}
					}
				}
			}
		}
		.sub {
			display: flex;
			flex-wrap: wrap;
			flex-direction: row;
			justify-content: space-between;
			align-items: center;
			.copyright {
				letter-spacing: 0.1em;
				font-size: 10px;
				font-family: $f_en;
				font-weight: 400;
			}
		}
	}
}


.hm_button * {
	margin: 0;
	padding: 0;
	outline: none;
	border: none;
	font: inherit;
	font-family: inherit;
	font-size: 100%;
	font-style: inherit;
	font-weight: inherit;
	-webkit-appearance: none;
	-moz-appearance: none;
	appearance: none;
	text-align: left;
	text-decoration: none;
	list-style: none;
}

.hm_button {
	display: block;
	padding: 0;
	width: 64px;
	height: 17px;
	position: relative;
	background: none;
	border: none;
	text-align: center;
	letter-spacing: 0.1em;
	cursor: pointer;
	outline: none;
}

.hm_button .hm_bar {
	display: block;
	width: 64px;
	height: 1px;
	transition: all 0.2s;
	transform-origin: 0% 0%;
	transform: translateY(-50%);
	position: absolute;
	left: 0;
}

.hm_button .hm_bar1 {
	top: 0;
}

.hm_button .hm_bar2 {
	top: 50%;
}

.hm_button .hm_bar3 {
	top: 100%;
}

.hm_button.active .hm_bar {
	width: 66.22px;
	left: 0px
}

.hm_button.active .hm_bar1 {
	transform: rotate(0.2596296294082575rad) translateY(-50%);
	top: 0px
}

.hm_button.active .hm_bar2 {
	opacity: 0;
}

.hm_button.active .hm_bar3 {
	transform: rotate(-0.2596296294082575rad) translateY(-50%);
	top: calc(100% - 0px)
}

.hm_button.active .hm_menu_text {
	display: none;
}

.hm_button.active .hm_close {
	display: block;
}

.hm_button .hm_bar {
	background-color: #4f5254;
}