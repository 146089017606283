#estimate {
	#zone1 {
		padding-bottom: 50px;
		.in {
			max-width: 1000px;
			margin: auto;
			figure {
				width: 140px;
				margin: auto;
			}
			h2 {
				font-size: 36px;
				font-family: $f_b;
				padding: 40px 0;
				text-align: center;
			}
			h3 {
				font-size: 24px;
				font-family: $f_b;
				text-align: center;
				padding-bottom: 10px;
			}
			.text {
				text-align: center;
				padding-bottom: 30px;
			}
			.box {
				width: 500px;
				margin: auto;
				.price {
					background: #eff3f6;
					font-size: 24px;
					font-family: sans-serif;
					font-weight: bold;
					letter-spacing: 0.1em;
					text-align: center;
					padding: 30px;
				}
				.t1 {
					text-align: right;
					padding-top: 10px;
				}
				.btn {
					padding-top: 30px;
				}
			}
		}
	}
	#zone2 {
		padding-bottom: 100px;
		.in {
			max-width: 1000px;
			margin: auto;
			h2 {
				text-align: center;
				line-height: 2;
				padding: 40px 0 20px;
				border-top: 2px solid #4a535b;
			}
			.t1 {
				padding-bottom: 40px;
				border-bottom: 2px solid #4a535b;
				margin-bottom: 40px;
			}
			h3 {
				text-align: center;
				color: #004498;
				font-size: 20px;
				font-family: $f_b;
				padding: 50px 0;
				small {
					display: block;
					padding-top: 15px;
					font-size: 14px;
				}
			}
			.text {
				padding-bottom: 20px;
				b {
					font-family: $f_b;
				}
				strong {
					color: #004498;
					font-family: $f_b;
				}
				i {
					font-family: $f_b;
					color: #d2001e;
				}
			}
		}
	}
	.gform {
		width: 780px;
		margin: auto;
		margin-top: -30px;
		padding: 30px;
		background: #e2e2e2;
		iframe {
			width: 740px;
			height: 1300px;
		}
	}
}



#recommend {
	#zone2 {
		max-width: 1000px;
		margin: auto;
		padding-top: 100px;
	}
}



#recommend_single {
	#zone2 {
		height: 490px;
		margin-bottom: 100px;
		display: flex;
		align-items: center;
		.in {
			max-width: 730px;
			margin: auto;
			color: #fff;
			h2 {
				font-size: 28px;
				font-weight: bold;
				text-align: center;
				padding-bottom: 35px;
				margin-bottom: 30px;
				position: relative;
				&::after {
					position: absolute;
					bottom: 0;
					left: calc(50% - 50px);
					content: '';
					width: 100px;
					height: 2px;
					background: #fff;
				}
			}
		}
	}
	#zone3 {
		max-width: 1100px;
		margin: auto;
		background: #fff;
		padding: 80px 0 120px;
		ul {
			li {
				display: flex;
				flex-wrap: wrap;
				flex-direction: row-reverse;
				padding-bottom: 120px;
				&.ex {
					align-items: center;
				}
				figure {
					width: 600px;
				}
				.in {
					width: calc(100% - 600px);
					padding: 0 50px;
					&.ex {
						width: 100%;
						margin-top: 30px;
					}
					h3 {
						font-size: 23px;
						font-weight: bold;
						line-height: 1.5;
						padding-bottom: 20px;
					}
					h4 {
						font-size: 18px;
						font-weight: bold;
						line-height: 1.5;
						margin: 30px 0 10px;
					}
					h5 {
						font-size: 16px;
						font-weight: bold;
						line-height: 1.5;
					}
					.text a {
						color: #004498;
						font-weight: bold;
					}
				}
				&:nth-of-type(2n) {
					flex-direction: row;
				}
				.box {
					width: 100%;
					margin-top: 65px;
					.bg {
						max-width: 900px;
						margin: auto;
						background: #EFF3F6;
						padding: 50px;
						figure {
							width: 480px;
							margin: auto;
							position: relative;
							margin-bottom: 30px;
							i {
								position: absolute;
								bottom: 0;
								left: 0;
								display: block;
								color: #fff;
								background: rgba(#4A535B, 0.5);
								width: 100%;
								text-align: center;
								padding: 5px;
							}
						}
					}
					.text {
						max-width: 700px;
						margin: auto;
						padding-bottom: 20px;
					}
				}
			}
			table {
				width: 100%;
				margin: 30px 0;
				border-top: 1px solid #818181;
				border-left: 1px solid #818181;
				th,td {
					border-bottom: 1px solid #818181;
					border-right: 1px solid #818181;
					vertical-align: middle;
					padding: 20px;
					line-height: 1.5;
				}
				th {
					background-color: #F7F9FA;
					white-space: nowrap;
					font-weight: bold;
				}
				.c {
					text-align: center;
				}
			}
		}
		.t1 {
			color: #004498;
			font-size: 24px;
			font-family: $f_b;
			text-align: center;
			padding-bottom: 30px;
		}
	}
	.page1 {
		#zone2 {
			background: url("../img/recommend/001/bg.jpg") no-repeat center center;
			background-size: cover;
		}
	}
	.page2 {
		#zone2 {
			background: url("../img/recommend/002/bg.jpg") no-repeat center center;
			background-size: cover;
		}
	}
	.page3 {
		#zone2 {
			background: url("../img/recommend/003/bg.jpg") no-repeat center center;
			background-size: cover;
		}
	}
	.page4 {
		#zone2 {
			background: url("../img/recommend/004/bg.jpg") no-repeat center center;
			background-size: cover;
		}
	}
	.page5 {
		#zone2 {
			background: url("../img/recommend/005/bg.jpg") no-repeat center center;
			background-size: cover;
		}
	}
	.page6 {
		#zone2 {
			background: url("../img/recommend/006/bg.jpg") no-repeat center center;
			background-size: cover;
		}
	}
	.page7 {
		#zone2 {
			background: url("../img/recommend/007/bg.jpg") no-repeat center center;
			background-size: cover;
		}
	}
	.page8 {
		#zone2 {
			background: url("../img/recommend/008/bg.jpg") no-repeat center center;
			background-size: cover;
		}
		#zone3 {
			.box {
				margin: 30px 0;
				.text {
					b {
						display: block;
						font-weight: bold;
						background-color: #E1E7EC;
						padding-left: 5px;
						margin-bottom: 5px;
					}
				}
			}
		}
	}
	.page9 {
		#zone2 {
			background: url("../img/recommend/009/bg.jpg") no-repeat center center;
			background-size: cover;
		}
		#zone3 {
			.in.ex .btn {
				margin-top: 30px;
			}
		}
	}
	.page10 {
		#zone2 {
			background: url("../img/recommend/010/bg.jpg") no-repeat center center;
			background-size: cover;
		}
		#zone3 {
			table {
				margin: 10px 0;
			}
			.in.ex .btn {
				margin-top: 30px;
			}
			.in.ex1 {
				width: 900px;
				margin: 50px auto;
			}
			.c1 {
				color: #004498;
				font-weight: bold;
			}
			.in.ex2 {
				width: 100%;
			}
			.box {
				margin: 50px 0;
				.text {
					b {
						display: block;
						font-weight: bold;
						background-color: #E1E7EC;
						padding-left: 5px;
						margin-bottom: 5px;
					}
				}
			}
		}
	}
	.page11 {
		#zone2 {
			background: url("../img/recommend/011/bg.jpg") no-repeat center center;
			background-size: cover;
		}
		#zone3 {
			table {
				margin: 10px 0;
			}
			.in.ex .btn {
				margin-top: 30px;
			}
			.in.ex1 {
				width: 900px;
				margin: 50px auto;
			}
			.c1 {
				color: #004498;
				font-weight: bold;
			}
			.in.ex2 {
				width: 100%;
			}
			.box {
				margin: 50px 0;
				.text {
					b {
						display: block;
						font-weight: bold;
						background-color: #E1E7EC;
						padding-left: 5px;
						margin-bottom: 5px;
					}
				}
			}
		}
	}
	.page12 {
		#zone2 {
			background: url("../img/recommend/012/bg.jpg") no-repeat center center;
			background-size: cover;
		}
		#zone3 {
			.in.ex {
				width: 100%;
			}
		}
	}
}



#casestudy {
	#zone1 {
		.dropsec {
			border-bottom: 2px solid #e5e5e5;
			.dropnav {
				width: 700px;
				margin: auto;
				display: flex;
				justify-content: space-between;
				text-align: center;
				.dropnav_ttl {
					width: 330px;
					position: relative;
					font-size: 18px;
					.navttl {
						display: block;
						font-family: $f_b;
						padding: 20px;
						background: url("../img/arrow4.png") no-repeat right 20px center;
						background-size: 20px;
						cursor: pointer;
						&::after {
							position: absolute;
							bottom: 0;
							left: 0;
							content: '';
							width: 100%;
							height: 7px;
							background: linear-gradient(to right, #213f9b, #03baf3);
							transition: 0.3s;
							opacity: 0;
						}
						&:hover {
							&::after {
								opacity: 1;
							}
						}
					}
					.dropnav_box {
						display: none;
						position: absolute;
						top: 51px;
						left: 0;
						width: 100%;
						background: #fff;
						z-index: 2;
						padding-top: 7px;
						box-shadow: 5px 5px 10px rgba(#000, 0.3);
						&::after {
							position: absolute;
							top: 0;
							left: 0;
							content: '';
							width: 100%;
							height: 7px;
							background: linear-gradient(to right, #213f9b, #03baf3);
						}
						a {
							display: block;
							padding: 20px;
							&:hover {
								background: #eff3f6;
								opacity: 1;
							}
						}
					}
				}
			}
		}
	}
	#zone2 {
		max-width: 1000px;
		margin: auto;
		padding-top: 100px;
	}
}



#casestudy_single {
	#zone2 {
		max-width: 1120px;
		margin: auto;
		height: 510px;
		display: flex;
		align-items: center;
		figure {
			width: 840px;
			position: absolute;
			top: 0;
			left: calc(50% - 150px);
		}
		.in {
			background: #fff;
			width: 520px;
			min-height: 330px;
			display: flex;
			align-items: center;
			position: relative;
			padding: 25px 50px;
			.t1 {
				color: #004498;
				font-family: $f_en;
				font-size: 18px;
				font-weight: 700;
				display: flex;
				align-items: center;
				b {
					font-size: 42px;
					margin-left: 5px;
				}
			}
			h2 {
				font-family: $f_b;
				font-size: 24px;
				line-height: 1.5;
				margin-bottom: 20px;
			}
			.t2 {
				line-height: 1.5;
			}
			dl {
				display: flex;
				flex-wrap: wrap;
				flex-direction: row;
				justify-content: space-between;
				line-height: 1.5;
				dt {
					width: 7em;
					font-feature-settings: "palt";
					margin-bottom: 5px;
				}
				dd {
					width: calc(100% - 7em);
					margin-bottom: 5px;
					text-indent: -1em;
					padding-left: 1em;
				}
			}
		}
	}
	#zone3 {
		max-width: 1000px;
		margin: 50px auto 0;
		background: #fff;
		padding: 100px;
		.set1 {
			display: flex;
			justify-content: space-between;
			padding-bottom: 120px;
			>li {
				width: calc(50% - 15px);
				border: 5px solid #eff3f6;
				padding: 40px 40px 20px 40px;
				&:first-child {
					background: #eff3f6;
				}
				ol {
					counter-reset: item;
					list-style-type: none;
					padding-left: 0;
					line-height: 1.6;
					li {
						text-indent: -1.3em;
						padding-left: 1.3em;
						padding-bottom: 20px;
						&::before{
							counter-increment: item;
							content: counter(item)'.';
							padding-right: .4em;
							color: #004498;
						}
					}
				}
			}
		}
		.set2 {
			padding: 0px 50px 50px 50px;
			h3 {
				font-family: $f_b;
				font-size: 24px;
				line-height: 1.5;
				padding-bottom: 40px;
				&.ex {
					padding-bottom: 30px;
				}
			}
			h4 {
				font-family: $f_b;
				font-size: 18px;
				line-height: 1.5;
				color: #004498;
				text-indent: -1.2em;
				padding-left: 1.2em;
				padding-bottom: 10px;
				&::before{
					content: 'ー';
					padding-right: .2em;
				}
			}
			.text {
				padding-bottom: 40px;
				&.ex {
					padding-bottom: 0px;
				}
			}
		}
		.pic_l {
			position: relative;
			width: 700px;
			margin-bottom: 80px;
			&::after {
				position: absolute;
				top: -45px;
				left: calc(50% + 50px);
				content: '';
				width: 400px;
				height: 220px;
				background: linear-gradient(to right, #213f9b, #03baf3);
				z-index: -1;
			}
		}
		.pic_r {
			position: relative;
			margin-bottom: 80px;
			text-align: right;
			img {
				width: 700px;
			} 
			&::after {
				position: absolute;
				top: -45px;
				left: 0;
				content: '';
				width: 400px;
				height: 220px;
				background: linear-gradient(to right, #213f9b, #03baf3);
				z-index: -1;
			}
		}
		.set3 {
			border: 1px solid #818181;
			padding: 50px;
			.mds2 {
				font-size: 24px;
				line-height: 1.5;
			}
			.in {
				display: flex;
				justify-content: center;
				line-height: 1.6;
				ul {
					li {
						position: relative;
						padding-left: 30px;
						background: url("../img/casestudy/ck.png") no-repeat left 0;
						background-size: 25px;
						padding-bottom: 20px;
					}
				}
			}
		}
		.photoset {
			margin-right: -15px;
			padding-top: 100px;
			.slider {
				margin-right: 15px;
			}
			.thumbnail {
				.slick-track {
					transform: unset !important;
					display: flex;
					flex-flow: row wrap;
				}
				.slick-slide {
					display: block!important;
					float: none !important;
					flex: 0 0 calc(100% / 6 - 15px);
					margin: 20px 14px 0 0;
					img {
						cursor: pointer;
					}
				}
				.slick-track:before {
					display: none;
				}
				.slick-track:after {
					display: none;
				}
				.slick-current {
					position: relative;
					z-index: 1;
				}
				.slick-current::after {
					content: '';
					width: 100%;
					height: 100%;
					border: 5px solid #004498;
					position: absolute;
					top: 0;
					left: 0;
					z-index: 2;
				}
			}
		}
	}
}



#qa {
	#zone1 {
		max-width: 1000px;
		margin: auto;
		.mds1 {
			padding-bottom: 50px;
			border-bottom: 10px solid #eff3f6;
		}
		.qanav {
			width: 700px;
			margin: auto;
			padding: 50px 0 80px;
			li {
				a {
					background: url("../img/qa/arrow.png") no-repeat left center;
					background-size: 20px;
					display: block;
					color: #004498;
					font-family: $f_b;
					font-size: 18px;
					padding: 15px 40px;
				}
			}
		}
		.set {
			padding-bottom: 80px;
			h3 {
				background: url("../img/qa/q.png") no-repeat left 20px center #eff3f6;
				background-size: 45px;
				font-family: $f_b;
				font-size: 18px;
				padding: 30px;
				padding-left: 85px;
			}
			.text {
				background: url("../img/qa/a.png") no-repeat left 20px center;
				background-size: 45px;
				padding: 30px;
				padding-left: 85px;
			}
		}
	}
}



#contact {
	#zone1 {
		max-width: 1000px;
		margin: auto;
		padding-bottom: 50px;
	}
}



#download {
	#f_download {
		margin-top: 0px;
		&::after {
			display: none;
		}
		.in {
			padding-top: 0;
			color: #4a535b;
			.t1 {
				color: #fff;
			}
		}
	}
}



#terms {
	#zone1 {
		max-width: 1000px;
		margin: auto;
		padding-bottom: 50px;
		h2 {
			color: #004498;
			font-family: $f_b;
			font-size: 18px;
			padding-bottom: 10px;
		}
		.text {
			padding-bottom: 50px;
		}
	}
}



#policy {
	#zone1 {
		max-width: 1000px;
		margin: auto;
		padding-bottom: 50px;
		h2 {
			color: #004498;
			font-family: $f_b;
			font-size: 18px;
			padding-bottom: 10px;
		}
		.text {
			padding-bottom: 50px;
			a {
				color: #004498;
				text-decoration: underline;
			}
		}
		.btn {
			padding-bottom: 50px;
			a {
				width: 400px;
			}
		}
	}
}



#cookie {
	#zone1 {
		max-width: 1000px;
		margin: auto;
		padding-bottom: 50px;
		h2 {
			color: #004498;
			font-family: $f_b;
			font-size: 18px;
			padding-bottom: 10px;
		}
		.text {
			padding-bottom: 50px;
			a {
				text-decoration: underline;
			}
		}
	}
}



#foundation {
	#zone1 {
		position: relative;
		margin-bottom: 100px;
		&::after {
			position: absolute;
			top: 0;
			right: calc(50% - 320px);
			content: '';
			width: 200%;
			height: 100%;
			background: #EFF3F6;
		}
		.in {
			position: relative;
			max-width: 1000px;
			margin: auto;
			z-index: 1;
			padding: 80px 0;
			.set {
				width: 500px;
				.mds1 {
					text-align: left;
					padding-bottom: 20px;
				}
				figure {
					max-width: 410px;
					margin: auto;
					padding-top: 30px;
				}
			}
		}
		.pic {
			position: absolute;
			top: calc(50% - 190px);
			left: calc(50% + 60px);
			width: 500px;
			z-index: 1;
		}
	}
	#zone2 {
		max-width: 1000px;
		margin: auto;
		margin-bottom: 100px;
		ul {
			display: flex;
			flex-wrap: wrap;
			flex-direction: row;
			justify-content: space-between;
			li {
				width: 31%;
				background: #EFF3F6;
				border: 1px solid #CFDDEA;
				padding: 30px;
				.mds2 {
					font-size: 30px;
					color: #004498;
					padding-bottom: 10px;
				}
				h4 {
					text-align: center;
					font-size: 20px;
					font-weight: bold;
					padding-bottom: 20px;
				}
				figure {
					padding-top: 30px;
				}
			}
		}
	}
	#zone3 {
		background: #EFF3F6;
		padding: 100px 0;
		margin-bottom: 100px;
		.in {
			max-width: 1000px;
			margin: auto;
			.sec1 {
				display: flex;
				flex-wrap: wrap;
				flex-direction: row;
				justify-content: space-between;
				padding-bottom: 40px;
				h4 {
					text-align: center;
					color: #fff;
					font-weight: bold;
					padding: 7px;
					margin-bottom: 10px;
				}
				.text {
					line-height: 1.6;
					padding-top: 10px;
				}
				.set1 {
					width: 62%;
					background: #fff;
					border: 1px solid #CFDDEA;
					padding: 30px 20px;
					display: flex;
					flex-wrap: wrap;
					flex-direction: row;
					justify-content: space-between;
					.pos1 {
						width: 315px;
						.m1 {
							background: #004498;
						}
					}
					.pos2 {
						width: 220px;
					}
				}
				.set2 {
					width: 34%;
					background: #fff;
					border: 1px solid #CFDDEA;
					padding: 30px 20px;
					.m2 {
						background: #4A535B;
					}
				}
			}
			.sec2 {
				background: #fff;
				border: 1px solid #CFDDEA;
				padding: 50px;
				.set1 {
					display: flex;
					justify-content: space-between;
					align-items: center;
					padding-bottom: 20px;
					h4 {
						color: #004498;
						font-weight: bold;
						font-size: 20px;
					}
				}
				.t1 {
					padding-top: 10px;
				}
			}
		}
	}
	#zone4 {
		max-width: 1000px;
		margin: auto;
		padding-bottom: 100px;
		.mds1 {
			padding-bottom: 30px;
		}
		.text {
			text-align: center;
			padding-bottom: 20px;
		}
		ul {
			display: flex;
			flex-wrap: wrap;
			flex-direction: row;
			justify-content: space-between;
			li {
				width: 19%;
				.t1 {
					text-align: center;
					color: #004498;
					font-weight: bold;
					padding-top: 10px;
				}
			}
		}
	}
}