footer {
	min-width: 950px;
	position: relative;
	margin-top: 100px;
	.bg {
		background: #fff;
		padding: 50px 0 40px;
		.in {
			max-width: 1000px;
			margin: auto;
			display: flex;
			flex-wrap: wrap;
			flex-direction: row;
			justify-content: space-between;
			.sec1 {
				width: 800px;
				.logo {
					img {
						width: 240px;
					}
					i {
						display: block;
						font-size: 14px;
						color: #004498;
						padding: 15px 0;
					}
				}
				.nav1 {
					display: flex;
					flex-wrap: wrap;
					flex-direction: row;
					justify-content: space-between;
					width: 800px;
					font-family: $f_b;
					padding-top: 40px;
				}
				.ex {
					margin-left: 30px;
				}
			}
			.sec2 {
				width: 200px;
				.nav2 {
					font-family: $f_b;
					text-align: center;
					li {
						padding-bottom: 10px;
						a {
							display: flex;
							justify-content: center;
							align-items: center;
							width: 200px;
							height: 50px;
						}
						.fbtn1 {
							background: #eff3f6;
							i {
								position: relative;
								padding-left: 25px;
								&::after {
									position: absolute;
									top: calc(50% - 10px);
									left: 0;
									content: '';
									width: 20px;
									height: 20px;
									background: url("../img/mail.png") no-repeat center center;
									background-size: 20px;
									z-index: 1;
								}
							}
						}
						.fbtn2 {
							background: #4a535b;
							color: #fff;
							i {
								position: relative;
								padding-left: 25px;
								&::after {
									position: absolute;
									top: calc(50% - 10px);
									left: 0;
									content: '';
									width: 20px;
									height: 20px;
									background: url("../img/dl.png") no-repeat center center;
									background-size: 20px;
									z-index: 1;
								}
							}
						}
						.fbtn3 {
							background: linear-gradient(to right, #213f9b, #03baf3);
							color: #fff;
						}
					}
				}
			}
		}
	}
	.sec3 {
		.in {
			max-width: 1000px;
			margin: auto;
			height: 80px;
			display: flex;
			flex-wrap: wrap;
			flex-direction: row;
			justify-content: space-between;
			align-items: center;
			font-size: 14px;
			ul {
				display: flex;
				li {
					&::after {
						content: '　｜　';
					}
					&:last-child {
						&::after {
							display: none;
						}
					}
				}
			}
			.copyright {
				font-family: $f_en2;
			}
		}
	}
}

#pagetop { 
	position: fixed;
	right : 10px;
	bottom: 10px;
	z-index: 1;
	cursor: pointer;
	img {
		width: 60px;
	}
}